import { createProxy } from '../utils/getPath';

export type Lang = Readonly<{
	distributors: {
		newDistributor: string;
		editDistributor: string;
		newClient: string;
		editClient: string;
		distributor: string;
	};
	administrator: {
		editAdministrator: string;
	};
	login: {
		subdistributor: string;
		administrator: string;
		titleDistributor: string;
		titleSubDistributor: string;
		titleAdministrator: string;
	};
	languages: {
		czech: string;
		english: string;
		german: string;
	};
	header: {
		language: string;
		currency: string;
	};
	table: {
		capacityIncrease: string;
		capacityIncrease5GB: string;
		capacityIncrease10GB: string;
		capacityIncrease100GB: string;
		Capacity5GB: string;
		Capacity10GB: string;
		Capacity100GB: string;
		licenseRenewal: string;
		halfYear: string;
		year: string;
		twoYears: string;
		licenseRenewalHalfYear: string;
		licenseRenewalYear: string;
		licenseRenewalTwoYears: string;
		open: string;
		licenseIssued: string;
		licenseIssuedBy: string;
		licenseValidityEnd: string;
		clientLicenceNumber: string;
		maxStorage: string;
		block: string;
		name: string;
		contact: string;
		size: string;
		extension: string;
		action: string;
		status: string;
		deleted: string;
		active: string;
		inactive: string;
		info: string;
		allowed: string;
		default: string;
	};
	input: {
		name: string;
		currency: string;
		picture: string;
		pictures: string;
		description: string;
		url: string;
		forename: string;
		surname: string;
		email: string;
		countryCode: string;
		phone: string;
		company: string;
		crn: string;
		address: string;
		note: string;
		username: string;
		password: string;
		newPassword: string;
		currentPassword: string;
		validationPassword: string;
		login: string;
		resetPass: string;
		changePass: string;
		public: string;
		clientLicenceNumber: string;
	};
	validation: {
		min: string;
		max: string;
		required: string;
		email: string;
		phone: string;
		passwordMatch: string;
	};
	button: {
		more: string;
		load: string;
		loadPrice: string;
		editItem: string;
		newItem: string;
		deleteItem: string;
		activate: string;
		duplicateItem: string;
		saveItem: string;
		link: string;
		save: string;
		resetPassword: string;
	};
	toast: {
		createDistributorSuccess: string;
		createSubDistributorSuccess: string;
		createClientSuccess: string;
	};
	status: {
		Unauthorized: string;
	};
	users: {
		title: string;
		newUser: string;
		editUser: string;
	};
	common: {
		settings: string;
		admin: string;
		superAdmin: string;
		resetSuccessful: string;
		users: string;
		resetPassword: string;
		resetPasswordSubmit: string;
		resetRequestPassword: string;
		resetRequestPasswordSubmit: string;
		distributor: string;
		distributors: string;
		clients: string;
		legalPerson: string;
		naturalPerson: string;
		companyName: string;
		vatRegNumber: string;
		exceed: string;
		attachedFiles: string;
		order: string;
		NameAlreadyExists: string;
		EntityNotFound: string;
		DuplicateEntity: string;
		UsernameExists: string;
		InvalidOrMissingRole: string;
		CannotCreateUser: string;
		ConcurrencyFailure: string;
		PasswordMismatch: string;
		InvalidToken: string;
		LoginAlreadyAssociated: string;
		InvalidUserName: string;
		InvalidEmail: string;
		EmailExists: string;
		InvalidRoleName: string;
		RoleNameExists: string;
		PasswordAlreadySet: string;
		UserLockoutNotEnabled: string;
		UserAlreadyInRole: string;
		UserNotInRole: string;
		PasswordTooShort: string;
		PasswordRequiresNonAlphanumeric: string;
		PasswordRequiresDigit: string;
		PasswordRequiresLower: string;
		PasswordRequiresUpper: string;
		Unauthorized: string;
		logout: string;
		register: string;
		reset: string;
		successful: string;
		failed: string;
		close: string;
		loading: string;
		oauth: string;
	};
}>;

export default createProxy<Lang>();
