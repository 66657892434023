import { Auth } from 'types/_types';
import { Error } from 'sagas/_sagas';
import {
	ActionType,
	createAsyncAction,
	createStandardAction,
} from "typesafe-actions";

export const oauthSuperAdminAction = createAsyncAction(
	"OAUTH_SUPER_ADMIN_REQUEST",
	"OAUTH_SUPER_ADMIN_SUCCESS",
	"OAUTH_SUPER_ADMIN_FAILURE"
)<Auth, string, Error>();

export const oauthSuperAdminLogoutAction = createStandardAction(
	"OAUTH_SUPER_ADMIN_LOGOUT"
)();

export type AuthenticationSuperAdminActionType = ActionType<
	typeof oauthSuperAdminAction | typeof oauthSuperAdminLogoutAction
>;
