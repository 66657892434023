import Cookies from 'universal-cookie';
import { ApiURL } from '../constants/api';
import { call } from 'redux-saga/effects';
import { getLanguage } from '../translation/i18n';

export const query = (params: WildCards) => {
	const queryString = Object.keys(params)
		.map((k) => `${encodeURIComponent(params[k])}`)
		.join("/");

	return `/${queryString}`;
};

export const getParam = (params: WildCards) => {
	const queryString = Object.keys(params)
		.map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
		.join("&");

	return `?${queryString}`;
};

export const getParamObject = (params: object) => {
	return Object.keys(params)
		.map((k) => `${encodeURIComponent(params[k])}`)
		.join("/");
};

export type WildCards = Record<string, string | number>;

export const replaceWildCards = (url: string, wildCards?: WildCards) => {
	if (!wildCards) {
		return url;
	}

	return Object.keys(wildCards).reduce(
		(acc, key) => acc.replace(`:${key}`, `${wildCards[key]}`),
		url
	);
};

export interface FetchSagaReponseType {
	errorResponse?: Error;
	errorResponseData?: object;
	response?: object | string;
	status?: number;
	statusText?: string;
	success?: boolean;
	headers?: any;
}

export interface Error {
	ErrorMessage: string;
	ErrorStatus: number;
	TimeOccured: string;
}

const createRequestParams = ({
	bodyFormData,
	bodyJSON,
	method,
	privateToken,
}: {
	bodyFormData?: FormData;
	bodyJSON?: object;
	method: string;
	privateToken: string;
}): RequestInit => {
	const headers: HeadersInit = {
		...(getLanguage && { "Accept-Language": getLanguage() }),
		Accept: "application/json",
		...(privateToken && { Authorization: `Bearer ${privateToken}` }),
		...(bodyJSON && { "Content-Type": "application/json" }),
		...(true && { "Access-Control-Allow-Origin": "*" }),
		...(true && {
			"Access-Control-Allow-Headers": "*",
		}),
	} as HeadersInit;

	let body = null;

	if ((method === "POST" || method === "PUT") && bodyJSON) {
		body = JSON.stringify(bodyJSON);
	}

	if ((method === "POST" || method === "PUT") && bodyFormData) {
		body = bodyFormData;
	}

	const params = {
		body,
		headers,
		method,
	};

	return params;
};

export const makeUrl = (
	url: string,
	server: string,
	params?: WildCards,
	urlWildCards?: WildCards,
	param?: string,
	query?: string,
	paramObject?: object
) => {
	const appUrl = new URL(url, server);
	if (query) {
		return appUrl + query;
	} else if (param) {
		return appUrl + "/" + param;
	} else if (urlWildCards) {
		return appUrl + "/" + getParam(urlWildCards);
	} else if (paramObject) {
		return appUrl + "/" + getParamObject(paramObject);
	}
	return appUrl;
};

// checks if content-type is application/json and if so, parses it
// because response.json() fails when there is no body in response
// e.g. 200 success response without body
const parseResponse = (response: Response) => {
	const contentType = response.headers.get("content-type");
	if (contentType && contentType.indexOf("application/json") !== -1) {
		return response.json();
	} else {
		return response.blob();
	}
};

const getToken = (): string => {
	const cookies = new Cookies();
	return cookies.get("oauth_token");
};

export function* fetchSaga(
	url: ApiURL | string,
	method: string = "GET",
	{
		bodyJSON,
		bodyFormData,
		params,
		urlWildCards,
		param,
		query,
		paramObject,
	}: {
		bodyJSON?: object;
		bodyFormData?: FormData;
		params?: Record<string, string>;
		urlWildCards?: WildCards;
		param?: string;
		query?: string;
		paramObject?: object;
	} = {}
): any {
	try {
		const privateToken = yield getToken();
		const requestParams = yield call(createRequestParams, {
			bodyFormData,
			bodyJSON,
			method,
			privateToken,
		});
		const server = process.env.REACT_APP_API_URL;

		const appUrl = yield call(
			makeUrl,
			url,
			server,
			params,
			urlWildCards,
			param,
			query,
			paramObject
		);
		const response = yield call(fetch, appUrl, requestParams);
		const responseData = yield call(parseResponse, response);

		if (response.ok) {
			return yield {
				response: responseData,
				status: response.ErrosStatus,
				statusText: responseData.ErrorMessage,
				success: true,
				headers: response.headers,
			};
		}

		return yield {
			response: null,
			status: response.status,
			statusText: responseData.ErrorMessage,
			success: false,
			errorResponse: responseData,
		};
	} catch (ex) {
		console.log(ex);
		return yield {
			response: null,
			status: null,
			success: false,
		};
	}
}
