export enum Input {
    DATE = 'DATE',
    EMAIL = 'EMAIL',
    NUMERIC = 'NUMERIC',
    MONEY = 'MONEY',
    PASSWORD = 'PASSWORD',
    SELECT = 'SELECT',
    FILE = 'FILE',
    TEXT = 'TEXT',
    PHONE = 'PHONE',
    CHECKBOX = 'CHECKBOX',
    SWITCH = 'SWITCH',
}

export enum Method {
    POST = 'POST',
    GET = 'GET',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH',
}

export enum Images {
    COMPONENT = '/static/components/',
}

export enum DateFormat {
    DATE_CZ = 'DD. MM. YYYY'
}