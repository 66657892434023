import Cookies from 'universal-cookie';
import { ApiURL } from '../../../constants/api';
import { authAdministratorAction, authSubDistributorAction } from './_actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchSaga } from 'sagas/_sagas';
import { getType } from 'typesafe-actions';
import { lang, t } from 'translation/i18n';
import { notificationAction } from 'components/Toast/_actions';
import { push } from 'connected-react-router';
import { Routes } from 'constants/routes';
import { Status } from 'components/Toast/_types';
import { translationPath } from 'utils/getPath';

function* administratorAuthenticationSaga(
	action: ReturnType<typeof authAdministratorAction.request>
): Generator {
	try {
		// @ts-ignore
		const { errorResponse, response, success, statusText } = yield call(
			fetchSaga,
			ApiURL.OAUTH_ADMINISTRATOR,
			"POST",
			{
				bodyJSON: action.payload,
			}
		);

		console.log(!!(!success || errorResponse));
		if (!!(!success || errorResponse)) {
			yield put(
				notificationAction({
					code: Status.ERROR,
					message: t(translationPath(lang.common[statusText])),
				})
			);
			yield put(authAdministratorAction.failure(errorResponse));

			return;
		}

		if (success && response) {
			const cookies = new Cookies();
			cookies.set("oauth_token", response, { path: "/" });
			yield put(authAdministratorAction.success(response as string));
			yield put(push(Routes.ADMINISTRATOR_PORTAL));
		}
	} catch (err) {
		yield put(authAdministratorAction.failure(err));
	}
}

function* distributorAuthenticationSaga(
	action: ReturnType<typeof authSubDistributorAction.request>
): Generator {
	try {
		// @ts-ignore
		const { errorResponse, response, success, statusText } = yield call(
			fetchSaga,
			ApiURL.OAUTH_SUB_DISTRIBUTOR,
			"POST",
			{
				bodyJSON: action.payload,
			}
		);

		if (!!(!success || errorResponse)) {
			yield put(authSubDistributorAction.failure(errorResponse));
			yield put(
				notificationAction({
					code: Status.ERROR,
					message: t(translationPath(lang.common[statusText])),
				})
			);
			return;
		}

		if (success && response) {
			const cookies = new Cookies();
			cookies.set("oauth_token", response, { path: "/" });
			yield put(authSubDistributorAction.success(response as string));
			yield put(push(Routes.SUB_DISTRIBUTOR_PORTAL));
		}
	} catch (err) {
		yield put(authSubDistributorAction.failure(err));
	}
}

export function* watchDistributorAuthentication() {
	yield takeLatest(
		getType(authSubDistributorAction.request),
		distributorAuthenticationSaga
	);
}

export function* watchAdministratorAuthentication() {
	yield takeLatest(
		getType(authAdministratorAction.request),
		administratorAuthenticationSaga
	);
}
