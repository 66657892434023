import { Auth } from "types/_types";
import { Error } from "../../../sagas/_sagas";
import {
  ActionType,
  createAsyncAction,
  createStandardAction,
} from "typesafe-actions";

export const oauthAdminAction = createAsyncAction(
  "OAUTH_ADMIN_REQUEST",
  "OAUTH_ADMIN_SUCCESS",
  "OAUTH_ADMIN_FAILURE"
)<Auth, string, Error>();

export const resetAdminAction = createAsyncAction(
  "RESET_ADMIN_REQUEST",
  "RESET_ADMIN_SUCCESS",
  "RESET_ADMIN_FAILURE"
)<string, void, Error>();

export const oauthAdminLogoutAction = createStandardAction(
  "OAUTH_ADMIN_LOGOUT"
)();

export type AuthenticationAdminActionType = ActionType<
  | typeof oauthAdminAction
  | typeof oauthAdminLogoutAction
  | typeof resetAdminAction
>;
