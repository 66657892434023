import { Distributor, DistributorRequest, NewDistributor } from "./_types";
import { Error } from "sagas/_sagas";
import { User } from "containers/Portal/Distributor/Users/UsersTable/_types";
import {
  ActionType,
  createAsyncAction,
  createStandardAction,
} from "typesafe-actions";

export const distributorsInit = createStandardAction("DISTRIBUTORS_INIT")();

export const distributorsGetAction = createAsyncAction(
  "DISTRIBUTORS_GET_REQUEST",
  "DISTRIBUTORS_GET_SUCCESS",
  "DISTRIBUTORS_GET_FAILURE"
)<void, Distributor[], Error>();

export const distributorPostAction = createAsyncAction(
  "DISTRIBUTORS_POST_REQUEST",
  "DISTRIBUTORS_POST_SUCCESS",
  "DISTRIBUTORS_POST_FAILURE"
)<DistributorRequest, NewDistributor, Error>();

export const distributorPutAction = createAsyncAction(
  "DISTRIBUTORS_PUT_REQUEST",
  "DISTRIBUTORS_PUT_SUCCESS",
  "DISTRIBUTORS_PUT_FAILURE"
)<DistributorRequest, NewDistributor, Error>();

export const distributorUserRegisterPostAction = createAsyncAction(
  "DISTRIBUTORS_USER_REGISTER_POST_REQUEST",
  "DISTRIBUTORS_USER_REGISTER_POST_SUCCESS",
  "DISTRIBUTORS_USER_REGISTER_POST_FAILURE"
)<User, User[], Error>();

export const distributorReactivatePostAction = createAsyncAction(
  "DISTRIBUTORS_REACTIVE_POST_REQUEST",
  "DISTRIBUTORS_REACTIVE_POST_SUCCESS",
  "DISTRIBUTORS_REACTIVE_POST_FAILURE"
)<string, Distributor[], Error>();

export const distributorUsers = createAsyncAction(
  "DISTRIBUTORS_USERS_GET_REQUEST",
  "DISTRIBUTORS_USERS_GET_SUCCESS",
  "DISTRIBUTORS_USERS_GET_FAILURE"
)<void, User[], Error>();

export const distributorUserDeleteByIdAction = createAsyncAction(
  "DISTRIBUTORS_USERS_DELETE_BY_ID_REQUEST",
  "DISTRIBUTORS_USERS_DELETE_BY_ID_SUCCESS",
  "DISTRIBUTORS_USERS_DELETE_BY_ID_FAILURE"
)<string, User[], Error>();

export const distributorUserPutAction = createAsyncAction(
  "DISTRIBUTORS_USERS_PUT_REQUEST",
  "DISTRIBUTORS_USERS_PUT_SUCCESS",
  "DISTRIBUTORS_USERS_PUT_FAILURE"
)<User, User[], Error>();

export const distributorUserGetByIdAction = createAsyncAction(
  "DISTRIBUTORS_USER_BY_ID_REQUEST",
  "DISTRIBUTORS_USER_BY_ID_SUCCESS",
  "DISTRIBUTORS_USER_BY_ID_FAILURE"
)<string, User, Error>();

export const distributorGetByIdAction = createAsyncAction(
  "DISTRIBUTORS_GET_BY_ID_REQUEST",
  "DISTRIBUTORS_GET_BY_ID_SUCCESS",
  "DISTRIBUTORS_GET_BY_ID_FAILURE"
)<string, Distributor, Error>();

export const distributorDeleteByIdAction = createAsyncAction(
  "DISTRIBUTORS_DELETE_REQUEST",
  "DISTRIBUTORS_DELETE_SUCCESS",
  "DISTRIBUTORS_DELETE_FAILURE"
)<string, Distributor[], Error>();

export type DistributorsActionType = ActionType<
  | typeof distributorsGetAction
  | typeof distributorPostAction
  | typeof distributorPutAction
  | typeof distributorUserRegisterPostAction
  | typeof distributorGetByIdAction
  | typeof distributorDeleteByIdAction
  | typeof distributorUsers
  | typeof distributorGetByIdAction
  | typeof distributorUserPutAction
  | typeof distributorUserGetByIdAction
  | typeof distributorUserDeleteByIdAction
  | typeof distributorReactivatePostAction
  | typeof distributorsInit
>;
