import React from "react";
import {Route} from "react-router-dom";
import {Redirect, RouteComponentProps} from "react-router";
import {Routes} from "constants/routes";
import {useCookies} from "react-cookie";

interface OwnProps {
  path: Routes;
  component:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>;
  exact?: boolean;
}

export const ProtectedRoute = (props: OwnProps) => {
  const {exact, component, path} = props;
  const [cookies] = useCookies(["oauth_token"]);

  const getToken = (): string => {
    return cookies.oauth_token;
  };

  if (!getToken()) {
    return <Redirect to={Routes.HOME}/>;
  }

  return <Route path={path} component={component} exact={exact}/>;
};
