export enum Routes {
  ADMIN_CATEGORY_SET_EDIT = "/admin/sets/category/edit",
  ADMIN_CATEGORY_SET_NEW = "/admin/sets/category/new",
  ADMIN_COMPONENT_CATEGORY_EDIT = "/admin/component/category/edit",
  ADMIN_COMPONENT_CATEGOTY_NEW = "/admin/component/category/new",
  ADMIN_COMPONENT_EDIT = "/admin/component/edit",
  ADMIN_COMPONENT_NEW = "/admin/component/new",
  ADMIN_COMPONENTS = "/admin/components",
  ADMIN_CONFIGURATION = "/admin/configuration",
  ADMIN_DEMAND = "/admin/web-sets",
  ADMIN_ORDERS = "/admin/orders",
  ADMIN_PROPERTIES = "/admin/properties",
  ADMIN_PROPERTIES_EDIT = "/admin/properties/edit",
  ADMIN_QUESTIONS = "/admin/questions",
  ADMIN_RATES = "/admin/rates",
  ADMIN_SET_EDIT = "/admin/sets/edit",
  ADMIN_SET_NEW = "/admin/sets/new",
  ADMIN_SETS = "/admin/sets",
  ADMIN_SUPPLIES_AND_DEMANDS = "/admin/demands",
  ADMINISTRATOR = "/administrator",
  ADMINISTRATOR_CLIENTS = "/administrator/portal/clients",
  ADMINISTRATOR_DISTRIBUTORS = "/administrator/portal/distributors",
  ADMINISTRATOR_DISTRIBUTORS_NEW = "/administrator/portal/distributors/create",
  ADMINISTRATOR_DISTRIBUTORS_EDIT = "/administrator/portal/distributors/edit",
  ADMINISTRATOR_DISTRIBUTORS_EDIT_ID = "/administrator/portal/distributors/edit/:id",
  ADMINISTRATOR_PORTAL = "/administrator/portal",
  ADMINISTRATOR_PORTAL_CREATE_CLIENT = "/administrator/portal/client/edit",
  ADMINISTRATOR_PORTAL_CREATE_CLIENT_ID = "/administrator/portal/client/edit/:id",
  ADMINISTRATOR_SETTINGS = "/administrator/portal/settings",
  AUTH = "/auth",
  CATEGORY = "/category",
  COMPLAINT = "/complaint",
  COMPUTER_SETS = "/computer-sets",
  CONFIGURATION = "/configuration/",
  CONFIGURATION_ID = "/configuration/:id",
  CONFIGURATION_ORDER = "/order",
  CONTACT = "/contact",
  DEMAND = "/demand",
  DISTRIBUTOR = "/distributor",
  DISTRIBUTOR_PORTAL = "/distributor/portal",
  DISTRIBUTOR_PORTAL_CLIENTS = "/distributor/portal/clients",
  DISTRIBUTOR_PORTAL_USERS = "/distributor/portal/users",
  DISTRIBUTOR_PORTAL_USER_CREATE = "/distributor/portal/user/create",
  DISTRIBUTOR_PORTAL_USER_EDIT = "/distributor/portal/user/edit",
  DISTRIBUTOR_PORTAL_USER_EDIT_ID = "/distributor/portal/user/edit/:id",
  DISTRIBUTOR_PORTAL_CLIENTS_EDIT = "/distributor/portal/client/edit",
  DISTRIBUTOR_PORTAL_CLIENTS_EDIT_ID = "/distributor/portal/client/edit/:id",
  DISTRIBUTORS_DISTRIBUTOR_CREATE = "/distributor/portal/distributor/create",
  DISTRIBUTORS_DISTRIBUTOR_EDIT = "/distributor/portal/distributor/edit",
  DISTRIBUTORS_DISTRIBUTOR_EDIT_ID = "/distributor/portal/distributor/edit/:id",
  FAQ = "/faq",
  HOME = "/",
  OAUTH_ADMIN = "/auth/admin",
  REQUEST_RESET = "/request/reset",
  RESET = "/reset",
  OAUTH_SUPER_ADMIN = "/auth/admin/super",
  OUR_CUSTOMERS = "/our-customers",
  PRICE = "/price",
  PRICE_INCLUDES = "/price-includes",
  PURCHASE_DELIVERY_PAYMENT = "/info",
  SUB_DISTRIBUTOR_PORTAL = "/sub/distributors/portal",
  SUB_DISTRIBUTORS_CLIENTS_CREATE = "/sub/distributors/portal/client/create",
  SUB_DISTRIBUTOR_PORTAL_CLIENTS_EDIT = "/sub/distributors/portal/client/edit",
  SUB_DISTRIBUTOR_PORTAL_CLIENTS_EDIT_ID = "/sub/distributors/portal/client/edit/:id",
  SUB_DISTRIBUTORS_USERS = "/sub/distributors/portal/users",
  SUB_DISTRIBUTORS_USERS_CREATE = "/sub/distributors/portal/users/create",
  SUB_DISTRIBUTORS_USERS_EDIT_ID = "/sub/distributors/portal/users/edit/:id",
  SUB_DISTRIBUTORS_USERS_EDIT = "/sub/distributors/portal/users/edit",
  SUBCATEGORY = "/subcategory",
  SUPER_ADMIN_SETTINGS = "/super/admin/portal/settings",
  SUPER_ADMIN_USER_EDIT = "/super/admin/portal/users/edit",
  SUPER_ADMIN_USER_EDIT_ID = "/super/admin/portal/users/edit/:id",
  SUPER_ADMIN_USER_NEW = "/super/admin/portal/users/create",
  SUPER_ADMINISTRATOR_PORTAL = "/super/admin/portal",
  SUBDISTRIBUTOR = "/subdistributor",
  TERMS_ADN_CONDITIONS = "/terms",
  WRITE_ABOUT_US = "/about-us",
}
