import {css, StyleSheet} from 'aphrodite/no-important';
import classNames from 'classnames';
import React from 'react';

const ActivityIndicatorProps = {
    color: {
        red: 0,
        green: 0,
        blue: 0,
        alpha: 98 / 255
    },
    segments: 12,
    segmentWidth: 2,
    segmentLength: 3,
    spacing: 2,
    fadeTo: 31 / 98,
    fadeSteps: 6,
};

// RGBA values measured by looking at the refresh control on top of white
// and black and solving a system of equations
const RefreshControlProps = {
    color: {
        red: Math.round(4845 / 56),
        green: Math.round(765 / 8),
        blue: Math.round(24225 / 224),
        alpha: 224 / 255
    },
    segments: 12,
    segmentWidth: 2,
    segmentLength: 5,
    spacing: 3,
    fadeTo: 0,
    fadeSteps: 11,
};

export default class LoadingIndicator extends React.Component {
    static ActivityIndicatorProps = ActivityIndicatorProps;
    static RefreshControlProps = RefreshControlProps;

    static defaultProps = ActivityIndicatorProps;

    render() {
        let segmentCount = this.props.segments;
        let segmentWidth = 3;
        let segmentLength = 7;
        let innerRadius = segmentWidth * 2 + 4;

        let opacityDelta = (1 - this.props.fadeTo) / this.props.fadeSteps;

        let segments = [];
        for (let ii = 0; ii < segmentCount; ii++) {
            let opacity = 1 - Math.min(ii, this.props.fadeSteps) * opacityDelta;
            let rotation = -ii * 360 / segmentCount;
            segments.push(
                <line
                    key={ii}
                    x1="0"
                    y1={innerRadius}
                    x2="0"
                    y2={innerRadius + segmentLength}
                    style={{opacity: opacity}}
                    transform={`rotate(${rotation})`}
                />
            );
        }

        let {red, green, blue, alpha} = this.props.color;
        let rgbaColor = `rgba(${red}, ${green}, ${blue}, ${alpha})`;

        let radius = innerRadius + segmentLength + Math.ceil(segmentWidth / 2);

        return (
            <svg
                className={classNames(css(styles.indicator), this.props.className)}
                style={this.props.style}
                width={radius * 2}
                height={radius * 2}
                xmlns="http://www.w3.org/2000/svg">
                <g
                    stroke={rgbaColor}
                    strokeWidth={segmentWidth}
                    strokeLinecap="round"
                    transform={`translate(${radius}, ${radius})`}>
                    {segments}
                </g>
            </svg>
        );
    }
}

const spinKeyframes = {
    from: {
        transform: 'rotate(0deg)',
    },
    to: {
        transform: 'rotate(360deg)',
    },
};

const styles = StyleSheet.create({
    indicator: {
        animationDuration: '1s',
        animationIterationCount: 'infinite',
        animationName: spinKeyframes,
        animationTimingFunction: 'steps(12)',
    },
});