import Flag from 'react-world-flags';
import i18n, { getLanguage, lang } from 'translation/i18n';
import logo from './img/logo.svg';
import Menu from '@material-ui/core/Menu';
import React, { FC } from 'react';
import styled from 'styled-components';
import { ContentSpaceBetween, NavButton } from 'constants/globalStyles';
import { MenuItem } from '@material-ui/core';
import { translationPath } from 'utils/getPath';
import { useTranslation } from 'react-i18next';

export const Header: FC = () => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [language, setLanguage] = React.useState(getLanguage());
	const { t } = useTranslation();

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleChange = (lng: string) => (
		_event: React.MouseEvent<HTMLLIElement, MouseEvent>
	) => {
		setLanguage(lng);
		i18n.changeLanguage(lng);
		handleClose();
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<ContentSpaceBetween>
			<a target="_blank" href="https://fine.cz" rel="noopener noreferrer">
				<Logo src={logo} />
			</a>
			<div>
				<NavButton
					aria-controls="simple-menu"
					aria-haspopup="true"
					onClick={handleClick}
				>
					<Flag
						code={
							language === "cs-CZ" ? "CZ" : language === "de-DE" ? "DE" : "GB"
						}
						height="16"
					/>
					&nbsp;&nbsp;
					{language === "cs-CZ" ? "CZ" : language === "de-DE" ? "DE" : "EN"}
				</NavButton>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItemStyled onClick={handleChange("cs-CZ")}>
						{t(translationPath(lang.languages.czech).path)}
					</MenuItemStyled>
					<MenuItemStyled onClick={handleChange("en-GB")}>
						{t(translationPath(lang.languages.english).path)}
					</MenuItemStyled>
					<MenuItemStyled onClick={handleChange("de-DE")}>
						{t(translationPath(lang.languages.german).path)}
					</MenuItemStyled>
				</Menu>
			</div>
		</ContentSpaceBetween>
	);
};

const Logo = styled.img`
	height: 60px;
	position: absolute;
	left: 30px;
	top: 20px;
`;

const MenuItemStyled = styled(MenuItem)`
	-webkit-app-region: no-drag;
	cursor: pointer;
`;
