import Administrator from 'containers/Portal/Admin/Container';
import AdminPortal from './containers/Portal/SuperAdmin/Container';
import DistributorPortal from 'containers/Portal/Distributor/Container';
import Oauth from './containers/Authentication/SubDistributor/Container';
import OauthAdmin from './containers/Authentication/Admin/Container';
import React from 'react';
import ResetPassword from 'containers/Authentication/Reset/Container';
import ResetPasswordRequest from 'containers/Authentication/ResetRequest/Container';
import ScrollToTop from './components/ScrollTop';
import SubDistributorPortal from 'containers/Portal/SubDistributor/Container';
import { configureStore, history } from './store/configureStore';
import { ConnectedRouter } from 'connected-react-router';
import { Footer } from './containers/Authentication/SubDistributor/components/Footer';
import { GlobalStyles, lightTheme, theme } from './constants/theme';
import { MuiThemeProvider } from '@material-ui/core';
import { ProtectedRoute } from './components/Routes/ProtectedRoute';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { Routes } from './constants/routes';
import { ThemeProvider } from 'styled-components';
import { Toast, ToastContainer } from './components/Toast';
import { ToastProvider } from 'react-toast-notifications';
import './global.css';

const store = configureStore();

export const App: React.FC = () => {
	return (
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<Router history={history}>
					<ScrollToTop />
					<MuiThemeProvider theme={theme}>
						<ThemeProvider theme={lightTheme}>
							<GlobalStyles />
							<ToastProvider
								placement="bottom-right"
								components={{ ToastContainer: ToastContainer, Toast: Toast }}
							>
								<Switch>
									<Route
										path={Routes.REQUEST_RESET}
										component={ResetPasswordRequest}
									/>
									<Route path={Routes.RESET} component={ResetPassword} />
									<ProtectedRoute
										path={Routes.ADMINISTRATOR_PORTAL}
										component={Administrator}
									/>
									<ProtectedRoute
										path={Routes.SUPER_ADMINISTRATOR_PORTAL}
										component={AdminPortal}
									/>
									<ProtectedRoute
										path={Routes.SUB_DISTRIBUTOR_PORTAL}
										component={SubDistributorPortal}
									/>
									<Route
										path={Routes.DISTRIBUTOR_PORTAL}
										component={DistributorPortal}
									/>
									<Route
										exact={true}
										path={Routes.OAUTH_ADMIN}
										component={OauthAdmin}
									/>
									<Route path={Routes.HOME} component={Oauth} />
								</Switch>
								<Footer />
							</ToastProvider>
						</ThemeProvider>
					</MuiThemeProvider>
				</Router>
			</ConnectedRouter>
		</Provider>
	);
};
