import React, { useEffect } from "react";
import { Box } from "components/Box";
import { Clients } from "components/Clients";
import { lang } from "translation/i18n";
import { Routes } from "../../../../../constants/routes";
import { translationPath } from "utils/getPath";
import { useTranslation } from "react-i18next";
import {
  Client,
  Storage,
  Validity,
} from "containers/Portal/Admin/Clients/ClientsTable/_types";
import {
  ButtonType,
  MaterialButton,
} from "../../../../../components/Button/MaterialButton";

export interface WebCategories {
  clients: Client[];
  deletedId: string;
  deleting: boolean;
  pending: boolean;
  clientsGetAction: (data: void) => void;
  clientsDeleteByIdAction: (data: string) => void;
  clientsPostStorageAction: (data: Storage) => void;
  clientsPostValidityAction: (data: Validity) => void;
  clientsReactivate: (data: string) => void;
}

const Component: React.FC<WebCategories> = ({
  deletedId,
  deleting,
  pending,
  clients,
  clientsGetAction,
  clientsDeleteByIdAction,
  clientsPostStorageAction,
  clientsPostValidityAction,
  clientsReactivate,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    clientsGetAction();
  }, [clientsGetAction]);

  return (
    <Box
      title={t(translationPath(lang.common.clients).path)}
      action={[
        <MaterialButton
          tooltip={t(translationPath(lang.button.newItem).path)}
          to={Routes.SUB_DISTRIBUTOR_PORTAL_CLIENTS_EDIT}
          type={ButtonType.NEW}
        />,
      ]}
    >
      <Clients
        clients={clients}
        deleting={deleting}
        deletedId={deletedId}
        pathToEditClients={Routes.SUB_DISTRIBUTOR_PORTAL_CLIENTS_EDIT}
        clientsDeleteByIdAction={clientsDeleteByIdAction}
        clientsPostStorageAction={clientsPostStorageAction}
        clientsPostValidityAction={clientsPostValidityAction}
        clientsReactivate={clientsReactivate}
      />
    </Box>
  );
};

export default React.memo(Component);
