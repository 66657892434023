import Component, { StateProps } from './Component';
import { Auth } from './_types';
import { authAdministratorAction, authSubDistributorAction } from './_actions';
import { authDistributorAction } from '../Distributor/_actions';
import { clearNotificationAction } from 'components/Toast/_actions';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootStateType } from '../../../reducers';
import { withRouter } from 'react-router';

const mapDispatchToProps = (dispatch: Dispatch) => ({
	oauthDistributorAction: (data: Auth) =>
		dispatch(authDistributorAction.request(data)),
	oauthSubDistributorAction: (data: Auth) =>
		dispatch(authSubDistributorAction.request(data)),
	oauthAdministratorAction: (data: Auth) =>
		dispatch(authAdministratorAction.request(data)),
	clearToast: () => dispatch(clearNotificationAction()),
});

const mapStateToProps = (state: RootStateType): StateProps => ({
	pending: state.AuthenticationReducer.pending,
	toast: state.ToastReducer.notification,
});

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(Component);
