import { DistributorStateType } from "./_types";
import { getType } from "typesafe-actions";
import {
  distributorUserRegisterPostAction,
  distributorDeleteByIdAction,
  distributorGetByIdAction,
  distributorPostAction,
  distributorPutAction,
  DistributorsActionType,
  distributorsGetAction,
  distributorUsers,
  distributorUserPutAction,
  distributorUserGetByIdAction,
  distributorUserDeleteByIdAction,
  distributorReactivatePostAction,
  distributorsInit,
} from "./_actions";

const initialState: DistributorStateType = {
  pending: false,
  error: null,
  distributors: null,
  distributor: null,
  newDistributor: null,
  deleting: false,
  deletedId: null,
  users: null,
  user: null,
};

export const DistributorReducer = (
  state: DistributorStateType = initialState,
  action: DistributorsActionType
): DistributorStateType => {
  switch (action.type) {
    case getType(distributorsInit):
      return {
        ...initialState,
      };
    case getType(distributorsGetAction.request):
      return {
        ...initialState,
        pending: true,
      };
    case getType(distributorPostAction.request):
    case getType(distributorPutAction.request):
    case getType(distributorUserRegisterPostAction.request):
    case getType(distributorUsers.request):
    case getType(distributorGetByIdAction.request):
    case getType(distributorUserPutAction.request):
    case getType(distributorUserGetByIdAction.request):
    case getType(distributorUserDeleteByIdAction.request):
    case getType(distributorReactivatePostAction.request):
      return {
        ...state,
        pending: true,
      };
    case getType(distributorDeleteByIdAction.request):
      return {
        ...state,
        deleting: true,
        deletedId: action.payload,
      };
    case getType(distributorGetByIdAction.success):
      return {
        ...state,
        distributor: action.payload,
        pending: false,
      };
    case getType(distributorUserGetByIdAction.success):
      return {
        ...state,
        user: action.payload,
        pending: false,
      };
    case getType(distributorUserDeleteByIdAction.success):
      return {
        ...state,
        users: action.payload,
        pending: false,
      };
    case getType(distributorsGetAction.success):
    case getType(distributorReactivatePostAction.success):
      return {
        ...state,
        distributors: action.payload,
        pending: false,
      };
    case getType(distributorUserRegisterPostAction.success):
      return {
        ...state,
        pending: false,
        users: action.payload,
      };
    case getType(distributorUserPutAction.success):
      return {
        ...state,
        pending: false,
        users: action.payload,
      };
    case getType(distributorPutAction.success):
      return {
        ...state,
        pending: false,
      };
    case getType(distributorPostAction.success):
      return {
        ...state,
        newDistributor: true,
        pending: false,
      };
    case getType(distributorDeleteByIdAction.success):
      return {
        ...state,
        deleting: false,
        distributors: action.payload,
      };
    case getType(distributorUsers.success):
      return {
        ...state,
        users: action.payload,
        pending: false,
      };
    case getType(distributorsGetAction.failure):
    case getType(distributorPutAction.failure):
    case getType(distributorPostAction.failure):
    case getType(distributorUserRegisterPostAction.failure):
    case getType(distributorGetByIdAction.failure):
    case getType(distributorDeleteByIdAction.failure):
    case getType(distributorUserGetByIdAction.failure):
    case getType(distributorUserPutAction.failure):
    case getType(distributorUserDeleteByIdAction.failure):
    case getType(distributorReactivatePostAction.failure):
      return {
        ...state,
        error: action.payload.ErrorMessage,
        pending: false,
        deleting: false,
      };
    default:
      return state;
  }
};
