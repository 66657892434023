import React from "react";
import styled from "styled-components";
import { Box } from "../Box";
import { ButtonType, MaterialButton } from "components/Button/MaterialButton";
import { Clients } from "components/DistributorsClient";
import { Content } from "../Content";
import { ContentRow } from "constants/globalStyles";
import { Distributor } from "../../containers/Portal/Admin/Distributors/UsersTable/_types";
import { lang } from "translation/i18n";
import { Routes } from "../../constants/routes";
import { Status } from "components/Status";
import { translationPath } from "utils/getPath";
import { useTranslation } from "react-i18next";
import {
  Storage,
  Validity,
} from "containers/Portal/Admin/Clients/ClientsTable/_types";

export interface Props {
  distributors: Distributor[];
  pending: boolean;
  deletedId: string;
  deleting: boolean;
  clientsDeleteByIdAction: (data: string) => void;
  clientsPostStorageAction: (data: Storage) => void;
  clientsPostValidityAction: (data: Validity) => void;
  pathToCreateDistributor: Routes;
  pathToEditDistributors: Routes;
  distributorDeleteByIdAction: (data: string) => void;
  clientsReactivate: (data: string) => void;
  distributorReactivatePostAction: (data: string) => void;
}

export const DistributorsWithClients: React.FC<Props> = ({
  pending,
  deletedId,
  deleting,
  distributors,
  clientsDeleteByIdAction,
  clientsPostStorageAction,
  clientsPostValidityAction,
  distributorDeleteByIdAction,
  pathToCreateDistributor,
  pathToEditDistributors,
  clientsReactivate,
  distributorReactivatePostAction,
}) => {
  const { t } = useTranslation();

  const distributorsDelete = (data: string) => () => {
    distributorDeleteByIdAction(data);
  };

  const reactivate = (data: string) => () => {
    distributorReactivatePostAction(data);
  };

  return (
    <>
      <Box
        title={t(translationPath(lang.common.distributors).path)}
        action={[
          <MaterialButton
            tooltip={t(translationPath(lang.button.newItem).path)}
            to={pathToCreateDistributor}
            type={ButtonType.NEW}
          />,
        ]}
      >
        <Content pending={pending} withoutMargin>
          {distributors &&
            distributors?.map((value: Distributor, key: number) => {
              var clients = value.Clients;
              value.SubDistributors &&
                value.SubDistributors.forEach((element) => {
                  if (clients && clients.length > 0) {
                    if (element.Clients && element.Clients.length > 0) {
                      clients = [...clients, ...element.Clients];
                    }
                  } else {
                    if (element.Clients && element.Clients.length > 0) {
                      clients = element.Clients;
                    }
                  }
                });
              return (
                <Box
                  title={
                    <ContentRow>
                      <Status status={value.IsActive} />
                      <DistributorName>{value.Name}</DistributorName>
                    </ContentRow>
                  }
                  action={[
                    <ContentRow>
                      <MaterialButton
                        tooltip={t(translationPath(lang.button.editItem).path)}
                        type={ButtonType.EDIT}
                        to={{
                          pathname: pathToEditDistributors + "/" + value.Id,
                          state: value,
                        }}
                        loading={deleting && deletedId === value.Id}
                      />
                      <MaterialButton
                        tooltip={t(
                          translationPath(
                            value.IsDeleted
                              ? lang.button.activate
                              : lang.button.deleteItem
                          ).path
                        )}
                        onClick={
                          value.IsDeleted
                            ? reactivate(value.Id)
                            : distributorsDelete(value.Id)
                        }
                        type={
                          value.IsDeleted ? ButtonType.LOAD : ButtonType.DELETE
                        }
                        loading={deleting && deletedId === value.Id}
                      />
                    </ContentRow>,
                  ]}
                  bottom={true}
                >
                  {clients && clients.length > 0 && (
                    <Clients
                      clients={clients}
                      deleting={deleting}
                      deletedId={deletedId}
                      clientsDeleteByIdAction={clientsDeleteByIdAction}
                      clientsPostStorageAction={clientsPostStorageAction}
                      clientsPostValidityAction={clientsPostValidityAction}
                      clientsReactivate={clientsReactivate}
                    />
                  )}
                </Box>
              );
            })}
        </Content>
      </Box>
    </>
  );
};

const DistributorName = styled.div`
  color: #2e2e2e;
  padding: 8px;
`;
