import moment from 'moment';
import React from 'react';
import { Capacity } from 'components/Capacity';
import { DateFormat } from 'constants/enum';
import { IsDeleted } from 'components/IsDeleted';
import { lang } from 'translation/i18n';
import { lastPathMember, translationPath } from 'utils/getPath';
import { LicenseValidity } from 'components/LicenseValidity';
import { ScrollTable } from 'components/Table';
import { Status } from 'components/Status';
import { useTranslation } from 'react-i18next';
import {
	Client,
	ClientProxy,
	Storage,
	Validity,
} from "containers/Portal/Admin/Clients/ClientsTable/_types";

export interface ClientsProps {
	clients: Client[];
	deletedId: string;
	deleting: boolean;
	clientsDeleteByIdAction: (data: string) => void;
	clientsPostStorageAction: (data: Storage) => void;
	clientsPostValidityAction: (data: Validity) => void;
	clientsReactivate: (data: string) => void;
}

export const Clients: React.FC<ClientsProps> = ({ clients }) => {
	const { t } = useTranslation();

	return (
		<ScrollTable
			columns={React.useMemo<any[]>(
				() => [
					{
						Header: t(translationPath(lang.table.status).path),
						Cell: ({ row }) => {
							return <Status status={row.original.IsActive} />;
						},
					},

					{
						Header: t(translationPath(lang.table.name).path),
						accessor: lastPathMember(ClientProxy.Name).path,
					},
					{
						Header: t(translationPath(lang.table.licenseIssued).path),
						Cell: ({ row }) => {
							return moment(row.original.LicenseIssued).format(
								DateFormat.DATE_CZ
							);
						},
					},
					{
						Header: t(translationPath(lang.table.licenseValidityEnd).path),
						Cell: ({ row }) => {
							return (
								<LicenseValidity
									licenseValidityEnd={row.original.LicenseValidityEnd}
									licenseValid={row.original.LicenseValid}
								/>
							);
						},
					},
					{
						Header: t(translationPath(lang.table.maxStorage).path),
						Cell: ({ row }) => {
							return (
								<Capacity
									maxCapacity={row.original.MaxStorage}
									currentCapacity={row.original.CurrentStorage}
								/>
							);
						},
					},
					{
						Header: t(translationPath(lang.table.deleted).path),
						Cell: ({ row }) => {
							return <IsDeleted isDeleted={row.original.IsDeleted} />;
						},
					},
				],
				[clients]
			)}
			data={React.useMemo<Client[]>(() => clients, [clients])}
			pagination={false}
		/>
	);
};
