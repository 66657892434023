import { AdministratorSettingsReducer } from '../containers/Portal/Admin/Settings/_reducers';
import { AuthenticationAdminReducer } from '../containers/Authentication/Admin/_reducers';
import { AuthenticationDistributorReducer } from '../containers/Authentication/Distributor/_reducers';
import { AuthenticationReducer } from '../containers/Authentication/SubDistributor/_reducers';
import { AuthenticationSuperAdminReducer } from '../containers/Authentication/SuperAdmin/_reducers';
import { ClientsReducer } from '../containers/Portal/Admin/Clients/ClientsTable/_reducers';
import { combineReducers } from 'redux';
import { connectRouter, RouterActionType } from 'connected-react-router';
import { DistributorReducer } from '../containers/Portal/Admin/Distributors/UsersTable/_reducers';
import { History, Location, LocationState } from 'history';
import { ResetPasswordReducer } from '../containers/Authentication/Reset/_reducers';
import { ReuestResetPasswordReducer } from '../containers/Authentication/ResetRequest/_reducers';
import { SettingsReducer } from '../containers/Portal/SuperAdmin/Settings/_reducers';
import { StateType } from 'typesafe-actions';
import { SubDistributorsReducer } from '../containers/Portal/SubDistributor/_reducers';
import { ToastReducer } from '../components/Toast/_reducers';
import { UserReducer } from '../containers/Portal/SuperAdmin/Users/UsersTable/_reducers';

let reducers = {
	AdministratorSettingsReducer,
	AuthenticationAdminReducer,
	AuthenticationDistributorReducer,
	AuthenticationReducer,
	AuthenticationSuperAdminReducer,
	ClientsReducer,
	DistributorReducer,
	SettingsReducer,
	ToastReducer,
	UserReducer,
	ReuestResetPasswordReducer,
	SubDistributorsReducer,
	ResetPasswordReducer,
	router: null,
};

export default function createRootReducer(history: History) {
	return combineReducers({ ...reducers, router: connectRouter(history) });
}

export type RootStateType = StateType<typeof reducers>;

export interface RouterReducerType<T = LocationState> {
	router: {
		location: Location<T>;
		action: RouterActionType;
	};
}
