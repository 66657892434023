import * as React from 'react';
import styled from 'styled-components';

interface OwnProps {
  formik: any;
  name: string;
}

const ErrorMessage = (props: OwnProps) => {
  const {formik, name} = props;
  if (
      formik &&
      formik.errors &&
      formik.touched &&
      formik.touched[name] &&
      formik.errors[name]
  ) {
    return <Message>{formik.errors[name]}</Message>;
  }
  return null;
};

export default ErrorMessage;

export const Message = styled.div`
  font-size: 0.8em;
  color: #c00;
  padding: 0.5em;
`;
