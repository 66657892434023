import React from 'react';
import UserTable from './ClientsTable/Component';
import { Alert } from 'components/Alert';
import { Content } from 'components/Content';
import { ContentColumn } from 'constants/globalStyles';
import { lang } from 'translation/i18n';
import { translationPath } from 'utils/getPath';
import { useTranslation } from 'react-i18next';
import {
	Client,
	Storage,
	Validity,
} from "containers/Portal/Admin/Clients/ClientsTable/_types";

export interface StateProps {
	clients: Client[];
	deletedId: string;
	deleting: boolean;
	pending: boolean;
	newClient: boolean;
}

export interface DispatchProps {
	clientsGetAction: (data: void) => void;
	clientsDeleteByIdAction: (data: string) => void;
	clientsPostStorageAction: (data: Storage) => void;
	clientsPostValidityAction: (data: Validity) => void;
	clientsReactivate: (data: string) => void;
}

const Component: React.FC<StateProps & DispatchProps> = ({
	deletedId,
	deleting,
	pending,
	clients,
	newClient,
	clientsGetAction,
	clientsDeleteByIdAction,
	clientsPostStorageAction,
	clientsPostValidityAction,
	clientsReactivate,
}) => {
	const { t } = useTranslation();

	return (
		<Content title={t(translationPath(lang.common.clients).path)}>
			<ContentColumn>
				{newClient && (
					<Alert
						children={
							<>{t(translationPath(lang.toast.createClientSuccess).path)}</>
						}
					/>
				)}
				<UserTable
					clients={clients}
					pending={pending}
					clientsGetAction={clientsGetAction}
					deleting={deleting}
					deletedId={deletedId}
					clientsDeleteByIdAction={clientsDeleteByIdAction}
					clientsPostStorageAction={clientsPostStorageAction}
					clientsPostValidityAction={clientsPostValidityAction}
					clientsReactivate={clientsReactivate}
				/>
			</ContentColumn>
		</Content>
	);
};

export default React.memo(Component);
