import { ApiURL } from "constants/api";
import { call, put, takeLatest } from "redux-saga/effects";
import { fetchSaga } from "sagas/_sagas";
import { getType } from "typesafe-actions";
import { lang, t } from "translation/i18n";
import { Method } from "constants/enum";
import { notificationAction } from "components/Toast/_actions";
import { push } from "connected-react-router";
import { Status } from "components/Toast/_types";
import { translationPath } from "utils/getPath";

import {
  clientsDeleteByIdAction,
  clientsGetAction,
  clientsGetByIdAction,
  clientsPostAction,
  clientsPutAction,
  clientsPostStorageAction,
  clientsPostValidityAction,
  clientsReactivate,
} from "./_actions";

function* clientsGetActionSaga(
  action: ReturnType<typeof clientsGetAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.CLIENTS,
      Method.GET
    );

    if (!success || errorResponse) {
      yield put(clientsGetAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(clientsGetAction.success(response));
    }
  } catch (err) {
    yield put(clientsGetAction.failure(err));
  }
}

function* clientsGetByIdActionSaga(
  action: ReturnType<typeof clientsGetByIdAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.CLIENTS,
      Method.GET,
      {
        param: action.payload,
      }
    );
    console.log(action.payload);
    if (!success || errorResponse) {
      yield put(clientsGetByIdAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(clientsGetByIdAction.success(response));
    }
  } catch (err) {
    yield put(clientsGetByIdAction.failure(err));
  }
}

function* clientsPostActionSaga(
  action: ReturnType<typeof clientsPostAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.CLIENTS,
      Method.POST,
      {
        bodyJSON: action.payload.client,
      }
    );
    if (!success || errorResponse) {
      yield put(clientsPostAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(push(action.payload.successPathRedirection));
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(clientsPostAction.success(response));
    }
  } catch (err) {
    yield put(clientsPostAction.failure(err));
  }
}

function* clientsPutActionSaga(
  action: ReturnType<typeof clientsPutAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.CLIENTS,
      Method.PUT,
      {
        param: action.payload.client.Id,
        bodyJSON: action.payload.client,
      }
    );
    if (!success || errorResponse) {
      yield put(clientsPutAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(push(action.payload.successPathRedirection));
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(clientsPutAction.success(response));
    }
  } catch (err) {
    yield put(clientsPutAction.failure(err));
  }
}

function* clientsReactivatePostActionSaga(
  action: ReturnType<typeof clientsReactivate.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.CLEINT_REACTIVATE,
      Method.POST,
      {
        bodyJSON: { Id: action.payload },
      }
    );

    if (!success || errorResponse) {
      yield put(clientsReactivate.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(clientsReactivate.success(response));
    }
  } catch (err) {
    yield put(clientsReactivate.failure(err));
  }
}

function* clientsPostStorageActionSaga(
  action: ReturnType<typeof clientsPostStorageAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.CLIENTS_STORAGE,
      Method.POST,
      {
        bodyJSON: action.payload,
      }
    );

    if (!success || errorResponse) {
      yield put(clientsPostStorageAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(clientsGetAction.request());
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(clientsPostStorageAction.success(response));
    }
  } catch (err) {
    yield put(clientsPostStorageAction.failure(err));
  }
}

function* clientsPostValidityActionSaga(
  action: ReturnType<typeof clientsPostValidityAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.CLIENTS_VALIDITY,
      Method.POST,
      {
        bodyJSON: action.payload,
      }
    );

    if (!success || errorResponse) {
      yield put(clientsPostValidityAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(clientsGetAction.request());
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(clientsPostValidityAction.success(response));
    }
  } catch (err) {
    yield put(clientsPostValidityAction.failure(err));
  }
}

function* clientsDeleteActionSaga(
  action: ReturnType<typeof clientsDeleteByIdAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.CLIENTS,
      Method.DELETE,
      {
        param: action.payload,
      }
    );
    if (!success || errorResponse) {
      yield put(clientsDeleteByIdAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(clientsGetAction.request());
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(clientsDeleteByIdAction.success(response));
    }
  } catch (err) {
    yield put(clientsDeleteByIdAction.failure(err));
  }
}

export function* watchClientsGetAction() {
  yield takeLatest(getType(clientsGetAction.request), clientsGetActionSaga);
}

export function* watchClientsPostAction() {
  yield takeLatest(getType(clientsPostAction.request), clientsPostActionSaga);
}

export function* watchClientsPutAction() {
  yield takeLatest(getType(clientsPutAction.request), clientsPutActionSaga);
}

export function* watchClientsPostStorageAction() {
  yield takeLatest(
    getType(clientsPostStorageAction.request),
    clientsPostStorageActionSaga
  );
}

export function* watchClientsPostValidityAction() {
  yield takeLatest(
    getType(clientsPostValidityAction.request),
    clientsPostValidityActionSaga
  );
}

export function* watchClientGetByIdAction() {
  yield takeLatest(
    getType(clientsGetByIdAction.request),
    clientsGetByIdActionSaga
  );
}

export function* watchClientsDeleteAction() {
  yield takeLatest(
    getType(clientsDeleteByIdAction.request),
    clientsDeleteActionSaga
  );
}

export function* watchClientsReactivationAction() {
  yield takeLatest(
    getType(clientsReactivate.request),
    clientsReactivatePostActionSaga
  );
}
