import React from 'react';
import UserTable from './UsersTable/Component';
import { Content } from 'components/Content';
import { ContentSpaceBetween } from 'constants/globalStyles';
import { lang } from 'translation/i18n';
import { translationPath } from 'utils/getPath';
import { User } from './UsersTable/_types';
import { useTranslation } from 'react-i18next';

export interface StateProps {
	users: User[];
	deletedId: string;
	deleting: boolean;
	pending: boolean;
}

export interface DispatchProps {
	usersGetAction: (data: void) => void;
	userDeleteByIdAction: (data: string) => void;
	userPutAction: (data: User) => void;
}

const Component: React.FC<StateProps & DispatchProps> = ({
	users,
	usersGetAction,
	deletedId,
	deleting,
	pending,
	userPutAction,
	userDeleteByIdAction,
}) => {
	const { t } = useTranslation();

	return (
		<Content title={t(translationPath(lang.users.title).path)}>
			<ContentSpaceBetween>
				<UserTable
					userPutAction={userPutAction}
					users={users}
					pending={pending}
					usersGetAction={usersGetAction}
					userDeleteByIdAction={userDeleteByIdAction}
					deleting={deleting}
					deletedId={deletedId}
				/>
			</ContentSpaceBetween>
		</Content>
	);
};

export default React.memo(Component);
