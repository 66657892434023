import React from 'react';
import styled from 'styled-components';
import { Center, ContentRow } from 'constants/globalStyles';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { lang } from 'translation/i18n';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from 'constants/routes';
import { translationPath } from 'utils/getPath';
import { useTranslation } from 'react-i18next';

interface IProps {
	oauthSuperAdminLogoutAction: () => void;
}
export const Header: React.FC<IProps> = ({ oauthSuperAdminLogoutAction }) => {
	const { t } = useTranslation();
	let history = useHistory();
	const logout = () => {
		oauthSuperAdminLogoutAction();
		history.push(Routes.HOME);
	};
	return (
		<>
			<Toast>
				<FontAwesomeIcon icon={faInfoCircle as IconProp} />
				{t(translationPath(lang.common.superAdmin).path)}
			</Toast>
			<Navbar>
				<Menu to={Routes.SUPER_ADMINISTRATOR_PORTAL}>
					{t(translationPath(lang.users.title).path)}
				</Menu>
				<Menu to={Routes.SUPER_ADMIN_SETTINGS}>
					{t(translationPath(lang.common.settings).path)}
				</Menu>
				<Logout onClick={logout}>
					{t(translationPath(lang.common.logout).path)}
				</Logout>
			</Navbar>
		</>
	);
};

const Toast = styled(Center)`
	padding: 1em;
	color: ${(props) => props.theme.colors.text.white};

	background-color: #1e8900;
	font-size: 0.8em;
`;
const Navbar = styled(ContentRow)`
	justify-content: center;
	background-color: #232f3e;
`;

const Menu = styled(Link)`
	padding: 0.9em 1em;
	text-decoration: none;
	color: ${(props) => props.theme.colors.text.white};
	&:hover {
		background-color: #202b3e;
	}
`;

const Logout = styled.div`
	padding: 0.9em 1em;
	text-decoration: none;
	cursor: pointer;
	color: ${(props) => props.theme.colors.text.white};
	&:hover {
		background-color: #202b3e;
	}
`;
