import styled from 'styled-components';

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const FullHeight = styled.div`
	margin: auto;
	width: 100%;
`;
