import React, {FC} from "react";
import {makeStyles} from "@material-ui/core/styles";
import AlertMessage from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";

interface AlertProps {
    children: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "1.1em",
        borderLeft: "4px solid #1e8900",
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}));

export const Alert: FC<AlertProps> = ({children}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    return (
        <div className={classes.root}>
            <Collapse in={open}>
                <AlertMessageWithStyled
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {children}
                </AlertMessageWithStyled>
            </Collapse>
        </div>
    );
};

export const AlertMessageWithStyled = styled(AlertMessage)`
  background-color: #daecde !important;
`;
