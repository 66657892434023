export enum ApiURL {
  DISTRIBUTOR = "api/v1/distributors",
  DISTRIBUTOR_REACTIVATE = "api/v1/distributors/reactivate",
  DISTRIBUTOR_REGISTER_USER = "api/v1/distributors/users/register",
  DISTRIBUTOR_USERS = "api/v1/distributors/users",
  

  OAUTH_DISTRIBUTOR = "/api/v1/distributors/login",
  OAUTH_SUB_DISTRIBUTOR = "/api/v1/subdistributors/login",

  SUBDISTRIBUTOR_REGISTER_USER = "/api/v1/subdistributors/users/register",
  SUBDISTRIBUTOR_REGISTER = "/api/v1/subdistributors/register",
  SUBDISTRIBUTOR_REACTIVATE = "/api/v1/subdistributors/reactivate",
  SUBDISTRIBUTOR_USERS = "/api/v1/subdistributors/users",
  SUBDISTRIBUTOR = "/api/v1/subdistributors",

  RESET_PASSWORD = "/api/v1/users/reset",
  RESET = "/api/v1/reset",
  OAUTH_ADMINISTRATOR = "/api/v1/admin/login",
  ADMINISTRATOR_RESET_PASSWORD = "/api/v1/admin/reset",
  ADMINISTRATOR_USER = "/api/v1/admin/user",
  OAUTH_USERS = "/api/v1/users/login",
  OAUTH_ADMIN = "/api/v1/adminusers/login",
  OAUTH_SUPER_ADMIN = "api/v1/superadmin/login",
  OAUTH_SUPER_ADMIN_RESET = "api/v1/superadmin/reset",
  SUPER_ADMIN_RESET_PASSWORD = "api/v1/superadmin/password",
  SUPER_ADMIN_USERS = "/api/v1/admin/users",
  SUPER_ADMIN_USER = "/api/v1/admin/user",
  SUPER_ADMIN_USER_REGISTER = "/api/v1/admin/users/register",
  CLIENTS = "/api/v1/clients",
  CLIENTS_STORAGE = "/api/v1/clients/storage",
  CLIENTS_VALIDITY = "/api/v1/clients/validity",
  CLEINT_REACTIVATE = "/api/v1/clients/reactivate",
}

export const AUTH_SUPER_ADMIN = "/api/v1/superadminusers/login";
