import * as Yup from 'yup';
import FormikRow from '../../../components/Form/FormikRow';
import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { Header } from '../SubDistributor/components/Header';
import { Input } from '../../../constants/enum';
import { lang } from 'translation/i18n';
import { ResetPassword } from './_types';
import { translationPath } from 'utils/getPath';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
	ContentCard,
	GridItem,
	GridRow,
	Justify,
	PageWrapper,
} from "constants/globalStyles";

export interface StateProps {
	pending: boolean;
}

export interface DispatchProps {
	resetPasswordAction: (data: string) => void;
}

const Component: React.FC<StateProps & DispatchProps> = ({
	pending,
	resetPasswordAction,
}) => {
	const { t } = useTranslation();

	const initialValues: ResetPassword = {
		email: "",
	};

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: Yup.object({
			email: Yup.string()
				.email(t(translationPath(lang.validation.email).path))
				.required(t(translationPath(lang.validation.required).path)),
		}),
		onSubmit: (value: ResetPassword) => {
			resetPasswordAction(value.email);
		},
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<Header />
			<PageWrapper>
				<GridRow>
					<GridItem>
						<Wrapper>
							<Title>
								{t(translationPath(lang.common.resetRequestPassword).path)}
							</Title>
							<FormWrapper>
								<FormikRow
									formik={formik}
									name="email"
									title={t(translationPath(lang.input.email).path)}
									type={Input.EMAIL}
									titleUp
								/>
								<ContentWrapper justify={Justify.END}>
									<ButtonOAuth type="submit" loading={pending}>
										{t(
											translationPath(lang.common.resetRequestPasswordSubmit)
												.path
										)}
									</ButtonOAuth>
								</ContentWrapper>
							</FormWrapper>
						</Wrapper>
					</GridItem>
				</GridRow>
			</PageWrapper>
		</form>
	);
};

export default React.memo(Component);

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	height: auto;
	min-height: 500px;
	width: 679px;

	margin: 8em auto;
	border-radius: 8px;
	background-color: #fff;
	box-shadow: 0 80px 99px rgba(0, 0, 0, 0.08);
`;

const Title = styled.h3`
	color: ${(props) => props.theme.colors.primaryText.default};

	margin-top: 2em;
	font-size: 1.4em;
	font-weight: 500;
`;

const ButtonOAuth = styled(Button)`
	background-color: ${(props) => props.theme.colors.primary};

	&:hover {
		background: #177864;
		-webkit-box-shadow: 0 1px 1px 0 rgba(0, 94, 44, 0.45),
			0 1px 3px 1px rgba(0, 94, 44, 0.3);
		box-shadow: 0 1px 1px 0 rgba(33, 94, 13, 0.45),
			0 1px 3px 1px rgba(0, 94, 44, 0.3);
	}
`;

const ContentWrapper = styled(ContentCard)`
	margin-top: 3em;
	width: 100%;
`;

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;

	padding: 0px 40px 36px;

	width: 100%;

	margin: 2em 0px;
`;
