import * as React from 'react';
import DateInput from './DateInput';
import { BaseInlineInput } from './BaseInput';
import { FormikCheckBox } from './FormikCheckbox';
import { GroupedOptionsType, OptionsType } from 'react-select';
import { Input } from 'constants/enum';
import { OptionType, ReactSelect } from './Select';
import { PhoneNumber } from './PhoneNumber';

interface FormRowProps {
	id: string;
	name?: string;
	formik?: any;
	type: Input;
	options?: GroupedOptionsType<OptionType> | OptionsType<OptionType>;
	format?: string;
	placeholder?: string;
	title?: string;
	multiline?: boolean;
	disabled?: boolean;
	countryCode?: string;
	phoneNumber?: string;
}

const FormikInput = (props: FormRowProps) => {
	const {
		formik,
		format,
		id,
		name,
		options,
		placeholder,
		type,
		countryCode,
		phoneNumber,
		disabled,
	} = props;

	const hasNumber = (myString: string) => {
		return /\d/.test(myString);
	};

	switch (type) {
		case Input.TEXT:
			return <BaseInlineInput {...props} type="text" />;
		case Input.PHONE:
			return (
				<PhoneNumber
					id={id}
					countryCode={countryCode}
					phoneNumber={phoneNumber}
					formik={formik}
					placeholder={placeholder}
					disabled={disabled}
					{...formik.getFieldProps(name)}
				/>
			);
		case Input.PASSWORD:
			return <BaseInlineInput {...props} type="password" />;
		case Input.NUMERIC:
			return <BaseInlineInput {...props} type="number" />;
		case Input.EMAIL:
			return <BaseInlineInput {...props} type="email" />;
		case Input.DATE:
			return (
				<DateInput
					id={id}
					name={name}
					formik={formik}
					format={format}
					disabled={disabled}
					{...formik.getFieldProps(name)}
				/>
			);
		case Input.SELECT:
			const item =
				options &&
				(options as OptionType[]).find(
					(item: { label: string; value: string }, key: number) => {
						let comparator = formik.values[name];
						if (hasNumber(name) && name) {
							const names = name.split(".");
							let temp = formik.values[names[0]];
							for (let i = 1; i < names.length; i++) {
								temp = temp[names[i]];
							}
							comparator = temp;
							if (item.value === comparator) {
								return item;
							}
						} else {
							if (name.includes(".") && name) {
								const names = name.split(".");
								let temp = formik.values[names[0]];
								var x = temp[names[1]];
								if (item.value === x) {
									return item;
								}
							} else {
								if (item.value === formik.values[name]) {
									return item;
								}
							}
						}
						return null;
					}
				);
			return (
				<ReactSelect
					formik={formik}
					name={name}
					value={
						formik.values[name]
							? {
									value: formik.values[name],
									label: item && item.label,
							  }
							: null
					}
					options={options}
					placeholder={placeholder}
				/>
			);
		case Input.CHECKBOX:
			return (
				<FormikCheckBox
					input
					name={name}
					formik={formik}
					value={formik.values[name]}
					label={props.title}
				/>
			);
	}
};

export default FormikInput;
