import React from "react";
import styled from "styled-components";
import {ContentSpaceBetween} from "constants/globalStyles";

interface Pagination {
    gotoPage: (val: number) => void;
    canPreviousPage: boolean;
    canNextPage: boolean;
    previousPage: () => void;
    nextPage: () => void;
    pageCount: number;
    pageOptions: any;
    pageIndex: number;
    setPageSize: (val: number) => void;
    pageSize: number;
}

export const Pagination: React.FC<Pagination> = ({
                                                     pageSize,
                                                     setPageSize,
                                                     pageIndex,
                                                     gotoPage,
                                                     canPreviousPage,
                                                     previousPage,
                                                     nextPage,
                                                     canNextPage,
                                                     pageCount,
                                                     pageOptions,
                                                 }) => {
    return (
        <ContentSpaceBetweenCenter>
            <Content> </Content>
            <Content>
                <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {"<<"}
                </Button>{" "}
                <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {"<"}
                </Button>{" "}
                <Span>
                    {pageIndex + 1} / {pageOptions.length}
                </Span>{" "}
                <Button onClick={() => nextPage()} disabled={!canNextPage}>
                    {">"}
                </Button>{" "}
                <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {">>"}
                </Button>{" "}
            </Content>
            <Select
                value={pageSize}
                onChange={(e) => {
                    setPageSize(Number(e.target.value));
                }}
            >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                    <Option key={pageSize} value={pageSize}>
                        {pageSize}
                    </Option>
                ))}
            </Select>
        </ContentSpaceBetweenCenter>
    );
};

const ContentSpaceBetweenCenter = styled(ContentSpaceBetween)`
  align-items: center;
`;
const Button = styled.button`
  color: #ccc;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    color: #222;
  }
`;

const Content = styled.div`
  padding: 8px;
  color: #222;
`;

const Select = styled.select`
  border: none;
  background: none;
  text-indent: 0px !important;
  color: #cccacc;
  cursor: pointer;
  margin-right: 10px;
`;

const Option = styled.option`
  padding: 7px 7px;
  font-size: 0.85em;
  border: none;
`;

const Span = styled.span`
  font-size: 0.85em;
  color: #cccacc;

  &:hover {
    color: #222;
  }
`;
