import { ButtonType, MaterialButton } from 'components/Button/MaterialButton';
import { Capacity } from 'components/Capacity';
import { IsDeleted } from 'components/IsDeleted';
import { LicenseValidity } from 'components/LicenseValidity';
import { Status } from 'components/Status';
import { ScrollTable } from 'components/Table';
import { DateFormat } from 'constants/enum';
import { ContentRow } from 'constants/globalStyles';
import { Routes } from 'constants/routes';
import { Client, ClientProxy, Storage, Validity } from 'containers/Portal/Admin/Clients/ClientsTable/_types';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { lang } from 'translation/i18n';
import { lastPathMember, translationPath } from 'utils/getPath';

export interface ClientsProps {
	clients: Client[];
	deletedId: string;
	deleting: boolean;
	pathToEditClients: Routes;
	clientsDeleteByIdAction: ( data: string ) => void;
	clientsPostStorageAction: ( data: Storage ) => void;
	clientsPostValidityAction: ( data: Validity ) => void;
	clientsReactivate: ( data: string ) => void;
}

export const Clients: React.FC<ClientsProps> = ( {
	deletedId,
	deleting,
	clients,
	pathToEditClients,
	clientsDeleteByIdAction,
	clientsPostStorageAction,
	clientsPostValidityAction,
	clientsReactivate,
} ) => {
	const { t } = useTranslation();

	const deleteItem = ( data: string ) => () => {
		clientsDeleteByIdAction( data );
	};

	const activate = ( data: string ) => () => {
		clientsReactivate( data );
	};

	const renewal = ( id: string, data: any, add: number ) => () => {
		const date = new Date( data );
		date.setDate( date.getDate() + add );
		clientsPostValidityAction( {
			Id: id,
			Validity: date.toDateString(),
		} );
	};
	const capacityIncrease = (
		id: string,
		data: number,
		current: number
	) => () => {
		clientsPostStorageAction( {
			Id: id,
			Storage: data + ( current ? current : 0 ),
		} );
	};

	return (
		<ScrollTable
			columns={React.useMemo<any[]>(
				() => [
					{
						Header: t( translationPath( lang.table.status ).path ),
						Cell: ( { row } ) => {
							return <Status status={row.original.IsActive} />;
						},
					},

					{
						Header: t( translationPath( lang.table.name ).path ),
						accessor: lastPathMember( ClientProxy.Name ).path,
					},

					{
						Header: t( translationPath( lang.table.clientLicenceNumber ).path ),
						accessor: lastPathMember( ClientProxy.ClientLicenceNumber ).path,
					},

					{
						Header: t( translationPath( lang.table.contact ).path ),
						accessor: lastPathMember( ClientProxy.AdminEmail ).path,
					},

					{
						Header: t( translationPath( lang.table.licenseIssued ).path ),
						Cell: ( { row } ) => {
							return moment( row.original.LicenseIssued ).format(
								DateFormat.DATE_CZ
							);
						},
					},
					{
						Header: t( translationPath( lang.table.licenseValidityEnd ).path ),
						Cell: ( { row } ) => {
							return (
								<LicenseValidity
									licenseValidityEnd={row.original.LicenseValidityEnd}
									licenseValid={row.original.LicenseValid}
								/>
							);
						},
					},
					{
						Header: t( translationPath( lang.table.licenseRenewal ).path ),
						Cell: ( { row } ) => {
							return (
								<ContentRow>
									<MaterialButton
										tooltip={t(
											translationPath( lang.table.licenseRenewalHalfYear ).path
										)}
										onClick={renewal(
											row.original.Id,
											row.original.LicenseValidityEnd,
											182.5
										)}
										type={ButtonType.RENEWAL}
										loading={
											row.origin && deleting && deletedId === row.origin.Id
										}
										text={"1/2"}
									/>
									<MaterialButton
										tooltip={t(
											translationPath( lang.table.licenseRenewalYear ).path
										)}
										onClick={renewal(
											row.original.Id,
											row.original.LicenseValidityEnd,
											365
										)}
										type={ButtonType.RENEWAL}
										loading={
											row.origin && deleting && deletedId === row.origin.Id
										}
										text={"1"}
									/>
									<MaterialButton
										tooltip={t(
											translationPath( lang.table.licenseRenewalTwoYears ).path
										)}
										onClick={renewal(
											row.original.Id,
											row.original.LicenseValidityEnd,
											365 * 2
										)}
										type={ButtonType.RENEWAL}
										loading={
											row.origin && deleting && deletedId === row.origin.Id
										}
										text={"2"}
									/>
								</ContentRow>
							);
						},
					},
					{
						Header: t( translationPath( lang.table.maxStorage ).path ),
						Cell: ( { row } ) => {
							return (
								<Capacity
									maxCapacity={row.original.MaxStorage}
									currentCapacity={row.original.CurrentStorage}
								/>
							);
						},
					},

					{
						Header: t( translationPath( lang.table.capacityIncrease ).path ),
						Cell: ( { row } ) => {
							return (
								<ContentRow>
									<MaterialButton
										tooltip={t(
											translationPath( lang.table.capacityIncrease5GB ).path
										)}
										onClick={capacityIncrease(
											row.original.Id,
											5,
											row.original.CurrentStorage
										)}
										type={ButtonType.RENEWAL}
										loading={deleting && deletedId === row.original.Id}
										text={t( translationPath( lang.table.Capacity5GB ).path )}
									/>
									<MaterialButton
										tooltip={t(
											translationPath( lang.table.capacityIncrease10GB ).path
										)}
										onClick={capacityIncrease(
											row.original.Id,
											10,
											row.original.CurrentStorage
										)}
										type={ButtonType.RENEWAL}
										loading={deleting && deletedId === row.original.Id}
										text={t( translationPath( lang.table.Capacity10GB ).path )}
									/>
									<MaterialButton
										tooltip={t(
											translationPath( lang.table.capacityIncrease100GB ).path
										)}
										onClick={capacityIncrease(
											row.original.Id,
											100,
											row.original.CurrentStorage
										)}
										type={ButtonType.RENEWAL}
										loading={deleting && deletedId === row.original.Id}
										text={t( translationPath( lang.table.Capacity100GB ).path )}
									/>
								</ContentRow>
							);
						},
					},
					{
						Header: t( translationPath( lang.table.deleted ).path ),
						Cell: ( { row } ) => {
							return <IsDeleted isDeleted={row.original.IsDeleted} />;
						},
					},
					{
						Header: t( translationPath( lang.table.action ).path ),
						Cell: ( { row } ) => {
							return (
								<ContentRow>
									<MaterialButton
										tooltip={t( translationPath( lang.button.editItem ).path )}
										type={ButtonType.EDIT}
										to={{
											pathname: pathToEditClients + "/" + row.original.Id,
											state: row.original,
										}}
										loading={deleting && deletedId === row.original.Id}
									/>

									<MaterialButton
										tooltip={
											row.original.IsDeleted
												? t( translationPath( lang.button.activate ).path )
												: t( translationPath( lang.button.deleteItem ).path )
										}
										onClick={
											row.original.IsDeleted
												? activate( row.original.Id )
												: deleteItem( row.original.Id )
										}
										type={
											row.original.IsDeleted
												? ButtonType.LOAD
												: ButtonType.DELETE
										}
										loading={deleting && deletedId === row.original.Id}
									/>
								</ContentRow>
							);
						},
					},
				],
				[clients]
			)}
			data={React.useMemo<Client[]>( () => clients, [clients] )}
			pagination={false}
		/>
	);
};
