import { Lang } from '../lang';

const dictionary: Lang = {
	distributors: {
		newDistributor: "Nový distributor",
		editDistributor: "Editace distributora",
		newClient: "Nový klient",
		distributor: "distributora",
		editClient: "Editace klienta",
	},
	administrator: {
		editAdministrator: "Editace správce",
	},
	login: {
		subdistributor: "Subdistributor",
		administrator: "Správce",
		titleDistributor: "Přihlášení do distributorského systému",
		titleSubDistributor: "Přihlášení do subdistributorského systému",
		titleAdministrator: "Přihlášení do správcovského systému",
	},
	languages: {
		czech: "Čeština",
		english: "Angličtina",
		german: "Němčina",
	},
	header: {
		language: "Jazyk",
		currency: "Měna",
	},
	table: {
		licenseRenewal: "Prodloužení licence (roky)",
		halfYear: "Půl roku",
		year: "Rok",
		twoYears: "Dva roky",
		licenseRenewalHalfYear: "Prodloužení licence o půl roku",
		licenseRenewalYear: "Prodloužení licence o rok",
		licenseRenewalTwoYears: "Prodloužení licence o dva roky",
		capacityIncrease: "Navýšení kapacity (GB)",
		capacityIncrease5GB: "Navýšení kapacity o 5 GB",
		capacityIncrease10GB: "Navýšení kapacity o 10 GB",
		capacityIncrease100GB: "Navýšení kapacity o 100 GB",
		Capacity5GB: "5",
		Capacity10GB: "10",
		Capacity100GB: "100",
		open: "Zobrazit",
		licenseIssued: "Vydána dne",
		licenseIssuedBy: "Vydal",
		licenseValidityEnd: "Platná do",
		clientLicenceNumber: "Licenční číslo",
		maxStorage: "Kapacita (GB)",
		name: "Název",
		contact: "Kontakt",
		block: "Blokovat",
		size: "Velikost",
		extension: "Přípona",
		action: "Akce",
		deleted: "Smazáno",
		status: "Status",
		active: "Aktivní",
		inactive: "Neaktivní",
		info: "Info",
		allowed: "Povolený",
		default: "Defaultní",
	},
	input: {
		name: "Název",
		picture: "Obrázek",
		pictures: "Obrázky",
		description: "Popis",
		url: "URL",
		currency: "Měna",
		clientLicenceNumber: "Licenční číslo",
		forename: "Jméno",
		surname: "Příjmení",
		countryCode: "Předvolba",
		email: "E-mail",
		phone: "Telefon",
		company: "Firma",
		crn: "IČ",
		address: "Adresa",
		note: "Poznámka",
		username: "Přihlašovací jméno",
		password: "Heslo",
		currentPassword: "Staré heslo",
		newPassword: "Nové heslo",
		validationPassword: "Kontrola hesla",
		login: "Přihlásit se",
		resetPass: "Resetovat heslo",
		changePass: "Změna hesla",
		public: "Veřejné",
	},
	validation: {
		min: "Pole musí obsahovat minimálně {{count}} znaků.",
		max: "Pole může obsahovat maximálně {{count}} znaků.",
		required: "Povinné pole.",
		email: "Zadejte platný e-mail",
		phone: "Zadejte platné telefonní číclo",
		passwordMatch: "Zadaná hesla se neshodují",
	},
	button: {
		more: "Více",
		load: "Načíst více",
		link: "Odkaz",
		loadPrice: "Načíst cenu",
		activate: "Aktivovat",
		editItem: "Editovat položku",
		newItem: "Nová položka",
		deleteItem: "Smazat položku",
		duplicateItem: "Duplikovat položku",
		saveItem: "Uložit položku",
		save: "Uložit",
		resetPassword: "Změna hesla",
	},
	toast: {
		createDistributorSuccess:
			"Distributor byl úspěšně vytvořen. Na jeho e-mail byly automaticky odeslány přihlašovací údaje.",
		createSubDistributorSuccess:
			"Subdistributor byl úspěšně vytvořen. Na jeho e-mail byly automaticky odeslány přihlašovací údaje.",
		createClientSuccess:
			"Klient byl úspěšně vytvořen. Na jeho e-mail byly automaticky odeslány přihlašovací údaje.",
	},
	status: {
		Unauthorized: "Chybně zadané heslo nebo uživatelské jméno",
	},
	users: {
		title: "Uživatelé",
		newUser: "Nový uživatel",
		editUser: "Editace uživatele",
	},
	common: {
		settings: "Nastavení",
		admin: "SuperAdmin prostředí",
		superAdmin: "Super admin prostředí",
		resetSuccessful: "Heslo bylo úspěšně změněno.",
		users: "Uživatelé",
		resetRequestPassword: "Obnova hesla",
		resetRequestPasswordSubmit: "Obnovit",
		resetPassword: "Nové heslo",
		resetPasswordSubmit: "Obnovit",
		distributor: "Distributor",
		distributors: "Distributoři",
		clients: "Klienti",
		legalPerson: "Právnická osoba",
		naturalPerson: "Fyzická osoba",
		companyName: "Název firmy",
		vatRegNumber: "DIČ",
		exceed: "Překročení ceny",
		attachedFiles: "Sem přetáhněte soubory, které chcete uložit.",
		NameAlreadyExists: "Název již existuje",
		EntityNotFound: "Objekt se nenachází v databázi (pravděpodobně byl smazán)",
		DuplicateEntity: "Nelze vytvořit - daný objekt se v databázi již nachází",
		UsernameExists: "Uživatelské jméno obsazeno",
		InvalidOrMissingRole: "Chybějící role.",
		CannotCreateUser: "Nebylo možné vytvořit uživatele.",
		ConcurrencyFailure: "Objekt je právě upravován jiným uživatelem.",
		PasswordMismatch: "Hesla se neshodují.",
		InvalidToken: "Nevalidní token.",
		LoginAlreadyAssociated: "Zadaný uživatel je již přihlášen.",
		InvalidUserName: "Vaše uživatelské jméno není ve validním formátu.",
		InvalidEmail: "Váš e-mail není va validním formátu.",
		EmailExists: "Zadaný e-mail již existuje.",
		InvalidRoleName: "Chybný název role.",
		RoleNameExists: "Tato role již existuje.",
		PasswordAlreadySet: "Heslo bylo již zadáno.",
		UserLockoutNotEnabled: "Nelze zamknout uživatele.",
		UserAlreadyInRole: "Role je uživateli již přiřazena.",
		UserNotInRole: "Uživatel nemá zadanou roli.",
		PasswordTooShort: "Heslo je příliš krátké.",
		PasswordRequiresNonAlphanumeric: "Heslo nesmí obsahovat číselné znaky.",
		PasswordRequiresDigit: "Heslo vyžaduje alespoň jeden číselný znak.",
		PasswordRequiresLower: "Heslo vyžaduje alespoň jedno malé písmeno.",
		PasswordRequiresUpper: "Heslo vyžaduje alespoň jedno velké písmeno",
		Unauthorized: "Zadali jste chybné přihlašovací údaje.",
		order: "Pořadí",
		logout: "Odhlásit se",
		register: "Vytvořit účet",
		reset: "Zapomněli jste heslo?",
		successful: "Váš požadavek byl úspěšně zpracován",
		failed: "Váš požadavek nebylo možné zpracovat",
		close: "Zavřít",
		loading: "Načítám",
		oauth: "Přihlášení do systému",
	},
};

export default dictionary;
