import Cookies from 'universal-cookie';
import { ApiURL } from '../../../constants/api';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchSaga } from '../../../sagas/_sagas';
import { getType } from 'typesafe-actions';
import { lang, t } from 'translation/i18n';
import { notificationAction } from 'components/Toast/_actions';
import { oauthAdminAction, resetAdminAction } from './_actions';
import { push } from 'connected-react-router';
import { Routes } from 'constants/routes';
import { Status } from 'components/Toast/_types';
import { translationPath } from 'utils/getPath';
import { Method } from 'constants/enum';

function* tokenSaga(
	action: ReturnType<typeof oauthAdminAction.request>
): Generator {
	try {
		// @ts-ignore
		const { errorResponse, response, success } = yield call(
			fetchSaga,
			ApiURL.OAUTH_SUPER_ADMIN,
			"POST",
			{
				bodyJSON: action.payload,
			}
		);
		if (!!(!success || errorResponse)) {
			yield put(oauthAdminAction.failure(errorResponse));
			yield put(
				notificationAction({
					code: Status.ERROR,
					message: t(translationPath(lang.common.failed)),
				})
			);
			return;
		}

		if (success && response) {
			const cookies = new Cookies();
			cookies.set("oauth_token", response, { path: "/" });
			yield put(oauthAdminAction.success(response as string));
			yield put(push(Routes.SUPER_ADMINISTRATOR_PORTAL));
		}
	} catch (err) {
		yield put(oauthAdminAction.failure(err));
	}
}

function* resetSaga(
	action: ReturnType<typeof resetAdminAction.request>
): Generator {
	try {
		// @ts-ignore
		const { errorResponse, response, success } = yield call(
			fetchSaga,
			ApiURL.OAUTH_SUPER_ADMIN_RESET,
			Method.POST,
			{
				param: action.payload,
			}
		);
		if (!!(!success || errorResponse)) {
			yield put(resetAdminAction.failure(errorResponse));
			yield put(
				notificationAction({
					code: Status.ERROR,
					message: t(translationPath(lang.common.failed)),
				})
			);
			return;
		}

		if (success && response) {
			yield put(resetAdminAction.success(response as void));
		}
	} catch (err) {
		yield put(oauthAdminAction.failure(err));
	}
}

export function* watchAuthenticationAdmin() {
	yield takeLatest(getType(oauthAdminAction.request), tokenSaga);
}

export function* watchResetAdmin() {
	yield takeLatest(getType(resetAdminAction.request), resetSaga);
}
