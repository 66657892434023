import { Lang } from '../lang';

const dictionary: Lang = {
	distributors: {
		newDistributor: "Create distributor",
		editDistributor: "Edit distributor",
		newClient: "Create client",
		distributor: "distributor",
		editClient: "Edit client",
	},
	administrator: {
		editAdministrator: "Edit Administrator",
	},
	login: {
		subdistributor: "Subdistributor",
		administrator: "Administrator",
		titleDistributor: "Distributor system login",
		titleSubDistributor: "Subdistributor system login",
		titleAdministrator: "Administrator system login",
	},
	languages: {
		czech: "Czech",
		english: "English",
		german: "German",
	},
	header: {
		language: "Language",
		currency: "Currency",
	},
	table: {
		licenseRenewal: "License renewal (years)",
		halfYear: "Half a year",
		year: "Year",
		twoYears: "Two years",
		licenseRenewalHalfYear: "Half-year license renewal",
		licenseRenewalYear: "One year license renewal",
		licenseRenewalTwoYears: "Two year licence renewal",
		capacityIncrease: "Increase capacity (GB)",
		capacityIncrease5GB: "Increase capacity by 5 GB",
		capacityIncrease10GB: "Increase capacity by 10 GB",
		capacityIncrease100GB: "Increase capacity by 100 GB",
		Capacity5GB: "5",
		Capacity10GB: "10",
		Capacity100GB: "100",
		open: "View",
		licenseIssued: "Issued on",
		licenseIssuedBy: "Issued",
		licenseValidityEnd: "Valid until",
		clientLicenceNumber: "License Number",
		maxStorage: "Capacity (GB)",
		name: "Name",
		contact: "Contact",
		block: "Block",
		size: "size",
		extension: "extension",
		action: "Action",
		deleted: "Deleted",
		status: "Status",
		active: "Active",
		inactive: "Inactive",
		info: "Info",
		allowed: "Allowed",
		default: "Default",
	},
	input: {
		name: "Name",
		picture: "Picture",
		pictures: "Pictures",
		description: "Description",
		currency: "Currency",
		clientLicenceNumber: "Licence Number",
		url: "URL",
		forename: "Forename",
		surname: "Surname",
		countryCode: "Country code",
		email: "Email",
		phone: "Phone",
		company: "Company",
		crn: "ID",
		address: "Address",
		note: "note",
		username: "Username",
		password: "Password",
		currentPassword: "Old password",
		newPassword: "New password",
		validationPassword: "Password check",
		login: "Login",
		resetPass: "Reset password",
		changePass: "Change password",
		public: "Veřejné",
	},
	validation: {
		min: "The field must contain at least {{count}} characters.",
		max: "The field can contain a maximum of {{count}} characters.",
		required: "Required field.",
		email: "Enter a valid email",
		phone: "Enter a valid phone number",
		passwordMatch: "The passwords entered do not match",
	},
	button: {
		more: "More",
		load: "Load more",
		link: "link",
		loadPrice: "Load price",
		activate: "Activate",
		editItem: "Edit item",
		newItem: "New item",
		deleteItem: "Delete item",
		duplicateItem: "Duplicate item",
		saveItem: "Save item",
		save: "Save",
		resetPassword: "Reset password",
	},
	toast: {
		createDistributorSuccess:
			"Distributor has been successfully created. Login credentials have been automatically sent to his email.",
		createSubDistributorSuccess:
			"Subdistributor has been successfully created. Login credentials have been automatically sent to his email.",
		createClientSuccess:
			"The client was successfully created. Login credentials were automatically sent to his e-mail.",
	},
	status: {
		Unauthorized: "Incorrect password or username",
	},
	users: {
		title: "Users",
		newUser: "New user",
		editUser: "Edit user",
	},
	common: {
		settings: "Settings",
		admin: "SuperAdmin Environment",
		superAdmin: "Super Admin Environment",
		resetSuccessful: "Password has been successfully changed.",
		users: "Users",
		resetRequestPassword: "Password reset",
		resetRequestPasswordSubmit: "Reset",
		resetPassword: "New password",
		resetPasswordSubmit: "Reset",
		distributor: "Distributor",
		distributors: "Distributors",
		clients: "Clients",
		legalPerson: "Legal person",
		naturalPerson: "Natural person",
		companyName: "Company name",
		vatRegNumber: "VAT number",
		exceed: "Price overrun",
		attachedFiles: "Drag and drop the files you want to save here",
		NameAlreadyExists: "Name already exists",
		EntityNotFound:
			"The object is not in the database (it was probably deleted)",
		DuplicateEntity: "Cannot create - the object is already in the database",
		UsernameExists: "Username taken",
		InvalidOrMissingRole: "Missing role.",
		CannotCreateUser: "Could not create user.",
		ConcurrencyFailure: "The object is currently being edited by another user.",
		PasswordMismatch: "Passwords do not match.",
		InvalidToken: "Invalid token.",
		LoginAlreadyAssociated: "The specified user is already logged in.",
		InvalidUserName: "Your username is not in a valid format.",
		InvalidEmail: "Your email is not in a valid format.",
		EmailExists: "The specified email already exists.",
		InvalidRoleName: "Invalid role name.",
		RoleNameExists: "This role already exists.",
		PasswordAlreadySet: "Password has already been entered.",
		UserLockoutNotEnabled: "Unable to lock the user.",
		UserAlreadyInRole: "The role is already assigned to the user.",
		UserNotInRole: "The user does not have an assigned role.",
		PasswordTooShort: "Password is too short.",
		PasswordRequiresNonAlphanumeric:
			"Password must not contain numeric characters.",
		PasswordRequiresDigit: "Password requires at least one numeric character.",
		PasswordRequiresLower: "Password requires at least one lowercase letter.",
		PasswordRequiresUpper: "Password requires at least one uppercase letter",
		Unauthorized: "You have entered incorrect login credentials.",
		order: "Order",
		logout: "Log out",
		register: "Create account",
		reset: "Forgot your password?",
		successful: "Your request has been processed successfully",
		failed: "Your request could not be processed",
		close: "Close",
		loading: "Loading",
		oauth: "Login to the system",
	},
};

export default dictionary;
