import * as React from 'react';
import styled from 'styled-components';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

interface IOwnProps {
    checked: boolean;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string | JSX.Element;
    name: string;
}

export const CheckBox = (props: IOwnProps) => {
    const {checked, handleChange, label, name} = props;
    const [isChecked, setChecked] = React.useState(checked);

    return (
        <Content>
            <Label>
                <Input
                    defaultChecked={checked}
                    name={name}
                    onChange={(e) => {
                        setChecked(e.target.checked);
                        handleChange(e);
                    }}
                    type='checkbox'
                />
                <StyledBox checked={isChecked}>
                    {isChecked && <FontAwesomeIcon icon={faCheck as IconProp}/>}
                </StyledBox>
                {label}
            </Label>
        </Content>
    );
};

export const Content = styled.div`
  margin: 2px 0;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Input = styled.input<{ name: any }>`
  position: fixed;
  top: -50px;
  left: -50px;
`;

export const StyledBox = styled.div<{ checked: boolean }>`
  position: relative;

  width: 15px;
  height: 15px;

  margin-right: 2rem;

  background-color: ${(props) =>
    props.checked ? props.theme.colors.primary : 'transparent'};
  border: 1px solid
    ${(props) =>
    props.checked
        ? props.theme.colors.primary
        : props.theme.colors.forms.border};
  border-radius: 3px;

  transition: all 0.2s ease-out;

  &:hover {
    background-color: ${(props) =>
    props.checked ? props.theme.colors.primary : 'transparent'};
    border-color: ${(props) =>
    props.checked ? props.theme.colors.primary : props.theme.colors.primary};
  }

  svg {
    position: absolute;

    top: 1px;
    left: 1px;

    color: ${(props) => props.theme.colors.background.content};
    font-size: 11px;
  }
`;

export const StyledRadio = styled.div<{ checked: boolean }>`
  flex-shrink: 0;

  position: relative;

  width: 15px;
  height: 15px;

  margin-right: 2rem;

  background-color: transparent;
  border: 1px solid
    ${(props) =>
    props.checked
        ? props.theme.colors.primary
        : props.theme.colors.forms.border};
  border-radius: 7px;

  transition: all 0.2s ease-out;

  &:after {
    content: '';

    position: absolute;

    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;

    background-color: ${(props) =>
    props.checked ? props.theme.colors.primary : 'transparent'};
    border-radius: 99px;

    transition: all 0.2s ease-out;
  }
`;
