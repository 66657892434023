import EditClient from "components/EditClient";
import React from "react";
import {
  CreateClient,
  Client,
} from "../../../Admin/Clients/ClientsTable/_types";
import { Routes } from "constants/routes";

export interface StateProps {
  pending: boolean;
  routerState: any;
  client: Client;
}

export interface DispatchProps {
  clientsPostAction: (data: CreateClient) => void;
  clientsPutAction: (data: CreateClient) => void;
  clientGetByIdAction: (data: string) => void;
}

const Component: React.FC<StateProps & DispatchProps> = ({
  pending,
  client,
  clientsPostAction,
  clientsPutAction,
  clientGetByIdAction,
}) => {
  return (
    <EditClient
      pending={pending}
      client={client}
      clientsPostAction={clientsPostAction}
      clientsPutAction={clientsPutAction}
      clientGetByIdAction={clientGetByIdAction}
      successPathRedirection={Routes.ADMINISTRATOR_CLIENTS}
    />
  );
};

export default React.memo(Component);
