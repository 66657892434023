import * as React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { BaseInlineInput, BasicInlineInputProps } from './BaseInput';
import { getCountryCode } from 'utils/getCountryCodes';
interface BaseInlineInputProps extends BasicInlineInputProps {
	formik: any;
	countryCode?: string;
	phoneNumber?: string;
	disabled?: boolean;
}

export const PhoneNumber = (props: BaseInlineInputProps) => {
	const { formik, countryCode, phoneNumber, disabled } = props;

	const handleChange = (value: any, _actionMeta: any) => {
		formik.setFieldValue(countryCode, value.value);
	};
	const customStyles = {
		control: (base, state) => ({
			...base,
			border: "1px solid transparent",
			borderBottom: state.isFocused
				? "3px solid #17785e"
				: "3px solid transparent",
			borderRadius: "3px",
			boxShadow: props.disabled
				? "0 3px 15px -5px rgba(58, 77, 158, 0.5)"
				: "0 3px 15px -5px rgba(0, 0, 0, 0.15)",
			transition: "all 0.2s ease-out",
			"&:active": {
				boxShadow: "0 3px 15px -5px rgba(58, 77, 158, 0.5)",
			},
			"&:hover": {
				boxShadow: "0 3px 15px -5px rgba(58, 77, 158, 0.5)",
			},
			"&:focus": {
				boxShadow: "0 3px 15px -5px rgba(58, 77, 158, 0.5)",
			},
		}),
	};
	return (
		<GridRow>
			<SelectWrapper>
				<SelectInput
					className={"select"}
					isDisabled={disabled}
					isSearchable={true}
					styles={customStyles}
					onChange={handleChange}
					options={getCountryCode().map((value: any) => {
						return { value: value, label: value };
					})}
					value={
						formik.values
							? {
									value: formik.values[countryCode],
									label: formik.values[countryCode],
							  }
							: { value: "", label: "" }
					}
				/>
			</SelectWrapper>
			<SBaseInlineInput
				disabled={disabled}
				formik={formik}
				name={phoneNumber}
				type="text"
			/>
		</GridRow>
	);
};

const GridRow = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: flex-start;
	flex-wrap: wrap;
	color: ${(props) => props.theme.colors.contentText};
`;

const SelectWrapper = styled.div``;
const SBaseInlineInput = styled(BaseInlineInput)`
	max-width: 438px !important;
	min-width: 438px !important;
	input {
		width: 100px !important;
	}
`;

export const SelectInput = styled(Select)`
	width: 100%;

	border-radius: 5px;
	border-color: "#ffffff";
	> div {
		padding: 9.2px 4px;
		min-width: 160px;
		max-width: 160px;
	}
`;
