import { getType } from 'typesafe-actions';
import { UserStateType } from './_types';

import {
	userDeleteByIdAction,
	userGetByIdAction,
	userPostAction,
	userPutAction,
	UsersActionType,
	usersGetAction,
} from "./_actions";

const initialState: UserStateType = {
	pending: false,
	error: null,
	users: null,
	user: null,
	deleting: false,
	deletedId: null,
};

export const UserReducer = (
	state: UserStateType = initialState,
	action: UsersActionType
): UserStateType => {
	switch (action.type) {
		case getType(usersGetAction.request):
		case getType(userPostAction.request):
		case getType(userPutAction.request):
		case getType(userGetByIdAction.request):
			return {
				...state,
				pending: true,
			};
		case getType(userDeleteByIdAction.request):
			return {
				...state,
				deleting: true,
				deletedId: action.payload,
			};
		case getType(userPutAction.success):
			return {
				...state,
				pending: false,
			};
		case getType(usersGetAction.success):
		case getType(userPostAction.success):
			return {
				...state,
				users: action.payload,
				pending: false,
			};
		case getType(userGetByIdAction.success):
			return {
				...state,
				user: action.payload,
				pending: false,
			};
		case getType(userDeleteByIdAction.success):
			return {
				...state,
				deleting: false,
			};
		case getType(usersGetAction.failure):
		case getType(userPostAction.failure):
		case getType(userPutAction.failure):
		case getType(userGetByIdAction.failure):
		case getType(userDeleteByIdAction.failure):
			return {
				...state,
				error: action.payload.ErrorMessage,
				pending: false,
				deleting: false,
			};
		default:
			return state;
	}
};
