import React, {FC} from "react";
import styled from "styled-components";
import logo from "./img/logo.svg";

export const Footer: FC = () => {
    return (
        <FooterWrapper>
            <PoweredBy>
                <Powered>POWERED BY</Powered>
                <a target="_blank" href="https://optimify.cz" rel="noopener noreferrer">
                    <Logo src={logo}/>
                </a>
            </PoweredBy>
        </FooterWrapper>
    );
};

const FooterWrapper = styled.div`
  height: 70px;
  position: absolute;
  left:0;
  right: 0;
  bottom: 30px;
  
  z-index: -99999;
`;

const PoweredBy = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Powered = styled.span`
  padding-bottom: 0.75em;
  
  color: #ccc;

  font-size: 0.74em;
  
  letter-spacing: 6px;
`;

const Logo = styled.img`
  height: 28px;

  fill: red;
`;
