import { ActionType, createAsyncAction } from 'typesafe-actions';
import { Error } from 'sagas/_sagas';
import { User } from './_types';

export const usersGetAction = createAsyncAction(
	"USERS_GET_REQUEST",
	"USERS_GET_SUCCESS",
	"USERS_GET_FAILURE"
)<void, User[], Error>();

export const userPostAction = createAsyncAction(
	"USERS_POST_REQUEST",
	"USERS_POST_SUCCESS",
	"USERS_POST_FAILURE"
)<User, User[], Error>();

export const userPutAction = createAsyncAction(
	"USER_PUT_REQUEST",
	"USER_PUT_SUCCESS",
	"USER_PUT_FAILURE"
)<User, void, Error>();

export const userGetByIdAction = createAsyncAction(
	"USER_GET_BY_ID_REQUEST",
	"USER_GET_BY_ID_SUCCESS",
	"USER_GET_BY_ID_FAILURE"
)<string, User, Error>();

export const userDeleteByIdAction = createAsyncAction(
	"USER_DELETE_REQUEST",
	"USER_DELETE_SUCCESS",
	"USER_DELETE_FAILURE"
)<string, void, Error>();

export type UsersActionType = ActionType<
	| typeof usersGetAction
	| typeof userPostAction
	| typeof userPutAction
	| typeof userGetByIdAction
	| typeof userDeleteByIdAction
>;
