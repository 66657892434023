import * as React from "react";
import Loader from "../Loader/Loader";
import Modal from "../Modal";
import styled from "styled-components";

interface OwnProps {
  closeModal?: boolean;
  button?: string;
  level?: ButtonLevel;
  children: React.ReactNode;
  loading?: boolean;
  modal?: boolean;
  modalChildren?: any;
  modalButton?: string;
  modalTitle?: string;
  modalDescription?: string;
  onClick?: (_event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  fullWidth?: boolean;
  iconOnly?: boolean;
  noMargin?: boolean;
  noLeftMargin?: boolean;
  type?: string;
  update?: boolean;
  handleSync?: (_event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  disabled?: boolean;
}

type ButtonLevel = 1 | 2 | 3;

export const ButtonContent = (props: OwnProps) => {
  const { children, loading } = props;

  return (
    <>
      <ChildrenWrapper>{children}</ChildrenWrapper>
      {loading && (
        <LoaderWrapper>
          <Loader size={24} />
        </LoaderWrapper>
      )}
    </>
  );
};

export const Button = (props: OwnProps) => {
  const { children, type, loading, ...elementProps } = props;

  return (
    <ButtonElement {...elementProps} type={!type ? "submit" : type}>
      {ButtonContent(props)}
    </ButtonElement>
  );
};

export const PlainButton = (props: OwnProps) => {
  const { children, type, ...elementProps } = props;
  return (
    <PlainButtonElement {...elementProps} type={type ? type : "submit"}>
      {ButtonContent(props)}
    </PlainButtonElement>
  );
};

export const OutlinedButton = (props: OwnProps) => {
  const {
    children,
    type,
    modalTitle,
    modalDescription,
    modal,
    ...elementProps
  } = props;

  if (modal) {
    return <ModalButton {...props} />;
  }

  return (
    <OutlinedButtonElement {...elementProps} type={type}>
      {ButtonContent(props)}
    </OutlinedButtonElement>
  );
};

export const ModalButton = (props: OwnProps) => {
  const [open, setOpen] = React.useState(false);

  const {
    update,
    button,
    children,
    type,
    closeModal,
    onClick,
    modalChildren,
    modalTitle,
    modalDescription,
    modal,
    handleSync,
    ...elementProps
  } = props;

  const handleClick = (_event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setOpen(true);
  };

  const confirm = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (closeModal) close();
    onClick(event);
  };

  const close = () => {
    setOpen(false);
  };

  const sync = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    handleSync(event);
    close();
  };

  return (
    <>
      <OutlinedButton
        onClick={handleClick}
        {...elementProps}
        type={type ? type : "submit"}
      >
        {ButtonContent(props)}
      </OutlinedButton>
      <Modal
        confirm={confirm}
        open={open}
        title={modalTitle}
        description={modalDescription}
        closeModal={close}
        button={button}
        modalChildren={modalChildren}
        update={update}
        sync={sync}
      />
    </>
  );
};

export const ModalTextButton = (props: OwnProps) => {
  const [open, setOpen] = React.useState(false);

  const {
    button,
    children,
    type,
    onClick,
    modalChildren,
    modalTitle,
    modalDescription,
    modal,
    handleSync,
    update,
    ...elementProps
  } = props;

  const handleClick = (_event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setOpen(true);
  };

  const confirm = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onClick(event);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const sync = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    handleSync(event);
    closeModal();
  };

  return (
    <>
      <TrussOutlinedButtonElement onClick={handleClick} {...elementProps}>
        {ButtonContent(props)}
      </TrussOutlinedButtonElement>
      <Modal
        confirm={confirm}
        open={open}
        title={modalTitle}
        description={modalDescription}
        closeModal={closeModal}
        button={button}
        modalChildren={modalChildren}
        update={update}
        sync={sync}
      />
    </>
  );
};

const ChildrenWrapper = styled.div`
  display: inline-block;
`;

const LoaderWrapper = styled.div`
  display: inline-block;
  position: relative;
  top: -5px;
  width: 24px;
  height: 24px;
  margin: -8px 4px;
`;

export const ButtonElement = styled.button<{ type?: any }>`
  position: relative;
  z-index: 1;

  padding: 0.4em 0.7rem;
  margin: 0em 0.3em;
  box-shadow: ${(props) => props.theme.boxShadowSharp};
  border: 0;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.background.button.red};
  color: ${(props) => props.theme.colors.text.white};
  font-size: 1.2rem;
  font-weight: 500;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background: #232f3e;
  }

  transition: all 0.2s ease-in-out;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;

    top: -1px;
    right: 50%;
    bottom: -1px;
    left: 50%;

    background-color: rgba(0, 0, 0, 0.1);

    transition: all 0.1s ease-out;
  }

  &:hover {
    cursor: pointer;
    box-shadow: ${(props) => props.theme.boxShadowSharpHover};

    &:before {
      left: -2px;
      right: -2px;
    }
  }

  &:active {
    background-color: ${(props) => props.theme.colors.primary};
    box-shadow: ${(props) => props.theme.boxShadowSharpActive};
  }
`;

const PlainButtonElement = styled(ButtonElement)`
  background-color: transparent;
  box-shadow: none;
  color: ${(props) => props.theme.colors.primary};

  &:before {
    background-color: transparent;
  }

  &:after {
    content: "";
    position: absolute;

    right: 50%;
    bottom: 0;
    left: 50%;

    height: 1px;

    background-color: ${(props) => props.theme.colors.primary};
    opacity: 0.15;

    transition: all 0.1s ease-out;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: none;

    &:after {
      left: 0;
      right: 0;
    }
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: none;
  }

  &:disabled {
    color: ${(props) => props.theme.colors.secondaryText.default};

    &:hover {
      background-color: transparent;

      &:after {
        right: 50%;
        left: 50%;
      }

      cursor: default;
    }
  }
`;

const OutlinedButtonElement = styled(ButtonElement)`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.primary};
  box-shadow: none;
  color: ${(props) => props.theme.colors.primary};

  .loaderWrapper > div:after {
    background: ${(props) => props.theme.colors.primary};
  }

  &:before {
    background-color: transparent;
  }

  &:not({(props) = > props.disabled}):hover {
    box-shadow: none;
    color: white;

    &:before {
      background-color: ${(props) => props.theme.colors.button};
    }

    .loaderWrapper > div:after {
      background: #fff;
    }
  }

  &:active {
    box-shadow: ${(props) => props.theme.boxShadowSharp};

    &:before {
      background-color: ${(props) => props.theme.colors.primaryText.default};
    }
  }
`;

const TrussOutlinedButtonElement = styled(OutlinedButtonElement)`
  padding: 5px 11px 6px;
`;
