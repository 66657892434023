import { Auth } from './_types';
import { Error } from '../../../sagas/_sagas';
import {
	ActionType,
	createAsyncAction,
	createStandardAction,
} from "typesafe-actions";

export const authSubDistributorAction = createAsyncAction(
	"AUTH_SUB_DISTRIBUTOR_REQUEST",
	"AUTH_SUB_DISTRIBUTOR_SUCCESS",
	"AUTH_SUB_DISTRIBUTOR_FAILURE"
)<Auth, string, Error>();

export const authAdministratorAction = createAsyncAction(
	"AUTH_ADMINISTRATOR_REQUEST",
	"AUTH_ADMINISTRATOR_SUCCESS",
	"AUTH_ADMINISTRATOR_FAILURE"
)<Auth, string, Error>();

export const oauthSubDistributorogoutAction = createStandardAction(
	"OAUTH_SUB_DISTRIBUTOR_ADMIN_LOGOUT"
)();

export type AuthenticationActionType = ActionType<
	| typeof authAdministratorAction
	| typeof authSubDistributorAction
	| typeof oauthSubDistributorogoutAction
>;
