import * as Yup from 'yup';
import React from 'react';
import { Auth } from 'types/_types';
import { GlobalNotification } from 'components/Toast/_types';
import { GridItem, GridRow, PageWrapper } from 'constants/globalStyles';
import { Header } from '../SubDistributor/components/Header';
import { lang } from 'translation/i18n';
import { OAuth } from 'components/OAuth';
import { translationPath } from 'utils/getPath';
import { useFormik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

export interface StateProps {
	pending: boolean;
	toast: GlobalNotification;
}

export interface DispatchProps {
	login: (data: Auth) => void;
	reset: (data: string) => void;
	clearToast: () => void;
}

const Component: React.FC<StateProps & DispatchProps> = ({
	pending,
	login,
	reset,
	toast,
	clearToast,
}) => {
	const { t } = useTranslation();

	const { addToast } = useToasts();

	React.useEffect(() => {
		if (toast) {
			addToast(toast.message, {
				appearance: toast.code,
				autoDismiss: true,
			});
			clearToast();
		}
	}, [toast, addToast, clearToast]);

	const initialValues: Auth = {
		username: "",
		password: "",
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: Yup.object({
			username: Yup.string()
				.min(1, t(translationPath(lang.validation.min).path, { count: 1 }))
				.max(200, t(translationPath(lang.validation.max).path, { count: 200 }))
				.required(t(translationPath(lang.validation.required).path)),
			password: Yup.string()
				.min(1, t(translationPath(lang.validation.min).path, { count: 1 }))
				.max(200, t(translationPath(lang.validation.max).path, { count: 200 }))
				.required(t(translationPath(lang.validation.required).path)),
		}),
		onSubmit: (values: Auth) => {
			login(values);
		},
	});
	return (
		<form onSubmit={formik.handleSubmit}>
			<Header />
			<PageWrapper>
				<GridRow>
					<GridItem>
						<OAuth
							formik={formik}
							title={t(translationPath(lang.common.oauth).path)}
							pending={pending}
							selection={false}
						/>
					</GridItem>
				</GridRow>
			</PageWrapper>
		</form>
	);
};

export default React.memo(Component);
