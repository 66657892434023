import { Client } from "../../Clients/ClientsTable/_types";
import { createProxy } from "../../../../../utils/getPath";
import { FetchStateType } from "../../../../_types";
import { Routes } from "../../../../../constants/routes";
import { User } from "containers/Portal/SuperAdmin/Users/UsersTable/_types";

export type Distributor = {
  Id?: string;
  Name: string;
  AdminEmail: string;
  AdminCountryCode: string;
  AdminPhoneNumber: string;
  AdminName: string;
  AdminSurname: string;
  LicenseValidityEnd: string;
  MaxStorage: number;
  IsDeleted: boolean;
  IsActive?: boolean;
  SubDistributors?: Distributor[];
  Clients?: Client[];
};

export type DistributorRequest = {
  data: Distributor;
  path: Routes;
};

export type NewDistributor = {
  Id: string;
  Name: string;
  SubDistributors: Distributor[];
  ParentOrganization: string;
  ParentOrganizationId: string;
  Type: string;
  LicenseIssued: string;
  LicenseValidityEnd: string;
  LastLicenseEdit: string;
  LastLicenseEditedBy: string;
  LicenseIssuedBy: string;
  MaxStorage: number;
  LicenseValid: boolean;
  AdminUsername: string;
  AdminPassword: string;
};

export type DistributorStateType = FetchStateType & {
  distributors: Distributor[];
  distributor: Distributor;
  newDistributor: boolean;
  deleting: boolean;
  deletedId: string;
  users: User[];
  user: User;
};

export const DistributorProxy = createProxy<Distributor>();
