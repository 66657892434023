import { ApiURL } from '../../../constants/api';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchSaga } from 'sagas/_sagas';
import { getType } from 'typesafe-actions';
import { lang, t } from 'translation/i18n';
import { Method } from 'constants/enum';
import { notificationAction } from 'components/Toast/_actions';
import { resetPasswordAction } from './_actions';
import { Status } from 'components/Toast/_types';
import { translationPath } from 'utils/getPath';

function* resetPasswordActionSaga(
	action: ReturnType<typeof resetPasswordAction.request>
): Generator {
	try {
		console.log(action.payload.secretCode);
		// @ts-ignore
		const { errorResponse, success, statusText } = yield call(
			fetchSaga,
			ApiURL.RESET,
			Method.POST,
			{
				bodyJSON: action.payload,
			}
		);
		if (!success || errorResponse) {
			yield put(resetPasswordAction.failure(errorResponse));
			yield put(
				notificationAction({
					code: Status.ERROR,
					message: t(translationPath(lang.common[statusText])),
				})
			);
			return;
		}

		if (success) {
			yield put(
				notificationAction({
					code: Status.SUCCESS,
					message: t(translationPath(lang.common.resetSuccessful)),
				})
			);
			yield put(resetPasswordAction.success());
		}
	} catch (err) {
		yield put(resetPasswordAction.failure(err));
	}
}

export function* watchResetPassword() {
	yield takeLatest(
		getType(resetPasswordAction.request),
		resetPasswordActionSaga
	);
}
