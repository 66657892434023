import { ActionType, createAsyncAction } from 'typesafe-actions';
import { Error } from 'sagas/_sagas';
import { User } from 'containers/Portal/SuperAdmin/Users/UsersTable/_types';

export const administratorResetPasswordAction = createAsyncAction(
	"ADMINISTRATOR_RESET_REQUEST",
	"ADMINISTRATOR_RESET_SUCCESS",
	"ADMINISTRATOR_RESET_FAILURE"
)<string, void, Error>();

export const administratorPutAction = createAsyncAction(
	"ADMINISTRATOR_PUT_REQUEST",
	"ADMINISTRATOR_PUT_SUCCESS",
	"ADMINISTRATOR_PUT_FAILURE"
)<User, void, Error>();

export const administratorGetByTokenAction = createAsyncAction(
	"ADMINISTRATOR_GET_BY_TOKEN_REQUEST",
	"ADMINISTRATOR_GET_BY_TOKEN_SUCCESS",
	"ADMINISTRATOR_GET_BY_TOKEN_FAILURE"
)<void, User, Error>();

export type AdministratorSettingsActionType = ActionType<
	| typeof administratorResetPasswordAction
	| typeof administratorPutAction
	| typeof administratorGetByTokenAction
>;
