import * as React from 'react';
import Select, { OptionTypeBase } from 'react-select';
import styled from 'styled-components';
import { FC } from 'react';

interface SelectProps {
	formik: any;
	name?: string;
	onChange?: (value: any, actionMeta: any) => void;
	options?: any;
	placeholder?: string;
	value?: any;
}

export interface OptionType extends OptionTypeBase {
	label: string | number;
	value: string | number;
}

export const ReactSelect: FC<SelectProps> = ({
	options,
	placeholder,
	name,
	formik,
	value,
}) => {
	const handleChange = (value: any, _actionMeta: any) => {
		value
			? formik.setFieldValue(name, (value as OptionType).value, false)
			: formik.setFieldValue(name, null, false);
	};

	return (
		<SelectInput
			className={"basic-single"}
			classNamePrefix="select"
			isDisabled={false}
			isLoading={false}
			isClearable={true}
			isRtl={false}
			isSearchable={true}
			placeholder={placeholder}
			onChange={handleChange}
			name="color"
			options={options}
			value={value}
		/>
	);
};

export const SelectInput = styled(Select)`
	width: 100%;

	> div {
		padding: 9.2px 4px;
	}
`;
