import React from "react";
import styled from "styled-components";

interface CapacityProps {
    maxCapacity: number;
    currentCapacity: number;
}

export const Capacity: React.FC<CapacityProps> = ({
                                                      maxCapacity,
                                                      currentCapacity,
                                                  }) => {
    return (
        <Container>
            <Filter
                currentCapacity={currentCapacity}
                capacity={currentCapacity / (maxCapacity / 100)}
            >
                <Label>{`${Math.round(currentCapacity)}/${Math.round(
                    maxCapacity
                )} GB`}</Label>
            </Filter>
        </Container>
    );
};

export const Container = styled.div`
  min-width: 400px;
  height: 20px;
  width: 100%;
  background-color: #e0e0de;
  border-radius: 8px;
  margin: 10px;
`;

export const Filter = styled.div<{ currentCapacity: number; capacity: number }>`
  height: 100%;
  width: ${(props) =>
    props.capacity < 10
        ? props.capacity + 20
        : props.capacity < 20
        ? props.capacity + 10
        : props.capacity > 100
            ? 100
            : props.capacity}%;
  background-color: ${(props) =>
    props.capacity < 40
        ? "#125e4a"
        : props.capacity < 50
        ? "#DCBF07"
        : props.capacity < 80
            ? "#DC600A"
            : "rgb(220,0,78)"};
  border-radius: inherit;
  text-align: right;
`;

export const Label = styled.div`
  padding: 5px;
  color: white;
  font-weight: bold;
`;
