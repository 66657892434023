import { Routes } from 'constants/routes';

import { createProxy } from '../../../../../utils/getPath';
import { FetchStateType } from '../../../../_types';

export type Client = {
  Id?: string;
  Name: string;
  LicenseValidityEnd: string;
  ClientLicenceNumber: string;
  MaxStorage: number;
  CurrentStorage?: number;
  DistributorOrganization?: string;
  DistributorOrganizationId?: string;
  IsActive?: boolean;
  Type?: string;
  LicenseIssued?: string;
  LastLicenseEdit?: string;
  LastLicenseEditedBy?: string;
  LicenseIssuedBy?: string;
  LicenseValid?: boolean;
  AdminEmail?: string;
  AdminCountryCode?: string;
  AdminPhoneNumber?: string;
  AdminUsername?: string;
  AdminPassword?: string;
  IsDeleted?: boolean;
  ClientCurrency: string;
};

export type CreateClient = {
  client: Client;
  successPathRedirection: Routes;
};

export type Validity = {
  Validity: string;
  Id: string;
};

export type Storage = {
  Storage: number;
  Id: string;
};

export type ClientsStateType = FetchStateType & {
  clients: Client[];
  client: Client;
  deleting: boolean;
  deletedId: string;
  activating: boolean;
  activatedId: string;
  newClient: boolean;
};

export const ClientProxy = createProxy<Client>();
