import React, { useEffect } from 'react';
import { Box } from 'components/Box';
import { ButtonType, MaterialButton } from 'components/Button/MaterialButton';
import { Content } from 'components/Content';
import { ContentRow, PaddedText } from 'constants/globalStyles';
import { lang } from 'translation/i18n';
import { lastPathMember, translationPath } from 'utils/getPath';
import { Routes } from 'constants/routes';
import { ScrollTable } from 'components/Table';
import { User, UserProxy } from './_types';
import { useTranslation } from 'react-i18next';

export interface WebCategories {
	users: User[];
	pending: boolean;
	usersGetAction: (data: void) => void;
	userDeleteByIdAction: (data: string) => void;
	userPutAction: (data: User) => void;
	deleting: boolean;
	deletedId: string;
}

const Component: React.FC<WebCategories> = ({
	deletedId,
	deleting,
	pending,
	userDeleteByIdAction,
	usersGetAction,
	users,
}) => {
	const { t } = useTranslation();

	useEffect(() => {
		usersGetAction();
	}, [usersGetAction]);

	const deleteItem = (id: string) => () => {
		userDeleteByIdAction(id);
	};

	return (
		<Box
			title={t(translationPath(lang.users.title).path)}
			action={[
				<MaterialButton
					tooltip={t(translationPath(lang.button.newItem).path)}
					to={Routes.SUB_DISTRIBUTORS_USERS_CREATE}
					type={ButtonType.NEW}
				/>,
			]}
		>
			<Content pending={pending} withoutMargin>
				<ScrollTable
					columns={React.useMemo<any[]>(
						() => [
							{
								Header: t(translationPath(lang.input.forename).path),
								accessor: lastPathMember(UserProxy.Name).path,
							},
							{
								Header: t(translationPath(lang.input.surname).path),
								accessor: lastPathMember(UserProxy.Surname).path,
							},
							{
								Header: t(translationPath(lang.input.phone).path),
								accessor: lastPathMember(UserProxy.PhoneNumber).path,
								Cell: ({ row }) => {
									return (
										<ContentRow>
											<PaddedText>
												{!!!row.original.CountryCode
													? ""
													: row.original.CountryCode}
											</PaddedText>
											<PaddedText>{row.original.PhoneNumber}</PaddedText>
										</ContentRow>
									);
								},
							},
							{
								Header: t(translationPath(lang.input.email).path),
								accessor: lastPathMember(UserProxy.Email).path,
							},
							{
								Header: t(translationPath(lang.table.action).path),
								Cell: ({ row }) => {
									return (
										<ContentRow>
											<MaterialButton
												tooltip={t(translationPath(lang.button.editItem).path)}
												to={{
													pathname:
														Routes.SUB_DISTRIBUTORS_USERS_EDIT +
														"/" +
														row.original.UserName,
													state: row.original,
												}}
												type={ButtonType.EDIT}
											/>
											<MaterialButton
												tooltip={t(
													translationPath(lang.button.deleteItem).path
												)}
												onClick={deleteItem(row.original.UserName)}
												type={ButtonType.DELETE}
												loading={deleting && deletedId === row.origin.Id}
											/>
										</ContentRow>
									);
								},
							},
						],
						[users]
					)}
					data={React.useMemo<User[]>(() => users, [users])}
					pagination={false}
				/>
			</Content>
		</Box>
	);
};

export default React.memo(Component);
