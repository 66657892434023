import { getType } from "typesafe-actions";
import { SubDistributorsStateType } from "./_types";
import {
  subDistributorsGetAction,
  subDistributorsRegisterPostAction,
  subDistributorsPutAction,
  subDistributorsGetByIdAction,
  subDistributorsDeleteByIdAction,
  SubDistributorsActionType,
  subDistributorsGetUsersAction,
  subDistributorsGetUserByIdAction,
  subDistributorsDeleteUserByIdAction,
  subDistributorsPutUserByIdAction,
  subDistributorsReactivePostAction,
} from "./_actions";

const initialState: SubDistributorsStateType = {
  pending: false,
  error: null,
  subDistributors: null,
  subDistributor: null,
  deleting: false,
  deletedId: null,
  newSubDistributor: null,
  users: null,
  user: null,
};

export const SubDistributorsReducer = (
  state: SubDistributorsStateType = initialState,
  action: SubDistributorsActionType
): SubDistributorsStateType => {
  switch (action.type) {
    case getType(subDistributorsGetAction.request):
      return {
        ...initialState,
        pending: true,
      };
    case getType(subDistributorsRegisterPostAction.request):
    case getType(subDistributorsPutAction.request):
    case getType(subDistributorsGetByIdAction.request):
    case getType(subDistributorsGetUsersAction.request):
    case getType(subDistributorsGetUserByIdAction.request):
    case getType(subDistributorsPutUserByIdAction.request):
    case getType(subDistributorsReactivePostAction.request):
      return {
        ...state,
        pending: true,
      };
    case getType(subDistributorsDeleteUserByIdAction.request):
    case getType(subDistributorsDeleteByIdAction.request):
      return {
        ...state,
        deleting: true,
        deletedId: action.payload,
      };
    case getType(subDistributorsGetByIdAction.success):
      return {
        ...state,
        subDistributor: action.payload,
        pending: false,
      };
    case getType(subDistributorsGetUsersAction.success):
      return {
        ...state,
        pending: false,
        users: action.payload,
      };
    case getType(subDistributorsGetUserByIdAction.success):
      return {
        ...state,
        pending: false,
        user: action.payload,
      };
    case getType(subDistributorsPutUserByIdAction.success):
      return {
        ...state,
        pending: false,
        users: action.payload,
      };
    case getType(subDistributorsGetAction.success):
    case getType(subDistributorsReactivePostAction.success):
      return {
        ...state,
        subDistributors: action.payload,
        pending: false,
      };
    case getType(subDistributorsRegisterPostAction.success):
      return {
        ...state,
        newSubDistributor: true,
        pending: false,
      };
    case getType(subDistributorsPutAction.success):
      return {
        ...state,
        pending: false,
      };
    case getType(subDistributorsDeleteUserByIdAction.success):
      return {
        ...state,
        deleting: false,
        deletedId: null,
      };
    case getType(subDistributorsDeleteByIdAction.success):
      return {
        ...state,
        deleting: false,
        deletedId: null,
        subDistributors: action.payload,
      };
    case getType(subDistributorsGetAction.failure):
    case getType(subDistributorsPutAction.failure):
    case getType(subDistributorsRegisterPostAction.failure):
    case getType(subDistributorsGetByIdAction.failure):
    case getType(subDistributorsDeleteByIdAction.failure):
    case getType(subDistributorsGetUsersAction.failure):
    case getType(subDistributorsGetUserByIdAction.failure):
    case getType(subDistributorsPutUserByIdAction.failure):
    case getType(subDistributorsDeleteUserByIdAction.failure):
    case getType(subDistributorsReactivePostAction.failure):
      return {
        ...state,
        error: action.payload.ErrorMessage,
        pending: false,
        deleting: false,
      };
    default:
      return state;
  }
};
