import Cookies from "universal-cookie";
import { Authentication } from "types/_types";
import { getType } from "typesafe-actions";
import {
  AuthenticationAdminActionType,
  oauthAdminAction,
  oauthAdminLogoutAction,
  resetAdminAction,
} from "./_actions";

const initialState: Authentication = {
  access_token: null,
  expires_in: null,
  token_type: null,
  pending: false,
  error: null,
};

export const AuthenticationAdminReducer = (
  state: Authentication = initialState,
  action: AuthenticationAdminActionType
): Authentication => {
  switch (action.type) {
    case getType(resetAdminAction.request):
    case getType(oauthAdminAction.request):
      return {
        ...initialState,
        pending: true,
      };
    case getType(resetAdminAction.success):
      return {
        ...initialState,
        pending: false,
      };
    case getType(oauthAdminAction.success):
      return {
        ...initialState,
        access_token: action.payload,
        pending: false,
      };
    case getType(resetAdminAction.failure):
    case getType(oauthAdminAction.failure):
      return {
        ...state,
        pending: false,
      };
    case getType(oauthAdminLogoutAction):
      const cookies = new Cookies();
      cookies.remove("oauth_token");
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
