import * as Yup from 'yup';
import React from 'react';
import { Auth } from './_types';
import { GlobalNotification } from 'components/Toast/_types';
import { GridItem, GridRow, PageWrapper } from 'constants/globalStyles';
import { Header } from './components/Header';
import { lang } from 'translation/i18n';
import { OAuth } from '../../../components/OAuth';
import { Routes } from 'constants/routes';
import { translationPath } from 'utils/getPath';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

export interface StateProps {
	pending: boolean;
	toast: GlobalNotification;
}

export interface DispatchProps {
	oauthDistributorAction: (data: Auth) => void;
	oauthAdministratorAction: (data: Auth) => void;
	oauthSubDistributorAction: (data: Auth) => void;
	clearToast: () => void;
}

const Component: React.FC<StateProps & DispatchProps> = ({
	pending,
	oauthDistributorAction,
	oauthAdministratorAction,
	oauthSubDistributorAction,
	clearToast,
	toast,
}) => {
	const { addToast } = useToasts();
	const { t } = useTranslation();
	const location = useLocation();

	React.useEffect(() => {
		if (toast) {
			addToast(toast.message, {
				appearance: toast.code,
				autoDismiss: true,
			});
			clearToast();
		}
	}, [toast, addToast, clearToast]);

	const initialValues: Auth = {
		username: "",
		password: "",
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: Yup.object({
			username: Yup.string()
				.min(1, t(translationPath(lang.validation.min).path, { count: 1 }))
				.max(200, t(translationPath(lang.validation.max).path, { count: 200 }))
				.required(t(translationPath(lang.validation.required).path)),
			password: Yup.string()
				.min(1, t(translationPath(lang.validation.min).path, { count: 1 }))
				.max(200, t(translationPath(lang.validation.max).path, { count: 200 }))
				.required(t(translationPath(lang.validation.required).path)),
		}),
		onSubmit: (values: Auth) => {
			if (location.pathname === Routes.HOME) {
				oauthDistributorAction(values);
			} else if (location.pathname === Routes.SUBDISTRIBUTOR) {
				oauthSubDistributorAction(values);
			} else {
				oauthAdministratorAction(values);
			}
		},
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<Header />
			<PageWrapper>
				<GridRow>
					<GridItem>
						<OAuth
							formik={formik}
							title={
								location.pathname === Routes.HOME
									? t(translationPath(lang.login.titleDistributor).path)
									: location.pathname === Routes.SUBDISTRIBUTOR
									? t(translationPath(lang.login.titleSubDistributor).path)
									: t(translationPath(lang.login.titleAdministrator).path)
							}
							pending={pending}
							selection
						/>
					</GridItem>
				</GridRow>
			</PageWrapper>
		</form>
	);
};

export default React.memo(Component);
