import Component, { StateProps } from './Component';
import { clearNotificationAction } from 'components/Toast/_actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { oauthSuperAdminLogoutAction } from 'containers/Authentication/SuperAdmin/_actions';

export default connect(
	(state: any): StateProps => ({
		toast: state.ToastReducer.notification,
	}),
	(dispatch: Dispatch<any>): any => ({
		clearToast: () => dispatch(clearNotificationAction()),
		oauthSuperAdminLogoutAction: () => dispatch(oauthSuperAdminLogoutAction()),
	})
)(Component);
