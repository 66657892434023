import { getType } from 'typesafe-actions';
import { ResetPasswordType } from './_types';
import {
	requestResetPasswordAction,
	RequestResetPasswordActionType,
} from "./_actions";

const initialState: ResetPasswordType = {
	pending: false,
	error: null,
};

export const ReuestResetPasswordReducer = (
	state: ResetPasswordType = initialState,
	action: RequestResetPasswordActionType
): ResetPasswordType => {
	switch (action.type) {
		case getType(requestResetPasswordAction.request):
			return {
				...initialState,
				pending: true,
			};
		case getType(requestResetPasswordAction.success):
			return {
				...initialState,
				pending: false,
			};
		case getType(requestResetPasswordAction.failure):
			return {
				...state,
				pending: false,
			};
		default:
			return state;
	}
};
