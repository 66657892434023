import Component, { DispatchProps, StateProps } from './Component';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootStateType } from 'reducers';
import { Storage, Validity } from '../../Admin/Clients/ClientsTable/_types';
import { withRouter } from 'react-router';
import {
	clientsDeleteByIdAction,
	clientsGetAction,
	clientsPostStorageAction,
	clientsPostValidityAction,
	clientsReactivate,
} from "containers/Portal/Admin/Clients/ClientsTable/_actions";

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	clientsGetAction: (data: void) => dispatch(clientsGetAction.request(data)),
	clientsPostStorageAction: (data: Storage) =>
		dispatch(clientsPostStorageAction.request(data)),
	clientsPostValidityAction: (data: Validity) =>
		dispatch(clientsPostValidityAction.request(data)),
	clientsDeleteByIdAction: (data: string) =>
		dispatch(clientsDeleteByIdAction.request(data)),
	clientsReactivate: (data: string) =>
		dispatch(clientsReactivate.request(data)),
});

const mapStateToProps = (state: RootStateType): StateProps => ({
	clients: state.ClientsReducer.clients,
	newClient: state.ClientsReducer.newClient,
	pending: state.ClientsReducer.pending,
	deleting: state.ClientsReducer.deleting,
	deletedId: state.ClientsReducer.deletedId,
});

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(Component);
