import * as React from "react";
import Add from "@material-ui/icons/Add";
import AddCircle from "@material-ui/icons/AddCircle";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Button from "@material-ui/core/Button/Button";
import ChevronRight from "@material-ui/icons/ChevronRight";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import FilterNone from "@material-ui/icons/FilterNone";
import Info from "@material-ui/icons/Info";
import Link from "@material-ui/icons/Link";
import Loader from "components/Loader/Loader";
import Refresh from "@material-ui/icons/Refresh";
import SaveIcon from "@material-ui/icons/Save";
import styled from "styled-components";
import Tooltip from "../Tooltip";
import { ContentRow } from "../../constants/globalStyles";
import { FC } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { withStyles } from "@material-ui/styles";

export enum ButtonType {
  RENEWAL,
  DELETE,
  EDIT,
  NEW,
  SAVE,
  UPLOAD,
  UP,
  DOWN,
  LOAD,
  DUPLICATE,
  LINK,
  INFO,
  RIGHT,
}

interface OutlinedButton {
  disabled?: boolean;
  to?: any;
  loading?: boolean;
  text?: string;
  type?: ButtonType;
  tooltip: string;
  onClick?: () => void;
  target?: string;
  small?: boolean;
}

export const MaterialButton: FC<OutlinedButton> = ({
  children,
  loading,
  onClick,
  text,
  to,
  tooltip,
  type,
  target,
}) => {
  const getIcon = () => {
    switch (type) {
      case ButtonType.EDIT:
        return <Edit />;
      case ButtonType.SAVE:
        return <SaveIcon />;
      case ButtonType.NEW:
        return <Add />;
      case ButtonType.UPLOAD:
        return <CloudUploadIcon />;
      case ButtonType.DELETE:
        return <DeleteIcon />;
      case ButtonType.UP:
        return <ArrowUpward />;
      case ButtonType.DOWN:
        return <ArrowDownward />;
      case ButtonType.DUPLICATE:
        return <FilterNone />;
      case ButtonType.LOAD:
        return <Refresh />;
      case ButtonType.LINK:
        return <Link />;
      case ButtonType.INFO:
        return <Info />;
      case ButtonType.RIGHT:
        return <ChevronRight />;
      case ButtonType.RENEWAL:
        return <AddCircle />;
    }
  };

  const getColor = () => {
    switch (type) {
      case ButtonType.EDIT:
      case ButtonType.UP:
      case ButtonType.DOWN:
      case ButtonType.UPLOAD:
        return "info";
      case ButtonType.SAVE:
      case ButtonType.NEW:
      case ButtonType.DUPLICATE:
      case ButtonType.LOAD:
        return "primary";
      case ButtonType.DELETE:
        return "secondary";
      case ButtonType.LINK:
      case ButtonType.RIGHT:
      case ButtonType.RENEWAL:
        return "success";
      case ButtonType.INFO:
        return "warning";
      default:
        return "info";
    }
  };

  const renderButton = (loading: boolean) => (
    <Tooltip title={tooltip} placement={"bottom"} sideMargin>
      <ButtonWithStyles
        variant="contained"
        small={true}
        color={getColor() as any}
        startIcon={type && loading ? "" : getIcon()}
        type={type === ButtonType.SAVE ? "submit" : "button"}
      >
        {loading ? (
          <LoaderWrapper>
            <Loader size={24} />
          </LoaderWrapper>
        ) : (
          text && <Text>{text}</Text>
        )}
      </ButtonWithStyles>
    </Tooltip>
  );

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onClick && onClick();
  };

  if (to && target) {
    return (
      <a href={to} target={target}>
        {renderButton(loading)}
      </a>
    );
  }

  if (to)
    return (
      <ReactRouterLink to={to} target={target}>
        {renderButton(loading)}
      </ReactRouterLink>
    );

  if (onClick) return <div onClick={handleClick}>{renderButton(loading)}</div>;

  return renderButton(loading);
};

const ButtonVariant = styled(Button)`
  min-width: 20px !important;
  min-height: 30px !important;
  padding: 4px 8px !important;
  margin-right: 0 !important;

  > span > span {
    margin: 0;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Text = styled(ContentRow)`
  padding: 2px 0 0 4px;
`;

const ButtonWithStyles = withStyles((theme) => ({
  root: (props) =>
    props.color === "success"
      ? {
          fontSize: props.small ? "10px" : "1em",
          textTransform: "capitalize",
          backgroundColor: theme.palette.success.main,
          color: "#fff",
          "&:hover": {
            backgroundColor: theme.palette.success.dark,
            "@media (hover: none)": {
              backgroundColor: theme.palette.success.main,
            },
          },
        }
      : props.color === "primary"
      ? {
          textTransform: "capitalize",
          backgroundColor: theme.palette.primary.main,
          color: "#fff",
          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            "@media (hover: none)": {
              backgroundColor: theme.palette.primary.main,
            },
          },
        }
      : props.color === "secondary"
      ? {
          textTransform: "capitalize",
          backgroundColor: theme.palette.secondary.main,
          color: "#fff",
          "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
            "@media (hover: none)": {
              backgroundColor: theme.palette.secondary.main,
            },
          },
        }
      : {
          textTransform: "capitalize",
          backgroundColor: theme.palette.info.main,
          color: "rgba(0, 0, 0, 0.87)",
          "&:hover": {
            backgroundColor: theme.palette.info.dark,
            "@media (hover: none)": {
              backgroundColor: theme.palette.info.main,
            },
          },
        },
}))(ButtonVariant);
