import csCZ from './dictionary/csCZ';
import deDE from './dictionary/deDE';
import enGB from './dictionary/enGB';
import i18n from 'i18next';
import lang from './lang';
import { getI18n, initReactI18next, withTranslation } from 'react-i18next';
import { ProxyProperty } from '../utils/getPath';

export enum Languages {
	CZ = "cs-CZ",
	EN = "en-GB",
	DE = "de-DE",
}

export const settings: i18n.InitOptions = {
	debug: process.env.NODE_ENV === "development" ? true : false,
	initImmediate: false,
	detection: {
		order: ["localStorage", "customLangDetector"],
	},
	fallbackLng: Languages.CZ,
	lng: Languages.CZ,
	react: {
		bindI18n: "languageChanged loaded",
		bindStore: "added removed",
		nsMode: "default",
		wait: false,
	},
	resources: {
		"cs-CZ": {
			translation: {},
			...csCZ,
		},
		"en-GB": {
			translation: {},
			...enGB,
		},
		"de-DE": {
			translation: {},
			...deDE,
		},
	},
};

i18n.use(initReactI18next).init(settings);

export default i18n;
export { getI18n, lang, withTranslation };
export const getLanguage = () =>
	(getI18n() && getI18n().language) || Languages.CZ;

export function t(proxy: ProxyProperty, options?: object | undefined) {
	return i18n.t(proxy.path, options);
}
