import {call, put, takeLatest} from "redux-saga/effects";
import {getType} from "typesafe-actions";
import {ApiURL} from "constants/api";
import {fetchSaga} from "sagas/_sagas";
import {notificationAction} from "components/Toast/_actions";
import {Status} from "components/Toast/_types";
import {lang, t} from "translation/i18n";
import {translationPath} from "utils/getPath";
import {Method} from "constants/enum";
import {resetPasswordAction} from "./_actions";

function* settingsResetPasswordPostActionSaga(
    action: ReturnType<typeof resetPasswordAction.request>
): Generator {
    try {
        // @ts-ignore
        const {errorResponse, response, success, statusText} = yield call(fetchSaga,
            ApiURL.SUPER_ADMIN_RESET_PASSWORD,
            Method.POST,
            {
				bodyJSON: action.payload,
			}
        );

        if (!success || errorResponse) {
            yield put(resetPasswordAction.failure(errorResponse));
            yield put(
                notificationAction({
                    code: Status.ERROR,
                    message: t(translationPath(lang.common[statusText])),
                })
            );
            return;
        }

        if (success && response) {
            yield put(
                notificationAction({
                    code: Status.SUCCESS,
                    message: t(translationPath(lang.common.successful)),
                })
            );
            yield put(resetPasswordAction.success(response));
        }
    } catch (err) {
        yield put(resetPasswordAction.failure(err));
    }
}

export function* watchResetPasswordPostAction() {
    yield takeLatest(getType(resetPasswordAction.request), settingsResetPasswordPostActionSaga);
}

