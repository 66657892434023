import {connect} from "react-redux";
import {withRouter} from "react-router";
import {RootStateType} from "reducers";
import {compose, Dispatch} from "redux";
import Component, {DispatchProps, StateProps} from "./Component";
import {userDeleteByIdAction, userPutAction, usersGetAction,} from "./UsersTable/_actions";
import {User} from "./UsersTable/_types";

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  usersGetAction: (data: void) =>
      dispatch(usersGetAction.request(data)),
  userDeleteByIdAction: (data: string) =>
      dispatch(userDeleteByIdAction.request(data)),
  userPutAction: (data: User) => dispatch(userPutAction.request(data)),
});

const mapStateToProps = (state: RootStateType): StateProps => ({
  users: state.UserReducer.users,
  pending: state.UserReducer.pending,
  deleting: state.UserReducer.deleting,
  deletedId: state.UserReducer.deletedId,
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Component);
