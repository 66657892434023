import { createGlobalStyle, DefaultTheme } from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';

export const lightTheme: DefaultTheme = {
	colors: {
		primary: "#17785e",
		secondary: "#b61100",
		contentText: "#000",
		primaryText: {
			h1: "#374d7a",
			h2: "#3a4d9e",
			h3: "#b61100",
			default: "#222",
			hover: "#222",
			active: "#111",
			text: "#16191f",
		},
		secondaryText: {
			default: "#858480",
			hover: "#666",
			active: "#333",
		},
		text: {
			lightGray: "#c8cacc",
			white: "#fff",
		},
		background: {
			wrapper: "#f2f3f3",
			darkBlue: "#29303c",
			mediumBlue: "#253044",
			darkGray: "#ccc",
			black: "#000",
			content: "#fff",
			contentSecondary: "#fcfcfc",
			menu: "#f6f6f6",
			secondaryMenu: "#eee",
			darker: "#ccc",
			lightGray: "#f9f9f9",
			primary: {
				primary: "#17785e",
				hover: "rgba(23, 120, 94, .12)",
				active: "rgba(23, 120, 94, .25)",
			},
			button: {
				red: "#b61100",
				darkRed: "#a81100",
			},
		},
		forms: {
			border: "#b8b8b8",
			labelBorder: "#d8d8d8",
		},
		button: "#b61100",
		sectionsDivider: "#e6e6e6",
		status: {
			0: "gray",
			1: "#17785e",
			2: "#db1d0b",
		},
	},
	margin: "10px",
	marginDouble: "20px",
	padding: "10px",
	border: {
		gray: "1px solid rgba(255, 255, 255, 0.15)",
	},
	boxShadow:
		"0 1px 8px 1px rgba(0, 0, 0, 0.1), 0 0 4px 2px rgba(0, 0, 0, 0.05)",
	boxShadowFine: "0 2px 8px 1px #17785e, 0px 2px 6px -1px #17785e",
	boxShadowHalf:
		"0 1px 4px 1px rgba(0, 0, 0, 0.1), 0 0 2px 1px rgba(0, 0, 0, 0.05)",
	boxShadowSharp:
		"0 2px 4px 1px rgba(0, 0, 0, 0.15), 0px 2px 3px -1px rgba(0,0,0,0.5)",
	boxShadowSharpHover:
		"0 2px 8px 1px rgba(0, 0, 0, 0.15), 0px 2px 6px -1px rgba(0,0,0,0.5)",
	boxShadowSharpActive:
		"0 2px 16px 1px rgba(0, 0, 0, 0.2), 0px 2px 12px -1px rgba(0,0,0,0.75)",
};

export const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#17785e",
		},
		secondary: {
			main: "rgb(220, 0, 78)",
		},
		success: {
			main: "#484848",
		},
		info: {
			main: "#e0e0e0",
		},
		warning: {
			main: "rgb(255, 229, 48)",
		},
	},
});

const breakpoints = {
	smallTo: "1000px",
	mediumFrom: "1200px",
	mediumTo: "1200px",
	largeFrom: "1450px",
	largeTo: "1450px",
	extraLargeFrom: "1500px",
	extraLargeTo: "3000x",
	maximumFrom: "1201px",
};

export const device = {
	small: `(max-width: ${breakpoints.smallTo})`,
	mediumOnly: `(min-width: ${breakpoints.mediumFrom}) and (max-width: ${breakpoints.mediumTo})`,
	medium: `(max-width: ${breakpoints.mediumTo})`,
	largeOnly: `(min-width: ${breakpoints.largeFrom}) and (max-width: ${breakpoints.largeTo})`,
	large: `(max-width: ${breakpoints.largeTo})`,
	extraLargeOnly: `(min-width: ${breakpoints.extraLargeFrom}) and (max-width: ${breakpoints.extraLargeTo})`,
	extraLarge: `(max-width: ${breakpoints.extraLargeTo})`,
	maximum: `(min-width: ${breakpoints.maximumFrom})`,
};

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {
    padding:0;
    
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2c3a78",endColorstr="#2c3a53",GradientType=1);
  }
  
  video {
    object-fit: fill;
}
 `;
