import { ApiURL } from "constants/api";
import { call, put, takeLatest } from "redux-saga/effects";
import { fetchSaga } from "sagas/_sagas";
import { getType } from "typesafe-actions";
import { lang, t } from "translation/i18n";
import { Method } from "constants/enum";
import { notificationAction } from "components/Toast/_actions";
import { push } from "connected-react-router";
import { Routes } from "constants/routes";
import { Status } from "components/Toast/_types";
import { translationPath } from "utils/getPath";
import {
  distributorUserRegisterPostAction,
  distributorDeleteByIdAction,
  distributorGetByIdAction,
  distributorPostAction,
  distributorPutAction,
  distributorsGetAction,
  distributorUsers,
  distributorUserDeleteByIdAction,
  distributorUserPutAction,
  distributorUserGetByIdAction,
  distributorReactivatePostAction,
} from "./_actions";

function* distributorsGetActionSaga(
  action: ReturnType<typeof distributorsGetAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.DISTRIBUTOR,
      Method.GET
    );

    if (!success || errorResponse) {
      yield put(distributorsGetAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(distributorsGetAction.success(response));
    }
  } catch (err) {
    yield put(distributorsGetAction.failure(err));
  }
}

function* distributorGetByIdActionSaga(
  action: ReturnType<typeof distributorGetByIdAction.request>
): Generator {
  try {
    // @ts-ignore

    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.DISTRIBUTOR,
      Method.GET,
      {
        param: action.payload,
      }
    );
    console.log(action.payload);

    if (!success || errorResponse) {
      yield put(distributorGetByIdAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      console.log(response);
      yield put(distributorGetByIdAction.success(response));
    }
  } catch (err) {
    yield put(distributorGetByIdAction.failure(err));
  }
}

function* distributorUserGetByIdActionSaga(
  action: ReturnType<typeof distributorUserGetByIdAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.DISTRIBUTOR_USERS,
      Method.GET,
      {
        param: action.payload,
      }
    );

    if (!success || errorResponse) {
      yield put(distributorUserGetByIdAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(distributorUserGetByIdAction.success(response));
    }
  } catch (err) {
    yield put(distributorUserGetByIdAction.failure(err));
  }
}

function* distributorPostActionSaga(
  action: ReturnType<typeof distributorPostAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.DISTRIBUTOR,
      Method.POST,
      {
        bodyJSON: action.payload.data,
      }
    );

    if (!success || errorResponse) {
      yield put(distributorPostAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(distributorPostAction.success(response));
      yield put(push(action.payload.path));
    }
  } catch (err) {
    yield put(distributorPostAction.failure(err));
  }
}

function* distributorPutActionSaga(
  action: ReturnType<typeof distributorPutAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.DISTRIBUTOR,
      Method.PUT,
      {
        param: action.payload.data.Id,
        bodyJSON: action.payload.data,
      }
    );

    if (!success || errorResponse) {
      yield put(distributorPutAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(distributorPutAction.success(response));

      yield put(distributorsGetAction.request());
      yield put(push(action.payload.path));
    }
  } catch (err) {
    yield put(distributorPutAction.failure(err));
  }
}

function* distributorReactivatePostActionSaga(
  action: ReturnType<typeof distributorReactivatePostAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.DISTRIBUTOR_REACTIVATE,
      Method.POST,
      {
        bodyJSON: { Id: action.payload },
      }
    );

    if (!success || errorResponse) {
      yield put(distributorReactivatePostAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(distributorReactivatePostAction.success(response));
    }
  } catch (err) {
    yield put(distributorReactivatePostAction.failure(err));
  }
}

function* distributorRegisterUserPostActionSaga(
  action: ReturnType<typeof distributorUserRegisterPostAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.DISTRIBUTOR_REGISTER_USER,
      Method.POST,
      {
        bodyJSON: action.payload,
      }
    );

    if (!success || errorResponse) {
      yield put(distributorUserRegisterPostAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(distributorUserRegisterPostAction.success(response));
      yield put(push(Routes.DISTRIBUTOR_PORTAL_USERS));
    }
  } catch (err) {
    yield put(distributorUserRegisterPostAction.failure(err));
  }
}

function* distributorUsersGetActionSaga(
  action: ReturnType<typeof distributorUsers.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.DISTRIBUTOR_USERS,
      Method.GET
    );

    if (!success || errorResponse) {
      yield put(distributorUsers.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(distributorUsers.success(response));
    }
  } catch (err) {
    yield put(distributorUsers.failure(err));
  }
}

function* distributorDeleteActionSaga(
  action: ReturnType<typeof distributorDeleteByIdAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.DISTRIBUTOR,
      Method.DELETE,
      {
        param: action.payload,
      }
    );

    if (!success || errorResponse) {
      yield put(distributorDeleteByIdAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(distributorsGetAction.request());
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(distributorDeleteByIdAction.success(response));
    }
  } catch (err) {
    console.log(err);
    yield put(distributorDeleteByIdAction.failure(err));
  }
}

function* distributorDeleteUserActionSaga(
  action: ReturnType<typeof distributorUserDeleteByIdAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.DISTRIBUTOR_USERS,
      Method.DELETE,
      {
        param: action.payload,
      }
    );

    if (!success || errorResponse) {
      yield put(distributorUserDeleteByIdAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(distributorUserDeleteByIdAction.success(response));
    }
  } catch (err) {
    console.log(err);
    yield put(distributorUserDeleteByIdAction.failure(err));
  }
}

function* distributorUserPutActionSaga(
  action: ReturnType<typeof distributorUserPutAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.DISTRIBUTOR_USERS,
      Method.PUT,
      {
        bodyJSON: action.payload,
      }
    );

    if (!success || errorResponse) {
      yield put(distributorUserPutAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(distributorUserPutAction.success(response));
      yield put(distributorUsers.request());
      yield put(push(Routes.DISTRIBUTOR_PORTAL_USERS));
    }
  } catch (err) {
    console.log(err);
    yield put(distributorUserPutAction.failure(err));
  }
}

export function* watchDistributorGetAction() {
  yield takeLatest(
    getType(distributorsGetAction.request),
    distributorsGetActionSaga
  );
}

export function* watchDistributorPostAction() {
  yield takeLatest(
    getType(distributorPostAction.request),
    distributorPostActionSaga
  );
}

export function* watchDistributorPutAction() {
  yield takeLatest(
    getType(distributorPutAction.request),
    distributorPutActionSaga
  );
}

export function* watchDistributorClientRegisterPostAction() {
  yield takeLatest(
    getType(distributorUserRegisterPostAction.request),
    distributorRegisterUserPostActionSaga
  );
}
export function* watchDistributoUsersGetAction() {
  yield takeLatest(
    getType(distributorUsers.request),
    distributorUsersGetActionSaga
  );
}

export function* watchDistributorGetByIdAction() {
  yield takeLatest(
    getType(distributorGetByIdAction.request),
    distributorGetByIdActionSaga
  );
}

export function* watchDistributorUserGetByIdActionSaga() {
  yield takeLatest(
    getType(distributorUserGetByIdAction.request),
    distributorUserGetByIdActionSaga
  );
}

export function* watchDistributorDeleteAction() {
  yield takeLatest(
    getType(distributorDeleteByIdAction.request),
    distributorDeleteActionSaga
  );
}
export function* watchDistributorDeleteUserAction() {
  yield takeLatest(
    getType(distributorUserDeleteByIdAction.request),
    distributorDeleteUserActionSaga
  );
}

export function* watchDistributorUserPutActionSaga() {
  yield takeLatest(
    getType(distributorUserPutAction.request),
    distributorUserPutActionSaga
  );
}

export function* watchDistributorReactivatePostActionSaga() {
  yield takeLatest(
    getType(distributorReactivatePostAction.request),
    distributorReactivatePostActionSaga
  );
}
