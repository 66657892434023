import Component, { DispatchProps, StateProps } from "./Component";
import { compose, Dispatch } from "redux";
import { connect } from "react-redux";
import { Distributor } from "../../../Admin/Distributors/UsersTable/_types";
import { RootStateType } from "reducers";
import { withRouter } from "react-router";
import {
  subDistributorsRegisterPostAction,
  subDistributorsGetByIdAction,
  subDistributorsPutAction,
} from "containers/Portal/SubDistributor/_actions";

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  subDistributorsRegisterPostAction: (data: Distributor) =>
    dispatch(subDistributorsRegisterPostAction.request(data)),
  subDistributorsPutAction: (data: Distributor) =>
    dispatch(subDistributorsPutAction.request(data)),
  subDistributorsGetByIdAction: (data: string) =>
    dispatch(subDistributorsGetByIdAction.request(data)),
});

const mapStateToProps = (state: RootStateType): StateProps => ({
  pending: state.SubDistributorsReducer.pending,
  distributor: state.SubDistributorsReducer.subDistributor,
  routerState: state.router.location.state,
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
