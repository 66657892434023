import React from "react";
import styled from "styled-components";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import {Center} from "../../constants/globalStyles";

interface Content {
    title?: string;
    children: React.ReactNode;
    pending?: boolean;
    withoutMargin?: boolean;
}

export const Content: React.FC<Content> = ({withoutMargin, title, children, pending}) => {
    return (
        <Wrapper withoutMargin={withoutMargin}>
            {title && <ContentHeader>{title}</ContentHeader>}
            <ContentBody>
                {pending ? (
                    <Loading>
                        <LoadingIndicator/>
                    </Loading>
                ) : (
                    children
                )}
            </ContentBody>
        </Wrapper>
    );
};

export const Wrapper = styled.div<{ withoutMargin: boolean }>`
  padding: ${(props) => (props.withoutMargin ? "0" : `2em 1em;`)};
`;

export const ContentHeader = styled.div`
  font-size: 2rem;
  line-height: 1.6rem;
  font-weight: 300;
  padding-bottom: 0.4em;
  border-bottom: 1px solid #ccc;
`;

export const Loading = styled(Center)`
  width: 100%;
  min-height: 300px;
`;

export const ContentBody = styled.div``;
