import FormikRow from "../Form/FormikRow";
import React from "react";
import styled from "styled-components";
import { Button } from "../Button";
import { ContentCard, Justify } from "../../constants/globalStyles";
import { FormikProps } from "formik";
import { Input } from "../../constants/enum";
import { lang } from "../../translation/i18n";
import { Link, useLocation } from "react-router-dom";
import { Routes } from "constants/routes";
import { translationPath } from "../../utils/getPath";
import { useTranslation } from "react-i18next";

export interface OAuth {
	title: string;
	formik: FormikProps<any>;
	pending: boolean;
	selection?: boolean;
}

export const OAuth: React.FC<OAuth> = ({
	title,
	formik,
	pending,
	selection,
}) => {
	const { t } = useTranslation();
	const location = useLocation();

	return (
		<Wrapper>
			{selection && (
				<Accounts>
					<Account
						to={Routes.HOME}
						active={location.pathname === Routes.HOME ? 1 : 0}
					>
						{t(translationPath(lang.common.distributor).path)}
					</Account>
					<Account
						to={Routes.SUBDISTRIBUTOR}
						active={location.pathname === Routes.SUBDISTRIBUTOR ? 1 : 0}
					>
						{t(translationPath(lang.login.subdistributor).path)}
					</Account>
					<Account
						to={Routes.ADMINISTRATOR}
						active={location.pathname === Routes.ADMINISTRATOR ? 1 : 0}
					>
						{t(translationPath(lang.login.administrator).path)}
					</Account>
				</Accounts>
			)}
			<Header>{title}</Header>
			<FormWrapper>
				<FormikRow
					formik={formik}
					name="username"
					title={t(translationPath(lang.input.username).path)}
					type={Input.TEXT}
					titleUp
				/>
				<FormikRow
					formik={formik}
					name="password"
					title={t(translationPath(lang.input.password).path)}
					type={Input.PASSWORD}
					titleUp
				/>
				<ContentWrapper justify={Justify.SPACE_BETWEEN}>
					<Reset to={Routes.REQUEST_RESET}>
						{t(translationPath(lang.common.reset).path)}
					</Reset>
					<ButtonOAuth type="submit" loading={pending}>
						{t(translationPath(lang.input.login).path)}
					</ButtonOAuth>
				</ContentWrapper>
			</FormWrapper>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	height: auto;
	min-height: 579px;
	width: 679px;

	margin: 8em auto;
	border-radius: 8px;
	background-color: #fff;
	box-shadow: 0 80px 99px rgba(0, 0, 0, 0.08);
`;

const Header = styled.h3`
	color: ${(props) => props.theme.colors.primaryText.default};

	margin-top: 2em;
	font-size: 1.4em;
	font-weight: 500;
`;

const ButtonOAuth = styled(Button)`
	background-color: ${(props) => props.theme.colors.primary};

	&:hover {
		background: #177864;
		-webkit-box-shadow: 0 1px 1px 0 rgba(0, 94, 44, 0.45),
			0 1px 3px 1px rgba(0, 94, 44, 0.3);
		box-shadow: 0 1px 1px 0 rgba(33, 94, 13, 0.45),
			0 1px 3px 1px rgba(0, 94, 44, 0.3);
	}
`;

const Reset = styled(Link)`
	color: ${(props) => props.theme.colors.primary};

	font-weight: 500;
	font-size: 1em;
	text-decoration: none;
`;

const ContentWrapper = styled(ContentCard)`
	margin-top: 3em;
	width: 100%;
`;

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;

	padding: 0px 40px 36px;

	width: 100%;

	margin: 2em 0px;
`;

const Accounts = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;

	width: 100%;
`;

const Account = styled(Link)<{ active: any }>`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 50%;
	padding: 1em 2em;
	border-bottom: ${(props) =>
		props.active ? "2px solid #17785e" : "2px solid #f1f1f1"};

	text-decoration: none;

	color: #cacaca;
	background-color: #fff;
`;
