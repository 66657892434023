import Component, { DispatchProps, StateProps } from './Component';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootStateType } from 'reducers';
import { User } from './UsersTable/_types';
import { withRouter } from 'react-router';
import {
	distributorUsers,
	distributorUserDeleteByIdAction,
	distributorUserPutAction,
} from "containers/Portal/Admin/Distributors/UsersTable/_actions";

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	usersGetAction: (data: void) => dispatch(distributorUsers.request(data)),
	userDeleteByIdAction: (data: string) =>
		dispatch(distributorUserDeleteByIdAction.request(data)),
	userPutAction: (data: User) =>
		dispatch(distributorUserPutAction.request(data)),
});

const mapStateToProps = (state: RootStateType): StateProps => ({
	users: state.DistributorReducer.users,
	pending: state.DistributorReducer.pending,
	deleting: state.DistributorReducer.deleting,
	deletedId: state.DistributorReducer.deletedId,
});

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(Component);
