import Component, { DispatchProps, StateProps } from './Component';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootStateType } from 'reducers';
import { User } from './UsersTable/_types';
import { withRouter } from 'react-router';
import {
	subDistributorsPutUserByIdAction,
	subDistributorsDeleteUserByIdAction,
	subDistributorsGetUsersAction,
} from "../_actions";

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	usersGetAction: (data: void) =>
		dispatch(subDistributorsGetUsersAction.request(data)),
	userDeleteByIdAction: (data: string) =>
		dispatch(subDistributorsDeleteUserByIdAction.request(data)),
	userPutAction: (data: User) =>
		dispatch(subDistributorsPutUserByIdAction.request(data)),
});

const mapStateToProps = (state: RootStateType): StateProps => ({
	users: state.SubDistributorsReducer.users,
	pending: state.SubDistributorsReducer.pending,
	deleting: state.SubDistributorsReducer.deleting,
	deletedId: state.SubDistributorsReducer.deletedId,
});

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(Component);
