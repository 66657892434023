import React, { FC } from 'react';
import styled from 'styled-components';
import { Pagination } from './Pagination';
import { usePagination, useSortBy, useTable } from 'react-table';

function Table({ columns, data, pagination }) {
	if (!columns || !data) return <></>;

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
		// eslint-disable-next-line react-hooks/rules-of-hooks
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 },
		},
		useSortBy,
		usePagination
	);

	return (
		<>
			{pagination && (
				<Pagination
					pageSize={pageSize}
					setPageSize={setPageSize}
					pageIndex={pageIndex}
					gotoPage={gotoPage}
					canPreviousPage={canPreviousPage}
					previousPage={previousPage}
					nextPage={nextPage}
					canNextPage={canNextPage}
					pageCount={pageCount}
					pageOptions={pageOptions}
				/>
			)}

			<table {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps(column.getSortByToggleProps())}>
									{column.render("Header")}
									<span>
										{column.isSorted ? (column.isSortedDesc ? "▼" : "▲") : ""}
									</span>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return (
										<td {...cell.getCellProps()}>{cell.render("Cell")}</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
			{pagination && (
				<Pagination
					pageSize={pageSize}
					setPageSize={setPageSize}
					pageIndex={pageIndex}
					gotoPage={gotoPage}
					canPreviousPage={canPreviousPage}
					previousPage={previousPage}
					nextPage={nextPage}
					canNextPage={canNextPage}
					pageCount={pageCount}
					pageOptions={pageOptions}
				/>
			)}
		</>
	);
}

interface ScrollTable {
	columns: any;
	data: any;
	pagination: boolean;
}

export const ScrollTable: FC<ScrollTable> = ({ columns, data, pagination }) => {
	return (
		<Styles>
			{data && columns && (
				<Table columns={columns} data={data} pagination={pagination} />
			)}
		</Styles>
	);
};

const Styles = styled.div`
	padding: 1rem;
	overflow: scroll;

	table {
		border-spacing: 0;
		min-width: 1550px;

		tr:nth-child(even) {
			background-color: rgba(0, 0, 0, 0.03);
		}

		tr:hover {
			background: #f1f1f1;
		}

		th {
			padding: 13px 10px;
			background-color: #fafafa;
			border-bottom: 1px solid #e5e5e5;
			border-top: 1px solid #eee;
			font-size: 14px;
			text-align: start;

			:first-child {
				border-left: 1px solid #e5e5e5;
				border-radius: 8px 0 0 0;
			}

			:last-child {
				border-right: 1px solid #e5e5e5;
				border-radius: 0 8px 0 0;
			}
		}

		td {
			border-bottom: 1px solid #f0f0f0;
			font-size: 0.875rem;
			color: #2e2e2e;
			word-break: break-word;
			padding: 8px;

			:first-child {
				border-left: 1px solid #e5e5e5;
			}

			:last-child {
				border-right: 1px solid #e5e5e5;
			}
		}
	}

	.pagination {
		padding: 0.5rem;
	}
`;
