import * as Yup from 'yup';
import FormikRow from '../../../../components/Form/FormikRow';
import React from 'react';
import styled from 'styled-components';
import { Box } from '../../../../components/Box';
import { Button } from '../../../../components/Button';
import { Content } from 'components/Content';
import { Input } from '../../../../constants/enum';
import { lang } from 'translation/i18n';
import { ResetPassword } from './_types';
import { translationPath } from 'utils/getPath';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
	ContentCard,
	GridItem,
	GridRow,
	Justify,
} from "constants/globalStyles";

export interface StateProps {
	pending: boolean;
}

export interface DispatchProps {
	resetPasswordAction: (data: ResetPassword) => void;
}

const Component: React.FC<StateProps & DispatchProps> = ({
	resetPasswordAction,
	pending,
}) => {
	const { t } = useTranslation();

	const initialValues: ResetPassword = {
		oldPassword: "",
		newPassword: "",
		verifyPassword: "",
	};

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: Yup.object({
			oldPassword: Yup.string().required(
				t(translationPath(lang.validation.required).path)
			),
			newPassword: Yup.string().required(
				t(translationPath(lang.validation.required).path)
			),
			verifyPassword: Yup.string().oneOf(
				[Yup.ref("newPassword"), null],
				t(translationPath(lang.validation.passwordMatch).path)
			),
		}),
		onSubmit: (value: ResetPassword) => {
			resetPasswordAction({
				...value,
			});
		},
	});

	return (
		<Content title={t(translationPath(lang.common.settings).path)}>
			<form onSubmit={formik.handleSubmit}>
				<Box title={t(translationPath(lang.input.changePass).path)}>
					<GridRow>
						<ContentWrapper>
							<GridItem>
								<FormikRow
									formik={formik}
									name="oldPassword"
									title={t(translationPath(lang.input.currentPassword).path)}
									titleWidth={60}
									type={Input.PASSWORD}
								/>
								<FormikRow
									formik={formik}
									name="newPassword"
									title={t(translationPath(lang.input.newPassword).path)}
									titleWidth={60}
									type={Input.PASSWORD}
								/>
								<FormikRow
									formik={formik}
									name="verifyPassword"
									title={t(translationPath(lang.input.validationPassword).path)}
									titleWidth={60}
									type={Input.PASSWORD}
								/>
								<ButtonWrapper justify={Justify.END}>
									<ButtonOAuth type="submit" loading={pending}>
										{t(translationPath(lang.button.save).path)}
									</ButtonOAuth>
								</ButtonWrapper>
							</GridItem>
						</ContentWrapper>
					</GridRow>
				</Box>
			</form>
		</Content>
	);
};

export default React.memo(Component);

const ButtonOAuth = styled(Button)`
	background-color: ${(props) => props.theme.colors.primary};

	&:hover {
		background: #177864;
		-webkit-box-shadow: 0 1px 1px 0 rgba(0, 94, 44, 0.45),
			0 1px 3px 1px rgba(0, 94, 44, 0.3);
		box-shadow: 0 1px 1px 0 rgba(33, 94, 13, 0.45),
			0 1px 3px 1px rgba(0, 94, 44, 0.3);
	}
`;

const ContentWrapper = styled(ContentCard)`
	width: 35%;
`;

const ButtonWrapper = styled(ContentCard)`
	margin-top: 2em;
	width: 100%;
`;
