import * as React from "react";
import Clients from "containers/Portal/Distributor/Clients/Container";
import ClientsEdit from "containers/Portal/Distributor/Clients/Edit/Container";
import CreateDistributor from "containers/Portal/Distributor/Distributors/Edit/Container";
import DistributorsPortal from "containers/Portal/Distributor/Distributors/Container";
import UserEdit from "containers/Portal/Distributor/Users/Edit/Container";
import Users from "containers/Portal/Distributor/Users/Container";
import { ProtectedRoute } from "./ProtectedRoute";
import { Routes } from "../../constants/routes";
import { Switch } from "react-router-dom";

export const RouterListDistributor = () => {
  return (
    <Switch>
      <ProtectedRoute
        exact={true}
        path={Routes.DISTRIBUTORS_DISTRIBUTOR_CREATE}
        component={CreateDistributor}
      />

      <ProtectedRoute
        path={Routes.DISTRIBUTORS_DISTRIBUTOR_EDIT_ID}
        component={CreateDistributor}
      />

      <ProtectedRoute
        exact={true}
        path={Routes.DISTRIBUTORS_DISTRIBUTOR_EDIT}
        component={CreateDistributor}
      />
      <ProtectedRoute
        exact={true}
        path={Routes.DISTRIBUTOR_PORTAL_CLIENTS}
        component={Clients}
      />
      <ProtectedRoute
        path={Routes.DISTRIBUTOR_PORTAL_CLIENTS_EDIT_ID}
        component={ClientsEdit}
      />
      <ProtectedRoute
        exact={true}
        path={Routes.DISTRIBUTOR_PORTAL_CLIENTS_EDIT}
        component={ClientsEdit}
      />
      <ProtectedRoute
        exact={true}
        path={Routes.DISTRIBUTOR_PORTAL_USERS}
        component={Users}
      />
      <ProtectedRoute
        exact={true}
        path={Routes.DISTRIBUTOR_PORTAL_USER_EDIT_ID}
        component={UserEdit}
      />
      <ProtectedRoute
        exact={true}
        path={Routes.DISTRIBUTOR_PORTAL_USER_CREATE}
        component={UserEdit}
      />
      <ProtectedRoute
        exact={true}
        path={Routes.DISTRIBUTOR_PORTAL}
        component={DistributorsPortal}
      />
    </Switch>
  );
};
