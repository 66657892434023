import * as Yup from 'yup';
import FormikRow from 'components/Form/FormikRow';
import React, { useEffect } from 'react';
import { Box } from '../../../../../components/Box';
import { ButtonType, MaterialButton } from 'components/Button/MaterialButton';
import { Content } from 'components/Content';
import { getCountryCode } from 'utils/getCountryCodes';
import { GridItem, GridRow } from 'constants/globalStyles';
import { Input } from 'constants/enum';
import { lang } from 'translation/i18n';
import { lastPathMember, translationPath } from 'utils/getPath';
import { Routes } from 'constants/routes';
import { useFormik } from 'formik';
import { useLocation, useParams } from 'react-router-dom';
import { User, UserProxy } from '../UsersTable/_types';
import { useTranslation } from 'react-i18next';

export interface StateProps {
	pending: boolean;
	routerState: any;
	user: User;
}

export interface DispatchProps {
	userPostAction: (data: User) => void;
	userPutAction: (data: User) => void;
	userGetByIdAction: (data: string) => void;
}

export interface Params {
	id: string;
}

const Component: React.FC<StateProps & DispatchProps> = ({
	userPostAction,
	userPutAction,
	pending,
	userGetByIdAction,
	user,
}) => {
	const { t } = useTranslation();
	const location = useLocation();
	const { id } = useParams<Params>();
	const initialValues: User = location.pathname.includes(
		Routes.DISTRIBUTOR_PORTAL_USER_EDIT
	)
		? user
		: {
				Email: "",
				Name: "",
				Surname: "",
				CountryCode: "",
				PhoneNumber: "",
				Password: "",
		  };

	useEffect(() => {
		userGetByIdAction(id);
	}, [id && userGetByIdAction]);

	const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: Yup.object(
			location.pathname.includes(Routes.DISTRIBUTOR_PORTAL_USER_EDIT)
				? {
						Email: Yup.string()
							.email(t(translationPath(lang.validation.email).path))
							.min(
								1,
								t(translationPath(lang.validation.min).path, { count: 1 })
							)
							.max(
								200,
								t(translationPath(lang.validation.max).path, { count: 200 })
							)
							.required(t(translationPath(lang.validation.required).path)),
						Name: Yup.string()
							.min(
								1,
								t(translationPath(lang.validation.min).path, { count: 1 })
							)
							.max(
								200,
								t(translationPath(lang.validation.max).path, { count: 200 })
							)
							.required(t(translationPath(lang.validation.required).path)),
						Surname: Yup.string()
							.min(
								1,
								t(translationPath(lang.validation.min).path, { count: 1 })
							)
							.max(
								200,
								t(translationPath(lang.validation.max).path, { count: 200 })
							)
							.required(t(translationPath(lang.validation.required).path)),
						PhoneNumber: Yup.string().matches(
							phoneRegExp,
							t(translationPath(lang.validation.phone).path)
						),
				  }
				: {
						Email: Yup.string()
							.email(t(translationPath(lang.validation.email).path))
							.min(
								1,
								t(translationPath(lang.validation.min).path, { count: 1 })
							)
							.max(
								200,
								t(translationPath(lang.validation.max).path, { count: 200 })
							)
							.required(t(translationPath(lang.validation.required).path)),
						Name: Yup.string()
							.min(
								1,
								t(translationPath(lang.validation.min).path, { count: 1 })
							)
							.max(
								200,
								t(translationPath(lang.validation.max).path, { count: 200 })
							)
							.required(t(translationPath(lang.validation.required).path)),
						Surname: Yup.string()
							.min(
								1,
								t(translationPath(lang.validation.min).path, { count: 1 })
							)
							.max(
								200,
								t(translationPath(lang.validation.max).path, { count: 200 })
							)
							.required(t(translationPath(lang.validation.required).path)),
						Password: Yup.string()
							.min(
								1,
								t(translationPath(lang.validation.min).path, { count: 1 })
							)
							.max(
								200,
								t(translationPath(lang.validation.max).path, { count: 200 })
							)
							.required(t(translationPath(lang.validation.required).path)),
						PhoneNumber: Yup.string()
							.matches(
								phoneRegExp,
								t(translationPath(lang.validation.phone).path)
							)
							.required(t(translationPath(lang.validation.required).path)),
				  }
		),

		onSubmit: (values: any) => {
			location.pathname.includes(Routes.DISTRIBUTOR_PORTAL_USER_EDIT)
				? userPutAction(values)
				: userPostAction(values);
		},
	});

	return (
		<Content
			title={
				location.pathname.includes(Routes.DISTRIBUTOR_PORTAL_USER_EDIT)
					? t(translationPath(lang.users.editUser).path)
					: t(translationPath(lang.users.newUser).path)
			}
		>
			<form onSubmit={formik.handleSubmit}>
				<Box
					title={
						location.pathname.includes(Routes.DISTRIBUTOR_PORTAL_USER_EDIT)
							? t(translationPath(lang.users.editUser).path)
							: t(translationPath(lang.users.newUser).path)
					}
					action={[
						<MaterialButton
							tooltip={t(translationPath(lang.button.saveItem).path)}
							type={ButtonType.SAVE}
							loading={pending}
						/>,
					]}
				>
					<GridRow>
						<GridItem>
							<FormikRow
								formik={formik}
								name={lastPathMember(UserProxy.Name).path}
								title={t(translationPath(lang.input.forename).path)}
								titleWidth={30}
								type={Input.TEXT}
							/>
							<FormikRow
								formik={formik}
								name={lastPathMember(UserProxy.Surname).path}
								title={t(translationPath(lang.input.surname).path)}
								titleWidth={30}
								type={Input.TEXT}
							/>
							<FormikRow
								formik={formik}
								title={t(translationPath(lang.input.phone).path)}
								titleWidth={30}
								countryCode={lastPathMember(UserProxy.CountryCode).path}
								phoneNumber={lastPathMember(UserProxy.PhoneNumber).path}
								type={Input.PHONE}
								options={getCountryCode().map((value: any) => {
									return { value: value, label: value };
								})}
							/>
							<FormikRow
								formik={formik}
								name={lastPathMember(UserProxy.Email).path}
								title={t(translationPath(lang.input.email).path)}
								titleWidth={30}
								type={Input.TEXT}
							/>
							{!location.pathname.includes(
								Routes.DISTRIBUTOR_PORTAL_USER_EDIT
							) && (
								<FormikRow
									formik={formik}
									name={lastPathMember(UserProxy.Password).path}
									title={t(translationPath(lang.input.password).path)}
									titleWidth={30}
									type={Input.PASSWORD}
								/>
							)}
						</GridItem>
					</GridRow>
				</Box>
			</form>
		</Content>
	);
};

export default React.memo(Component);
