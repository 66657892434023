import { ApiURL } from "constants/api";
import { call, put, takeLatest } from "redux-saga/effects";
import { fetchSaga } from "sagas/_sagas";
import { getType } from "typesafe-actions";
import { lang, t } from "translation/i18n";
import { Method } from "constants/enum";
import { notificationAction } from "components/Toast/_actions";
import { push } from "connected-react-router";
import { Routes } from "constants/routes";
import { Status } from "components/Toast/_types";
import { translationPath } from "utils/getPath";
import {
  subDistributorsGetAction,
  subDistributorsGetByIdAction,
  subDistributorsRegisterPostAction,
  subDistributorsPutAction,
  subDistributorsDeleteByIdAction,
  subDistributorsGetUsersAction,
  subDistributorsGetUserByIdAction,
  subDistributorsDeleteUserByIdAction,
  subDistributorsPutUserByIdAction,
  subDistributorsPostUserAction,
  subDistributorsReactivePostAction,
} from "./_actions";

function* subDistributorsGetActionSaga(
  action: ReturnType<typeof subDistributorsGetAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.SUBDISTRIBUTOR,
      Method.GET
    );

    if (!success || errorResponse) {
      yield put(subDistributorsGetAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(subDistributorsGetAction.success(response));
    }
  } catch (err) {
    yield put(subDistributorsGetAction.failure(err));
  }
}

function* subDistributorsGetByIdActionSaga(
  action: ReturnType<typeof subDistributorsGetByIdAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.SUBDISTRIBUTOR,
      Method.GET,
      {
        param: action.payload,
      }
    );

    if (!success || errorResponse) {
      yield put(subDistributorsGetByIdAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(subDistributorsGetByIdAction.success(response));
    }
  } catch (err) {
    yield put(subDistributorsGetByIdAction.failure(err));
  }
}

function* subDistributorsGetUserByIdActionSaga(
  action: ReturnType<typeof subDistributorsGetUserByIdAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.SUBDISTRIBUTOR_USERS,
      Method.GET,
      {
        param: action.payload,
      }
    );

    if (!success || errorResponse) {
      yield put(subDistributorsGetUserByIdAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(subDistributorsGetUserByIdAction.success(response));
    }
  } catch (err) {
    yield put(subDistributorsGetUserByIdAction.failure(err));
  }
}

function* subDistributorsRegisterPostActionSaga(
  action: ReturnType<typeof subDistributorsRegisterPostAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.SUBDISTRIBUTOR_REGISTER,
      Method.POST,
      {
        bodyJSON: action.payload,
      }
    );

    if (!success || errorResponse) {
      yield put(subDistributorsRegisterPostAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(push(Routes.DISTRIBUTOR_PORTAL));
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(subDistributorsRegisterPostAction.success(response));
    }
  } catch (err) {
    yield put(subDistributorsRegisterPostAction.failure(err));
  }
}

function* subDistributorsPutActionSaga(
  action: ReturnType<typeof subDistributorsPutAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.SUBDISTRIBUTOR,
      Method.PUT,
      {
        param: action.payload.Id,
        bodyJSON: action.payload,
      }
    );

    if (!success || errorResponse) {
      yield put(subDistributorsPutAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(subDistributorsPutAction.success(response));
      yield put(subDistributorsGetAction.request());
      yield put(push(Routes.DISTRIBUTOR_PORTAL));
    }
  } catch (err) {
    yield put(subDistributorsPutAction.failure(err));
  }
}

function* subDistributorsReactovatePostActionSaga(
  action: ReturnType<typeof subDistributorsReactivePostAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.SUBDISTRIBUTOR_REACTIVATE,
      Method.POST,
      {
        bodyJSON: { Id: action.payload },
      }
    );

    if (!success || errorResponse) {
      yield put(subDistributorsReactivePostAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(push(Routes.DISTRIBUTOR_PORTAL));
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(subDistributorsReactivePostAction.success(response));
    }
  } catch (err) {
    yield put(subDistributorsReactivePostAction.failure(err));
  }
}
function* subDistributorsRegisterUserPostActionSaga(
  action: ReturnType<typeof subDistributorsPostUserAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.SUBDISTRIBUTOR_REGISTER_USER,
      Method.POST,
      {
        bodyJSON: action.payload,
      }
    );

    if (!success || errorResponse) {
      yield put(subDistributorsPostUserAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(subDistributorsPostUserAction.success(response));
      yield put(push(Routes.SUB_DISTRIBUTORS_USERS));
    }
  } catch (err) {
    yield put(subDistributorsPostUserAction.failure(err));
  }
}

function* subDistributorsDeleteUserByIdActionSaga(
  action: ReturnType<typeof subDistributorsDeleteUserByIdAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.SUBDISTRIBUTOR_USERS,
      Method.DELETE,
      {
        param: action.payload,
      }
    );
    if (!success || errorResponse) {
      yield put(subDistributorsDeleteUserByIdAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(subDistributorsGetUsersAction.request());
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(subDistributorsDeleteUserByIdAction.success(response));
    }
  } catch (err) {
    yield put(subDistributorsDeleteUserByIdAction.failure(err));
  }
}

function* subDistributorsPutUserByIdActionSaga(
  action: ReturnType<typeof subDistributorsPutUserByIdAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.SUBDISTRIBUTOR_USERS,
      Method.PUT,
      {
        bodyJSON: action.payload,
      }
    );
    if (!success || errorResponse) {
      yield put(subDistributorsPutUserByIdAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(subDistributorsGetAction.request());
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(subDistributorsPutUserByIdAction.success(response));
      yield put(push(Routes.SUB_DISTRIBUTORS_USERS));
    }
  } catch (err) {
    yield put(subDistributorsPutUserByIdAction.failure(err));
  }
}

function* subDistributorsDeleteActionSaga(
  action: ReturnType<typeof subDistributorsDeleteByIdAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.SUBDISTRIBUTOR,
      Method.DELETE,
      {
        param: action.payload,
      }
    );
    if (!success || errorResponse) {
      yield put(subDistributorsDeleteByIdAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(subDistributorsDeleteByIdAction.success(response));
    }
  } catch (err) {
    yield put(subDistributorsDeleteByIdAction.failure(err));
  }
}

function* subDistributorsGetUsersActionSaga(
  action: ReturnType<typeof subDistributorsGetUsersAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.SUBDISTRIBUTOR_USERS,
      Method.GET
    );

    if (!success || errorResponse) {
      yield put(subDistributorsGetUsersAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(subDistributorsGetUsersAction.success(response));
    }
  } catch (err) {
    yield put(subDistributorsGetUsersAction.failure(err));
  }
}

export function* watchSubDistributorsGetAction() {
  yield takeLatest(
    getType(subDistributorsGetAction.request),
    subDistributorsGetActionSaga
  );
}

export function* watchSubDistributorsRegisterPostAction() {
  yield takeLatest(
    getType(subDistributorsRegisterPostAction.request),
    subDistributorsRegisterPostActionSaga
  );
}

export function* watchSubDistributorsGetByIdAction() {
  yield takeLatest(
    getType(subDistributorsGetByIdAction.request),
    subDistributorsGetByIdActionSaga
  );
}

export function* watchSubDistributorsDeleteAction() {
  yield takeLatest(
    getType(subDistributorsDeleteByIdAction.request),
    subDistributorsDeleteActionSaga
  );
}

export function* watchSubDistributorsGetUsersAction() {
  yield takeLatest(
    getType(subDistributorsGetUsersAction.request),
    subDistributorsGetUsersActionSaga
  );
}

export function* watchSubDistributorsGetUsersByIdAction() {
  yield takeLatest(
    getType(subDistributorsGetUserByIdAction.request),
    subDistributorsGetUserByIdActionSaga
  );
}

export function* watchSubDistributorsDeleteUserByIdAction() {
  yield takeLatest(
    getType(subDistributorsDeleteUserByIdAction.request),
    subDistributorsDeleteUserByIdActionSaga
  );
}

export function* watchSubDistributorsPutUserByIdAction() {
  yield takeLatest(
    getType(subDistributorsPutUserByIdAction.request),
    subDistributorsPutUserByIdActionSaga
  );
}

export function* watchSubDistributorsRegisterUserPostAction() {
  yield takeLatest(
    getType(subDistributorsPostUserAction.request),
    subDistributorsRegisterUserPostActionSaga
  );
}

export function* watchSubDistributorsPutAction() {
  yield takeLatest(
    getType(subDistributorsPutAction.request),
    subDistributorsPutActionSaga
  );
}

export function* watchSubDistributorsReactivatePostAction() {
  yield takeLatest(
    getType(subDistributorsReactivePostAction.request),
    subDistributorsReactovatePostActionSaga
  );
}
