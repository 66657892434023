import * as React from 'react';
import styled from 'styled-components';
import { FieldInputProps } from 'formik';

export interface BasicInlineInputProps {
	formik?: any;
	onChange?: (
		_event: React.ChangeEvent<
			HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
		>
	) => void;
	onBlur?: (_event: React.FocusEvent<any>) => void;
	value?: string | number;
	name?: string;
	placeholder?: string;
	id?: string;
	title?: string;
	formikProps?: FieldInputProps<any>;
	multiline?: boolean;
	disabled?: boolean;
}

interface BaseInlineInputProps extends BasicInlineInputProps {
	type?: string;
}

export const BaseInlineInput = (props: BaseInlineInputProps) => {
	const handleChange = (e: any) => {
		props.formik.setFieldValue(props.name, e.target.value);
	};

	const getValue = () => {
		if (props.formik.values) {
			const split = props.name.split(".");
			let value = props.formik.values;
			split.forEach((i) => {
				if (value) {
					value = value[i];
				}
			});
			return value;
		}
		return "";
	};

	const value =
		props.formik && props.formik.values
			? props.name && props.name.includes(".")
				? getValue()
				: props.formik.values[props.name]
			: "";

	return (
		<Input
			id={props.id}
			name={props.name}
			type={props.type}
			disabled={props.disabled}
			onChange={handleChange}
			value={value}
			{...props}
		/>
	);
};

const Input = styled.input`
	width: 100%;
	min-width: 600px;
	max-width: 600px;
	padding: 1rem 2rem calc(1rem - 3px);

	border: 0;
	border-bottom: 3px solid transparent;
	border-radius: 3px;
	box-shadow: 0 3px 15px -5px rgba(0, 0, 0, 0.15);
	font-size: 1rem;

	transition: all 0.2s ease-out;

	&:active,
	&:hover,
	&:focus {
		box-shadow: 0 3px 15px -5px rgba(58, 77, 158, 0.5);
	}

	&:focus {
		border-bottom: 3px solid ${(props) => props.theme.colors.primary};
	}
`;
