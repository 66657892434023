import * as React from 'react';
import _ from 'lodash';
import {ContentRow} from 'constants/globalStyles';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {Input, StyledBox} from './CheckBox';

interface IOwnProps {
  checked?: boolean;
  label?: string | JSX.Element;
  name: any;
  formik: any;
  value: any;
  array?: boolean;
  children?: React.ReactNode;
  input?: boolean;
}

export const FormikCheckBox: React.FC<IOwnProps> = ({
                                                      array,
                                                      formik,
                                                      checked,
                                                      children,
                                                      label,
                                                      name,
                                                      input,
                                                      value,
                                                    }) => {

  const active =
      formik.values[name] && input
          ? formik.values[name] === value
          : formik.values[name]
          ? formik.values[name].includes(value)
          : false;

  const handleChange = (value: any) => (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (array) {
      const toRemove =
          formik.values[name] && formik.values[name].includes(value);
      const arr =
          toRemove && _.remove(formik.values[name], (val) => val !== value);
      toRemove
          ? formik.setFieldValue(name, arr)
          : formik.setFieldValue(name, [...formik.values[name], value]);
      return;
    }
    if (input) {
      formik.setFieldValue(name, !value);
      return;
    }
    if (!active) {
      formik.setFieldValue(name, value);
    } else {
      formik.setFieldValue(name, null);
    }

  };


  return (
      <div onClick={handleChange(value)}>
        <Input
            defaultChecked={!!checked}
            name={array ? name + '[]' : name}
            type='radio'
            id={value}
            value={value}
        />
        <ContentRow>
          <StyledBox checked={active}>
            {active && <FontAwesomeIcon icon={faCheck as IconProp}/>}
          </StyledBox>
          <label>{label}</label>
        </ContentRow>
        {children && children}
      </div>
  );
};
