import { Box } from 'components/Box';
import { ButtonType, MaterialButton } from 'components/Button/MaterialButton';
import { Content } from 'components/Content';
import FormikRow from 'components/Form/FormikRow';
import { Input } from 'constants/enum';
import { GridItem, GridRow } from 'constants/globalStyles';
import { Routes } from 'constants/routes';
import { Client, ClientProxy, CreateClient } from 'containers/Portal/Admin/Clients/ClientsTable/_types';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { lang } from 'translation/i18n';
import { getCountryCode } from 'utils/getCountryCodes';
import { lastPathMember, translationPath } from 'utils/getPath';
import * as Yup from 'yup';

export interface StateProps {
	pending: boolean;

	successPathRedirection: Routes;
	client: Client;
}

export interface DispatchProps {
	clientsPostAction: ( data: CreateClient ) => void;
	clientsPutAction: ( data: CreateClient ) => void;
	clientGetByIdAction: ( data: string ) => void;
}

export interface Params {
	id: string;
}

const Component: React.FC<StateProps & DispatchProps> = ( {
	pending,
	clientsPostAction,
	clientsPutAction,
	clientGetByIdAction,
	successPathRedirection,
	client,
} ) => {
	const { t } = useTranslation();
	const { id } = useParams<Params>();

	const initialValues: Client = client
		? client
		: {
			Name: "",
			LicenseValidityEnd: "01. 01. 2200",
			MaxStorage: 0,
			ClientLicenceNumber: "",
			AdminEmail: "",
			AdminCountryCode: "",
			AdminPhoneNumber: "",
			ClientCurrency: "Kč"
		};

	useEffect( () => {
		if ( !!id ) {
			clientGetByIdAction( id );
		}
	}, [id && clientGetByIdAction] );

	const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

	const formik = useFormik( {
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: Yup.object( {
			Name: Yup.string()
				.min( 1, t( translationPath( lang.validation.min ).path, { count: 1 } ) )
				.max( 200, t( translationPath( lang.validation.max ).path, { count: 200 } ) )
				.required( t( translationPath( lang.validation.required ).path ) ),
			AdminEmail: Yup.string()
				.email( t( translationPath( lang.validation.email ).path ) )
				.min( 1, t( translationPath( lang.validation.min ).path, { count: 1 } ) )
				.max( 200, t( translationPath( lang.validation.max ).path, { count: 200 } ) )
				.required( t( translationPath( lang.validation.required ).path ) ),
			AdminPhoneNumber: Yup.string().matches(
				phoneRegExp,
				t( translationPath( lang.validation.phone ).path )
			),
		} ),

		onSubmit: ( values: Client ) => {
			id
				? clientsPutAction( {
					client: values,
					successPathRedirection: successPathRedirection,
				} )
				: clientsPostAction( {
					client: values,
					successPathRedirection: successPathRedirection,
				} );
		},
	} );

	return (
		<Content
			title={t(
				translationPath(
					id ? lang.distributors.editClient : lang.distributors.newClient
				).path
			)}
		>
			<form onSubmit={formik.handleSubmit}>
				<Box
					title={t(
						translationPath(
							id ? lang.distributors.editClient : lang.distributors.newClient
						).path
					)}
					action={[
						<MaterialButton
							tooltip={t( translationPath( lang.button.saveItem ).path )}
							type={ButtonType.SAVE}
							loading={pending}
						/>,
					]}
				>
					<GridRow>
						<GridItem>
							<FormikRow
								formik={formik}
								name={lastPathMember( ClientProxy.Name ).path}
								title={t( translationPath( lang.input.name ).path )}
								titleWidth={30}
								type={Input.TEXT}
							/>
							<FormikRow
								formik={formik}
								name={lastPathMember( ClientProxy.ClientLicenceNumber ).path}
								title={t( translationPath( lang.input.clientLicenceNumber ).path )}
								titleWidth={30}
								type={Input.TEXT}
							/>
							<FormikRow
								formik={formik}
								name={lastPathMember( ClientProxy.LicenseValidityEnd ).path}
								title={t( translationPath( lang.table.licenseValidityEnd ).path )}
								titleWidth={30}
								type={Input.DATE}
								disabled={!!id}
								format={"DD. MM. YYYY"}
							/>
							<FormikRow
								formik={formik}
								name={lastPathMember( ClientProxy.MaxStorage ).path}
								title={t( translationPath( lang.table.maxStorage ).path )}
								titleWidth={30}
								type={Input.NUMERIC}
								disabled={!!id}
							/>
							<FormikRow
								formik={formik}
								name={lastPathMember( ClientProxy.ClientCurrency ).path}
								title={t( translationPath( lang.input.currency ).path )}
								titleWidth={30}
								type={Input.TEXT}
							/>
							<FormikRow
								formik={formik}
								title={t( translationPath( lang.input.phone ).path )}
								countryCode={lastPathMember( ClientProxy.AdminCountryCode ).path}
								phoneNumber={lastPathMember( ClientProxy.AdminPhoneNumber ).path}
								titleWidth={30}
								type={Input.PHONE}
								disabled={!!id}
								options={getCountryCode().map( ( value: any ) => {
									return { value: value, label: value };
								} )}
							/>
							<FormikRow
								formik={formik}
								name={lastPathMember( ClientProxy.AdminEmail ).path}
								title={t( translationPath( lang.input.email ).path )}
								titleWidth={30}
								type={Input.TEXT}
								disabled={!!id}
							/>
						</GridItem>
					</GridRow>
				</Box>
			</form>
		</Content>
	);
};

export default React.memo( Component );
