import * as Yup from 'yup';
import FormikRow from '../../../../components/Form/FormikRow';
import React, { useEffect } from 'react';
import { Box } from '../../../../components/Box';
import { Content } from 'components/Content';
import { ContentSpaceBetween, GridItem, GridRow } from 'constants/globalStyles';
import { getCountryCode } from 'utils/getCountryCodes';
import { Input } from '../../../../constants/enum';
import { lang } from 'translation/i18n';
import { lastPathMember, translationPath } from 'utils/getPath';
import { Row } from './_styles';
import { useFormik } from 'formik';
import { User, UserProxy } from '../../SuperAdmin/Users/UsersTable/_types';
import { useTranslation } from 'react-i18next';
import {
	ButtonType,
	MaterialButton,
} from "../../../../components/Button/MaterialButton";

export interface StateProps {
	pending: boolean;
	administrator: User;
}

export interface DispatchProps {
	administratorResetPasswordAction: (data: string) => void;
	administratorPutAction: (data: User) => void;
	administratorGetByTokenAction: (data: void) => void;
}

const Component: React.FC<StateProps & DispatchProps> = ({
	administratorResetPasswordAction,
	administratorGetByTokenAction,
	administratorPutAction,
	pending,
	administrator,
}) => {
	const { t } = useTranslation();

	const resetPassword = () => () => {
		administratorResetPasswordAction(administrator.Email);
	};

	const initialValues: User = administrator
		? administrator
		: {
				Email: "",
				Name: "",
				Surname: "",
				CountryCode: "",
				PhoneNumber: null,
		  };

	useEffect(() => {
		administratorGetByTokenAction();
	}, [administratorGetByTokenAction]);

	const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		onSubmit: (values: User) => {
			administratorPutAction(values);
		},
		validationSchema: Yup.object({
			Email: Yup.string()
				.email(t(translationPath(lang.validation.email).path))
				.min(1, t(translationPath(lang.validation.min).path, { count: 1 }))
				.max(200, t(translationPath(lang.validation.max).path, { count: 200 }))
				.required(t(translationPath(lang.validation.required).path)),
			Name: Yup.string()
				.min(1, t(translationPath(lang.validation.min).path, { count: 1 }))
				.max(200, t(translationPath(lang.validation.max).path, { count: 200 }))
				.required(t(translationPath(lang.validation.required).path)),
			Surname: Yup.string()
				.min(1, t(translationPath(lang.validation.min).path, { count: 1 }))
				.max(200, t(translationPath(lang.validation.max).path, { count: 200 }))
				.required(t(translationPath(lang.validation.required).path)),
			PhoneNumber: Yup.string().matches(
				phoneRegExp,
				t(translationPath(lang.validation.phone).path)
			),
		}),
	});

	return (
		<Content title={t(translationPath(lang.common.settings).path)}>
			<form onSubmit={formik.handleSubmit}>
				<Box
					title={t(translationPath(lang.administrator.editAdministrator).path)}
					action={[
						<MaterialButton
							tooltip={t(translationPath(lang.button.saveItem).path)}
							type={ButtonType.SAVE}
							loading={pending}
						/>,
					]}
				>
					<GridRow>
						<GridItem size={2}>
							<FormikRow
								formik={formik}
								name={lastPathMember(UserProxy.Name).path}
								title={t(translationPath(lang.input.forename).path)}
								titleWidth={30}
								type={Input.TEXT}
							/>{" "}
							<FormikRow
								formik={formik}
								name={lastPathMember(UserProxy.Surname).path}
								title={t(translationPath(lang.input.surname).path)}
								titleWidth={30}
								type={Input.TEXT}
							/>
							<FormikRow
								formik={formik}
								name={lastPathMember(UserProxy.Email).path}
								title={t(translationPath(lang.input.email).path)}
								titleWidth={30}
								type={Input.TEXT}
							/>
							<FormikRow
								formik={formik}
								title={t(translationPath(lang.input.phone).path)}
								titleWidth={30}
								countryCode={lastPathMember(UserProxy.CountryCode).path}
								phoneNumber={lastPathMember(UserProxy.PhoneNumber).path}
								type={Input.PHONE}
								options={getCountryCode().map((value: any) => {
									return { value: value, label: value };
								})}
							/>
						</GridItem>
					</GridRow>
				</Box>
			</form>
			<Box title={t(translationPath(lang.common.settings).path)}>
				<ContentSpaceBetween>
					<Row>
						{t(translationPath(lang.button.resetPassword).path)}
						<MaterialButton
							tooltip={t(translationPath(lang.button.resetPassword).path)}
							onClick={resetPassword()}
							type={ButtonType.LOAD}
							loading={pending}
						/>
					</Row>
				</ContentSpaceBetween>
			</Box>
		</Content>
	);
};

export default React.memo(Component);
