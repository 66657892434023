import * as React from "react";
import AdministratorDistributors from "containers/Portal/Admin/Distributors/Container";
import AdministratorSettings from "containers/Portal/Admin/Settings/Container";
import Clients from "containers/Portal/Admin/Clients/Container";
import CreateClient from "containers/Portal/Admin/Clients/Edit/Container";
import CreateDistributor from "containers/Portal/Admin/Distributors/Edit/Container";
import { ProtectedRoute } from "./ProtectedRoute";
import { Routes } from "../../constants/routes";
import { Switch } from "react-router-dom";

export const RouterListAdministrator = () => {
  return (
    <Switch>
      <ProtectedRoute
        exact={true}
        path={Routes.ADMINISTRATOR_DISTRIBUTORS}
        component={AdministratorDistributors}
      />
      <ProtectedRoute
        exact={true}
        path={Routes.ADMINISTRATOR_CLIENTS}
        component={Clients}
      />
      <ProtectedRoute
        exact={true}
        path={Routes.ADMINISTRATOR_SETTINGS}
        component={AdministratorSettings}
      />
      <ProtectedRoute
        exact={true}
        path={Routes.ADMINISTRATOR_DISTRIBUTORS_NEW}
        component={CreateDistributor}
      />
      <ProtectedRoute
        path={Routes.ADMINISTRATOR_DISTRIBUTORS_EDIT_ID}
        component={CreateDistributor}
      />
      <ProtectedRoute
        path={Routes.ADMINISTRATOR_DISTRIBUTORS_EDIT}
        component={CreateDistributor}
      />
      <ProtectedRoute
        exact={true}
        path={Routes.ADMINISTRATOR_PORTAL}
        component={AdministratorDistributors}
      />
      <ProtectedRoute
        exact={true}
        path={Routes.ADMINISTRATOR_PORTAL_CREATE_CLIENT}
        component={CreateClient}
      />
      <ProtectedRoute
        path={Routes.ADMINISTRATOR_PORTAL_CREATE_CLIENT_ID}
        component={CreateClient}
      />
    </Switch>
  );
};
