import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { DateFormat } from '../../constants/enum';

interface LicenseValidityProps {
	licenseValidityEnd: string;
	licenseValid: boolean;
}

export const LicenseValidity: React.FC<LicenseValidityProps> = ({
	licenseValidityEnd,
	licenseValid,
}) => {
	const licenseValidityEndMoment = moment(licenseValidityEnd).format(
		DateFormat.DATE_CZ
	);
	return <Content valid={licenseValid}>{licenseValidityEndMoment}</Content>;
};

export const Content = styled.div<{ valid: boolean }>`
	font-weight: 600;
	color: ${(props) => (props.valid ? "#17785e" : "rgb(220, 0, 78)")};
`;
