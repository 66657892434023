import React from 'react';
import { Content, FullHeight } from './_styles';
import { GlobalNotification } from '../../../components/Toast/_types';
import { Header } from './components/Header';
import { RouterListAdmin } from '../../../components/Routes/RouterListAdmin';
import { useToasts } from 'react-toast-notifications';

export interface StateProps {
	toast: GlobalNotification;
}

export interface DispatchProps {
	clearToast: () => void;
	oauthSuperAdminLogoutAction: () => void;
}

const Component: React.FC<StateProps & DispatchProps> = ({
	toast,
	clearToast,
	oauthSuperAdminLogoutAction,
}) => {
	const { addToast } = useToasts();

	React.useEffect(() => {
		if (toast) {
			addToast(toast.message, {
				appearance: toast.code,
				autoDismiss: true,
			});
			clearToast();
		}
	}, [toast, addToast, clearToast]);

	return (
		<Content>
			<Header oauthSuperAdminLogoutAction={oauthSuperAdminLogoutAction} />
			<FullHeight>
				<RouterListAdmin />
			</FullHeight>
		</Content>
	);
};

export default Component;
