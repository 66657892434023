import { Client, CreateClient, Storage, Validity } from "./_types";
import { Error } from "sagas/_sagas";
import {
  ActionType,
  createAsyncAction,
  createStandardAction,
} from "typesafe-actions";

export const clientsInit = createStandardAction("CLIENTS_INIT")();

export const clientsGetAction = createAsyncAction(
  "CLIENTS_GET_REQUEST",
  "CLIENTS_GET_SUCCESS",
  "CLIENTS_GET_FAILURE"
)<void, Client[], Error>();

export const clientsPostAction = createAsyncAction(
  "CLIENTS_POST_REQUEST",
  "CLIENTS_POST_SUCCESS",
  "CLIENTS_POST_FAILURE"
)<CreateClient, Client, Error>();

export const clientsPutAction = createAsyncAction(
  "CLIENTS_PUT_REQUEST",
  "CLIENTS_PUT_SUCCESS",
  "CLIENTS_PUT_FAILURE"
)<CreateClient, Client, Error>();

export const clientsPostStorageAction = createAsyncAction(
  "CLIENTS_POST_STORAGE_REQUEST",
  "CLIENTS_POST_STORAGE_SUCCESS",
  "CLIENTS_POST_STORAGE_FAILURE"
)<Storage, Client[], Error>();

export const clientsPostValidityAction = createAsyncAction(
  "CLIENTS_POST_VALIDITY_REQUEST",
  "CLIENTS_POST_VALIDITY_SUCCESS",
  "CLIENTS_POST_VALIDITY_FAILURE"
)<Validity, Client[], Error>();

export const clientsGetByIdAction = createAsyncAction(
  "CLIENTS_GET_BY_ID_REQUEST",
  "CLIENTS_GET_BY_ID_SUCCESS",
  "CLIENTS_GET_BY_ID_FAILURE"
)<string, Client, Error>();

export const clientsDeleteByIdAction = createAsyncAction(
  "CLIENTS_DELETE_REQUEST",
  "CLIENTS_DELETE_SUCCESS",
  "CLIENTS_DELETE_FAILURE"
)<string, void, Error>();

export const clientsReactivate = createAsyncAction(
  "CLIENTS_REACTIVATE_POST_REQUEST",
  "CLIENTS_REACTIVATE_POST_SUCCESS",
  "CLIENTS_REACTIVATE_POST_FAILURE"
)<string, Client[], Error>();

export type ClientsActionType = ActionType<
  | typeof clientsGetAction
  | typeof clientsPostAction
  | typeof clientsPutAction
  | typeof clientsPostStorageAction
  | typeof clientsPostValidityAction
  | typeof clientsGetByIdAction
  | typeof clientsDeleteByIdAction
  | typeof clientsReactivate
  | typeof clientsInit
>;
