import Component, { StateProps } from "./Component";
import { Auth } from "types/_types";
import { clearNotificationAction } from "components/Toast/_actions";
import { compose, Dispatch } from "redux";
import { connect } from "react-redux";
import { oauthAdminAction, resetAdminAction } from "./_actions";
import { RootStateType } from "reducers";
import { withRouter } from "react-router";

const mapDispatchToProps = (dispatch: Dispatch) => ({
  login: (data: Auth) => dispatch(oauthAdminAction.request(data)),
  reset: (data: string) => dispatch(resetAdminAction.request(data)),
  clearToast: () => dispatch(clearNotificationAction()),
});

const mapStateToProps = (state: RootStateType): StateProps => ({
  pending: state.AuthenticationAdminReducer.pending,
  toast: state.ToastReducer.notification,
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
