import { Error } from "sagas/_sagas";
import { ActionType, createAsyncAction } from "typesafe-actions";
import { ResetPassword } from "./_types";

export const resetPasswordAction = createAsyncAction(
  "SUPER_ADMIN_RESET_REQUEST",
  "SUPER_ADMIN_RESET_SUCCESS",
  "SUPER_ADMIN_RESET_FAILURE"
)<ResetPassword, void, Error>();

export type SuperAdminResetPasswordActionType = ActionType<
  typeof resetPasswordAction
>;
