import Component, { DispatchProps, StateProps } from './Component';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootStateType } from 'reducers';
import { User } from '../UsersTable/_types';
import { withRouter } from 'react-router';
import {
	subDistributorsGetUserByIdAction,
	subDistributorsPutUserByIdAction,
	subDistributorsPostUserAction,
} from "../../_actions";

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	userPostAction: (data: User) =>
		dispatch(subDistributorsPostUserAction.request(data)),
	userPutAction: (data: User) =>
		dispatch(subDistributorsPutUserByIdAction.request(data)),
	userGetByIdAction: (data: string) =>
		dispatch(subDistributorsGetUserByIdAction.request(data)),
});

const mapStateToProps = (state: RootStateType): StateProps => ({
	pending: state.SubDistributorsReducer.pending,
	user: state.SubDistributorsReducer.user,
	routerState: state.router.location.state,
});

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(Component);
