import * as React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import styled from 'styled-components';
import { TextInput } from './TextInput';

interface DateInputProps {
	value: Date;
	onChange: (
		name: string
	) => (
		date: Date | null,
		event: React.SyntheticEvent<any> | undefined
	) => void;
	format: string;
	disabled?: boolean;
	name?: string;
	formik?: any;
}

const DateInput = (props: DateInputProps) => {
	let calendarRef = React.createRef<DatePicker>();

	const onChange = (
		date: Date | null,
		event: React.SyntheticEvent<any> | undefined
	) => {
		console.log(date);
		props.formik.setFieldValue(props.name, date.toDateString());
	};
	return (
		<Wrapper>
			<DatePicker
				ref={calendarRef}
				name={props.name}
				disabled={props.disabled}
				customInput={
					<div onClick={() => calendarRef.current.setOpen(true)}>
						<TextInput
							name={props.name}
							value={
								props.formik && props.formik.values[props.name] && props.format
									? moment(props.formik.values[props.name]).format(props.format)
									: ""
							}
							disabled
						/>
					</div>
				}
				onChange={onChange}
				dateFormat={props.format}
			/>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;

	> *:first-child {
		flex-grow: 1;
	}

	.svg-inline--fa {
		font-size: 1.15em !important;
		margin: 0 -2px 0 -1px;
	}

	.react-datepicker {
		border: 0;
		box-shadow: ${(props) => props.theme.boxShadow};
	}

	.react-datepicker__header {
		border: 0;
	}

	.react-datepicker-popper {
		z-index: 99;
	}

	.react-datepicker__triangle {
		right: 9px !important;

		&:before {
			border: 1px solid #d8d8d8;
		}
	}

	.react-datepicker__day--selected {
		background-color: ${(props) => props.theme.colors.primary} !important;
	}

	.react-datepicker__day--keyboard-selected,
	.react-datepicker__month-text--keyboard-selected,
	.react-datepicker__quarter-text--keyboard-selected {
		background-color: rgba(23, 120, 94, 0.15);
		color: black;
	}

	.react-datepicker__day--keyboard-selected:hover,
	.react-datepicker__month-text--keyboard-selected:hover,
	.react-datepicker__quarter-text--keyboard-selected:hover {
		background-color: rgba(23, 120, 94, 0.25);
	}

	.react-datepicker__day--outside-month {
		color: rgba(0, 0, 0, 0.25);
	}
`;

export default DateInput;
