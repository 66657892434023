import FilterList from "@material-ui/icons/FilterList";
import Paper from "@material-ui/core/Paper";
import styled, { keyframes } from "styled-components";
import { device } from "./theme";
import { Link } from "react-router-dom";

export const PageWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
`;

/**
 * TODO responsiveness
 * TODO documentation
 */

export const ContentWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
`;

/**
 * Content Card
 * TODO documentation
 */
export enum Direction {
  ROW = "row",
  COLUMN = "column",
}

export enum Justify {
  CENTER = "center",
  START = "flex-start",
  END = "flex-end",
  SPACE_BETWEEN = "space-between",
}

export enum Align {
  CENTER = "center",
  START = "flex-start",
  END = "flex-end",
  STRETCH = "stretch",
}

export const ContentCard = styled.div<{
  align?: Align;
  direction?: Direction;
  fullSize?: boolean;
  fullWidth?: boolean;
  justify?: Justify;
  padding?: number;
}>`
  display: flex;
  justify-content: ${(props) =>
    props.justify ? props.justify : Justify.START};
  flex-direction: ${(props) =>
    props.direction ? props.direction : Direction.ROW};
  align-items: ${(props) => (props.align ? props.align : Align.CENTER)};
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  height: ${(props) => (props.fullSize ? "100%" : "auto")};
  padding: ${(props) => (props.padding ? props.padding + "px 0" : "0")};
  box-sizing: border-box;
`;

export const Paragraph = styled.p<{
  align?: Align;
  direction?: Direction;
  fullSize?: boolean;
  justify?: Justify;
}>`
  justify-content: ${(props) =>
    props.justify ? props.justify : Justify.START};
  flex-direction: ${(props) =>
    props.direction ? props.direction : Direction.ROW};
  align-items: ${(props) => (props.align ? props.align : Align.START)};

  margin: 0 0 1rem;

  color: ${(props) => props.theme.colors.secondaryText.active};

  line-height: 1.45;
  font-size: 15px;
`;
//OLD TOTO REFACTOR  RP

export const PhoneTitle = styled.label<{
  variableWidth?: boolean;
  titleUp?: boolean;
}>`
  width: ${(props) => (props.variableWidth ? "auto" : "55%")};
  padding: ${(props) => (props.titleUp ? "0.6rem 0" : "1rem 2rem")};
  color: #a7a7a7;
`;

export const Card = styled.div`
  padding: 1rem 2rem;

  border-radius: 2px;
`;

/* GRID */
export const GridRow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  color: ${(props) => props.theme.colors.contentText};

  & + & {
    margin-top: 0;
  }

  @media ${device.large} {
    flex-wrap: wrap;
  }
`;

export const SGridRow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  flex-wrap: wrap;
  color: ${(props) => props.theme.colors.contentText};

  & + & {
    margin-top: 0;
  }

  @media ${device.large} {
    flex-wrap: wrap;
  }
`;

export const GridItem = styled.div<{ size?: number; padding?: boolean }>`
  flex: ${(props) => (props.size || props.size === 0 ? props.size : 1)} 0 0;
  max-width: 100%;
`;

export const SectionWrapper = styled(PageWrapper)`
  margin-top: 2rem;

  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
`;

/* TYPOGRAPHY */
export const Header1 = styled.h1`
  box-sizing: border-box;

  margin: 0 0 1.5rem 0;

  color: ${(props) => props.theme.colors.primaryText.h1};
  font-size: 2rem;
  font-weight: 700;
`;

export const Header2 = styled.h2`
  box-sizing: border-box;

  margin: 0 0 1.5rem 0;
  padding: 0 2rem 1rem;

  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  color: ${(props) => props.theme.colors.primaryText.h2};
  font-size: 1.5rem;
  font-weight: 300;
  text-align: center;
`;

export const Header3 = styled.h3`
  box-sizing: border-box;

  margin: 0 0 0.8em 0;

  color: ${(props) => props.theme.colors.primaryText.h3};
  font-size: 1.3em;
  letter-spacing: 0.4px;
  font-weight: 300;
`;

export const Header4 = styled.h4`
  box-sizing: border-box;

  margin: 0 0 0.25em 0;

  color: #444;
  font-size: 1.125em;
  letter-spacing: 0.4px;
  font-weight: 300;
`;

export const Header5 = styled.h5`
  color: ${(props) => props.theme.colors.primaryText.h2};
  font-size: 1.2em;
  box-sizing: border-box;
  margin: 1em 0;
  font-weight: 600;
`;

export const Header6 = styled.h6`
  color: ${(props) => props.theme.colors.secondaryText.active};
  font-size: 0.9em;
  box-sizing: border-box;
  font-family: sans-serif;
  margin: 1.5em 0;
`;

export const ContentPage = styled(PageWrapper)`
  margin: 2rem auto;
  padding: 2rem;

  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.2);

  h1 {
    box-sizing: border-box;

    margin: 0 0 1.5rem 0;

    color: ${(props) => props.theme.colors.primaryText.h1};
    font-size: 2rem;
    font-weight: 700;
  }

  h2,
  ${Header2} {
    box-sizing: border-box;

    margin: 1rem 0 1rem 0;
    padding: 0;

    border: 0;
    color: ${(props) => props.theme.colors.primaryText.h2};
    font-size: 1.5rem;
    font-weight: 300;
    text-align: left;

    &.float {
      display: inline-block;

      margin: 1rem 0.5rem 0 0;

      & + * {
        padding-top: 5px;
        margin-top: 1rem;
      }
    }
  }

  h3 {
    box-sizing: border-box;

    margin: 0 0 0.8em 0;

    color: ${(props) => props.theme.colors.primaryText.h3};
    font-size: 1.3em;
    letter-spacing: 0.4px;
    font-weight: 300;
  }
`;

export const Bold = styled.span`
  color: ${(props) => props.theme.colors.secondaryText.active};
  font-weight: bold;
  line-height: 1.45;
  padding-right: 7px;
`;

export const PaddedText = styled.span`
  padding-right: 7px;
`;

export const Value = styled.div`
  line-height: 1.45;
  color: ${(props) => props.theme.colors.secondaryText.active};
`;

export const Hr = styled.hr`
  border: 0;
  border-top: 1px solid #eee;
  margin: 1em 0;
  width: 100%;
`;

export const GridRowFillContent = styled(GridRow)`
  height: calc(100% - 48px);
`;

export const GridItemHalfHeight = styled(GridItem)`
  height: 50%;
`;

const ButtonStyles = (props) => `
  padding: 0.5rem 1rem;
  margin: 0;

  background-color: ${
    !props.secondary ? props.theme.colors.background.button.red : "#CCC"
  };
  color: ${props.theme.colors.text.white};
  border: none;
  border-radius: 3px;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;

  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    background: ${
      !props.secondary ? props.theme.colors.background.button.darkRed : "#AAA"
    };
  }
`;

export const Button = styled.a<{ secondary?: boolean }>`
  ${(props) => ButtonStyles(props)}

  & + & {
    margin-left: 1rem;
  }
`;

export const SubmitButton = styled.input<{ secondary?: boolean }>`
  ${(props) => ButtonStyles(props)}

  & + &,
  & + ${Button}, ${Button} + & {
    margin-left: 1rem;
  }
`;

export const Image = styled.img`
  margin: 0.3em;
`;

export const ButtonsRow = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 8px;
`;

export const Fixed = styled.div`
  background-color: ${(props) => props.theme.colors.background.content};
  border-top: 2px solid ${(props) => props.theme.colors.background.menu};

  ${ButtonsRow} button {
    width: 50%;
  }
`;

/*export const MainContent = styled.div`
  position: relative;

  background-color: #f8f8f8;
  box-shadow: 0 -20px 40px -20px rgba(0, 0, 0, 0.5),
    0 -10px 20px -10px rgba(0, 0, 0, 0.5);
`;*/

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CardMiddleTableWrapper = styled.div`
  flex-grow: 1;
  margin: 0 -16px -8px -16px;
  border-radius: 0 0 3px 3px;
`;

export const CardEndTableWrapper = styled(CardMiddleTableWrapper)`
  table tr {
    box-shadow: none !important;
  }
`;

export const FullCardEndTableWrapper = styled(CardEndTableWrapper)`
  height: calc(100% - 28px);
`;

/* FORMS */
export const Form = styled.form`
  width: 100%;
`;

/* NAV */
export const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 4px;
  padding-bottom: 0;
`;

export const NavItems = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`;

export const Href = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
`;

export const LinkPlain = styled(Link)`
  color: ${(props) => props.theme.colors.primaryText.h1};
`;

export const NavLink = styled(Link)`
  position: relative;
  display: block;
  border-radius: 3px 3px 0 0;
  color: ${(props) => props.theme.colors.background.content};
  margin: 1px;
  margin-bottom: 0;
  padding: 8px 24px;
  text-decoration: none;
  &:hover {
    background-color: ${(props) => props.theme.colors.background.secondaryMenu};
    color: black;
  }

  ${(props: any) =>
    props.active &&
    `
    color: black;
    background-color: ${props.theme.colors.background.menu};

    &:hover {
      background-color: ${props.theme.colors.background.secondaryMenu};
    }
  `}
`;

export const ButtonLink = styled(Link)`
  margin: 0;
  padding: 0.5em 1rem;
  margin: 0em 0.3em;
  font-size: 1.2rem;

  background-color: ${(props) => props.theme.colors.background.button.red};
  color: ${(props) => props.theme.colors.text.white};

  text-align: center;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: ${(props) => props.theme.colors.background.button.darkRed};
  }
`;

/* OLD STYLES */

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const SpaceBetween = styled.div`
  padding: 5px;
`;

export const Main = styled.div`
  position: relative;

  min-height: 100%;

  background-color: ${(props) =>
    props.theme.colors.background.contentSecondary};
  padding: 0;
`;

export const Content = styled.div`
  height: ${(props: any) => (props.fullSize ? "100%" : "auto")};
`;

export const ContentFilter = styled.div`
  padding: 16px;
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const ContentFillParent = styled(Content)`
  position: absolute;

  height: calc(100% - 40px);
  width: 100%;
`;

export const ContentInline = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const ContentSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;

  width: 100%;
`;

export const ContentEndCenter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
`;

export const ContentSpaceBetweenCenter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const ButtonsSpaceBetweenRow = styled(ContentSpaceBetween)`
  margin-top: 16px;
  padding: 8px;
`;

export const ContentSpaceBetweenWithPadding = styled(ContentSpaceBetween)`
  padding-right: ${(props) => props.theme.padding};
`;

export const Title = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  margin: 20px 16px 10px;

  &:first-child {
    margin: 5px 16px 10px;
  }
`;

export const TextArea = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  height: 250px;
  border: 1px solid ${(props) => props.theme.colors.forms.border};
`;

export const TextAreaFull = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  border: 1px solid ${(props) => props.theme.colors.forms.border};
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid ${(props) => props.theme.colors.forms.border};
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const ContentRowStart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ContentCenterRow = styled.div`
  width: 100%;
`;

export const RowEnd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const ContentRowEnd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const FilterColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContentColumnMargin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: ${(props) => props.theme.margin};
`;

export const ContentColumnCheckboxMargin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: ${(props) => props.theme.margin} 0;
`;

export const Column = styled.div`
  width: 45%;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
`;

export const Name = styled.div`
  color: ${(props) => props.theme.colors.secondaryText.default};
  padding: 7px 25px;
  width: 60%;
`;

export const Item = styled(Paper)`
  width: auto;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.forms.border};
  margin: ${(props) => props.theme.margin};

  ${(props: any) =>
    props.sameWidth &&
    `
          flex: 1 1 0px
         `}
`;

export const Start = styled.div`
  width: ${(props: any) => 100 / props.box}%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px;
  color: ${(props) => props.theme.colors.contentText};
`;

export const End = styled(Center)`
  display: flex;
  justify-content: flex-end;
`;

export const Space = styled.div`
  width: 10px;
  height: 10px;
`;

export const ContentSpace = styled(ContentInline)`
  padding: ${(props) => props.theme.padding};
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const InlineTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  color: black;
  border-bottom: 1px solid ${(props) => props.theme.colors.forms.border};
  &:hover {
    background-color: ${(props) => props.theme.colors.forms.labelBorder};
  }
`;

export const BoxTree = styled.div`
  display: inline-block;
  height: 0;
  background-color: ${(props: any) =>
    props.status === 0
      ? "lightgray"
      : props.status === 1
      ? props.theme.colors.status[1]
      : props.theme.colors.status[2]};
  border-radius: 10px;
  color: white;
  font-size: 0.8rem;
  font-weight: 400;
  padding: 6px;
  margin-left: 8px;
  height: 10px;
  width: 10px;
`;

export const Td = styled(Center)`
  display: ${(props: any) => (props.active ? "block" : "none")};
  width: ${(props: any) => 100 / props.box}%;
`;

export const Cursor = styled(Center)`
  cursor: pointer;
`;

export const FilterIcon = styled(FilterList)`
  margin: ${(props) => props.theme.margin};
`;

/*TABLE*/
export const TableInline = styled(ContentInline)`
  width: 100%;
  padding: 1rem 0;
`;

export const TableHead = styled(TableInline)`
  border-bottom: 1px solid ${(props) => props.theme.colors.forms.border};
`;

export const TableRow = styled(ContentColumn)`
  width: 100%;
  margin: 1rem 0rem;
`;

export const FullWidth = styled.div`
  width: 100%;
`;

export const Body = styled.tbody`
  height: 100%;
  width: 100%;
  vertical-align: top;
`;

export const Tr = styled.tr`
  height: 100%;
`;

export const CursorPinter = styled.div`
  cursor: pointer;
`;

const shine = keyframes`
  0%{
    transform: translate3d(-100%, 0, 0);
  }
  100%{
    transform: translate3d(100%, 0, 0);
  } `;

export const Sceleton = styled.div`
  position: relative;
  overflow: hidden;
  height: 200px;
  background: #ccc;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #ccc, #eaeaea, #ccc);
    animation: ${shine} 1s ease-in-out infinite;
  }
`;

export const Wrapper = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const ButtonCenter = styled(Center)`
  margin: 10px 0;
`;

export const NavButton = styled(ContentRow)`
  position: absolute;
  top: 52px;
  right: 30px;

  border-radius: 3px 3px 0 0;
  color: #cacaca;
  margin: 1px;
  margin-bottom: 0;
  text-decoration: none;
  cursor: pointer;

  ${(props: any) =>
    props.active &&
    `
    background-color: ${props.theme.colors.background.secondaryMenu};
    -webkit-box-shadow: inset 0px 30px 15px -15px rgba(255,255,255,1);
    box-shadow: inset 0px 30px 15px -15px rgba(255,255,255,1);

    &:hover {
      background-color: ${props.theme.colors.background.secondaryMenu};
    }
  `}
`;
