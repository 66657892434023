import * as Yup from 'yup';
import FormikRow from '../../../components/Form/FormikRow';
import queryString from 'query-string';
import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import {
	ContentCard,
	GridItem,
	GridRow,
	Justify,
	PageWrapper
	} from 'constants/globalStyles';
import { GlobalNotification } from 'components/Toast/_types';
import { Input } from '../../../constants/enum';
import { lang } from 'translation/i18n';
import { ResetPassword } from './_types';
import { translationPath } from 'utils/getPath';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
export interface StateProps {
	pending: boolean;
	toast: GlobalNotification;
}

export interface DispatchProps {
	resetPasswordAction: (data: ResetPassword) => void;
	clearToast: () => void;
}

const Component: React.FC<StateProps & DispatchProps> = ({
	pending,
	resetPasswordAction,
	toast, clearToast
}) => {
	const { t } = useTranslation();
	let location = useLocation();

	const {addToast} = useToasts();

  React.useEffect(() => {
    if (toast) {
      addToast(toast.message, {
        appearance: toast.code,
        autoDismiss: true,
      });
      clearToast();
    }
  }, [toast, addToast, clearToast]);

	const initialValues: ResetPassword = {
		newPassword: "",
		validationPassword: "",
	};

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: Yup.object({
			newPassword: Yup.string().required(
				t(translationPath(lang.validation.required).path)
			),
			validationPassword: Yup.string().oneOf(
				[Yup.ref("newPassword"), null],
				t(translationPath(lang.validation.passwordMatch).path)
			),
		}),
		onSubmit: (value: ResetPassword) => {
			const parsed = queryString.parse(location.search);
			resetPasswordAction({
				...value,
				secretCode: decodeURIComponent(parsed.secretCode as string),
				userId: decodeURIComponent(parsed.userId as string),
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<PageWrapper>
				<GridRow>
					<GridItem>
						<Wrapper>
							<Header>
								{t(translationPath(lang.common.resetPassword).path)}
							</Header>
							<FormWrapper>
								<FormikRow
									formik={formik}
									name="newPassword"
									title={t(translationPath(lang.input.newPassword).path)}
									type={Input.PASSWORD}
									titleUp
								/>
								<FormikRow
									formik={formik}
									name="validationPassword"
									title={t(translationPath(lang.input.validationPassword).path)}
									type={Input.PASSWORD}
									titleUp
								/>
								<ContentWrapper justify={Justify.END}>
									<ButtonOAuth type="submit" loading={pending}>
										{t(translationPath(lang.common.resetPasswordSubmit).path)}
									</ButtonOAuth>
								</ContentWrapper>
							</FormWrapper>
						</Wrapper>
					</GridItem>
				</GridRow>
			</PageWrapper>
		</form>
	);
};

export default React.memo(Component);

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	height: auto;
	min-height: 500px;
	width: 679px;

	margin: 8em auto;
	border-radius: 8px;
	background-color: #fff;
	box-shadow: 0 80px 99px rgba(0, 0, 0, 0.08);
`;

const Header = styled.h3`
	color: ${(props) => props.theme.colors.primaryText.default};

	margin-top: 2em;
	font-size: 1.4em;
	font-weight: 500;
`;

const ButtonOAuth = styled(Button)`
	background-color: ${(props) => props.theme.colors.primary};

	&:hover {
		background: #177864;
		-webkit-box-shadow: 0 1px 1px 0 rgba(0, 94, 44, 0.45),
			0 1px 3px 1px rgba(0, 94, 44, 0.3);
		box-shadow: 0 1px 1px 0 rgba(33, 94, 13, 0.45),
			0 1px 3px 1px rgba(0, 94, 44, 0.3);
	}
`;

const ContentWrapper = styled(ContentCard)`
	margin-top: 3em;
	width: 100%;
`;

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;

	padding: 0px 40px 36px;

	width: 100%;

	margin: 2em 0px;
`;
