import Component, { DispatchProps, StateProps } from './Component';
import { clearNotificationAction } from 'components/Toast/_actions';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ResetPassword } from './_types';
import { resetPasswordAction } from './_actions';
import { RootStateType } from '../../../reducers';
import { withRouter } from 'react-router';

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	resetPasswordAction: (data: ResetPassword) =>
		dispatch(resetPasswordAction.request(data)),
		clearToast: () => dispatch(clearNotificationAction()),
});

const mapStateToProps = (state: RootStateType): StateProps => ({
	pending: state.ResetPasswordReducer.pending,
	toast: state.ToastReducer.notification
});

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(Component);
