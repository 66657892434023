import Cookies from 'universal-cookie';
import { AUTH_SUPER_ADMIN } from 'constants/api';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchSaga } from 'sagas/_sagas';
import { getType } from 'typesafe-actions';
import { lang, t } from 'translation/i18n';
import { notificationAction } from 'components/Toast/_actions';
import { oauthSuperAdminAction } from './_actions';
import { push } from 'connected-react-router';
import { Routes } from 'constants/routes';
import { Status } from 'components/Toast/_types';
import { translationPath } from 'utils/getPath';

function* tokenSaga(
	action: ReturnType<typeof oauthSuperAdminAction.request>
): Generator {
	try {
		// @ts-ignore
		const { errorResponse, response, success, statusText } = yield call(
			fetchSaga,
			AUTH_SUPER_ADMIN,
			"POST",
			{
				bodyJSON: action.payload,
			}
		);

		if (!!(!success || errorResponse)) {
			yield put(oauthSuperAdminAction.failure(errorResponse));
			yield put(
				notificationAction({
					code: Status.ERROR,
					message: t(translationPath(lang.common[statusText])),
				})
			);
			return;
		}
		if (success && response) {
			const cookies = new Cookies();
			cookies.set("oauth_token", response, { path: "/" });
			yield put(oauthSuperAdminAction.success(response as string));
			yield put(push(Routes.ADMINISTRATOR_PORTAL));
		}
	} catch (err) {
		yield put(oauthSuperAdminAction.failure(err));
	}
}

export function* watchAuthenticationSuperAdmin() {
	yield takeLatest(getType(oauthSuperAdminAction.request), tokenSaga);
}
