import Component, { DispatchProps, StateProps } from './Component';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootStateType } from 'reducers';
import { User } from '../../SuperAdmin/Users/UsersTable/_types';
import { withRouter } from 'react-router';
import {
	administratorGetByTokenAction,
	administratorPutAction,
	administratorResetPasswordAction,
} from "./_actions";

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	administratorResetPasswordAction: (data: string) =>
		dispatch(administratorResetPasswordAction.request(data)),
	administratorGetByTokenAction: (data: void) =>
		dispatch(administratorGetByTokenAction.request(data)),
	administratorPutAction: (data: User) =>
		dispatch(administratorPutAction.request(data)),
});

const mapStateToProps = (state: RootStateType): StateProps => ({
	pending: state.AdministratorSettingsReducer.pending,
	administrator: state.AdministratorSettingsReducer.administrator,
});

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(Component);
