import { Auth } from 'types/_types';
import { Error } from '../../../sagas/_sagas';
import {
	ActionType,
	createAsyncAction,
	createStandardAction,
} from "typesafe-actions";

export const authDistributorAction = createAsyncAction(
	"AUTH_DISTRIBUTOR_REQUEST",
	"AUTH_DISTRIBUTOR_SUCCESS",
	"AUTH_DISTRIBUTOR_FAILURE"
)<Auth, string, Error>();

export const authDistributorLogoutAction = createStandardAction(
	"OAUTH_DISTRIBUTOR_LOGOUT"
)();

export type AuthenticationDistributorActionType = ActionType<
	typeof authDistributorAction | typeof authDistributorLogoutAction
>;
