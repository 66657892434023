import {ActionType, createStandardAction} from "typesafe-actions";
import {GlobalNotification} from "./_types";

export const clearNotificationAction = createStandardAction(
    "@common/CLEAR_NOTIFICATION"
)();

export const notificationAction = createStandardAction(
    "@common/SET_NOTIFICATION"
)<GlobalNotification>();

export type NotificationActionType = ActionType<typeof clearNotificationAction | typeof notificationAction>;
