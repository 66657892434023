import { ResetPassword } from "./_types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { RootStateType } from "reducers";
import { compose, Dispatch } from "redux";
import Component, { DispatchProps, StateProps } from "./Component";
import { resetPasswordAction } from "./_actions";

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  resetPasswordAction: (data: ResetPassword) =>
    dispatch(resetPasswordAction.request(data)),
});

const mapStateToProps = (state: RootStateType): StateProps => ({
  pending: state.SettingsReducer.pending,
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
