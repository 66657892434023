import Cookies from 'universal-cookie';
import { Authentication } from './_types';
import { getType } from 'typesafe-actions';
import {
	AuthenticationActionType,
	authAdministratorAction,
	authSubDistributorAction,
	oauthSubDistributorogoutAction,
} from "./_actions";

const initialState: Authentication = {
	access_token: null,
	expires_in: null,
	token_type: null,
	pending: false,
	error: null,
};

export const AuthenticationReducer = (
	state: Authentication = initialState,
	action: AuthenticationActionType
): Authentication => {
	switch (action.type) {
		case getType(authSubDistributorAction.request):
		case getType(authAdministratorAction.request):
			return {
				...initialState,
				pending: true,
			};
		case getType(authSubDistributorAction.success):
		case getType(authAdministratorAction.success):
			return {
				...initialState,
				access_token: action.payload,
				pending: false,
			};
		case getType(authSubDistributorAction.failure):
		case getType(authAdministratorAction.failure):
			return {
				...state,
				pending: false,
			};
		case getType(oauthSubDistributorogoutAction):
			const cookies = new Cookies();
			cookies.remove("oauth_token");
			return {
				...initialState,
			};
		default:
			return state;
	}
};
