import Component, { DispatchProps, StateProps } from './Component';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { distributorDeleteByIdAction } from 'containers/Portal/Admin/Distributors/UsersTable/_actions';
import { RootStateType } from 'reducers';
import { Storage, Validity } from '../../Admin/Clients/ClientsTable/_types';
import { withRouter } from 'react-router';
import {
	subDistributorsGetAction,
	subDistributorsDeleteByIdAction,
	subDistributorsReactivePostAction,
} from "containers/Portal/SubDistributor/_actions";
import {
	clientsDeleteByIdAction,
	clientsGetAction,
	clientsPostStorageAction,
	clientsPostValidityAction,
	clientsReactivate,
} from "containers/Portal/Admin/Clients/ClientsTable/_actions";

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	clientsGetAction: (data: void) => dispatch(clientsGetAction.request(data)),
	subDistributorsGetAction: (data: void) =>
		dispatch(subDistributorsGetAction.request(data)),
	clientsPostStorageAction: (data: Storage) =>
		dispatch(clientsPostStorageAction.request(data)),
	clientsPostValidityAction: (data: Validity) =>
		dispatch(clientsPostValidityAction.request(data)),
	clientsDeleteByIdAction: (data: string) =>
		dispatch(clientsDeleteByIdAction.request(data)),
	distributorDeleteByIdAction: (data: string) =>
		dispatch(distributorDeleteByIdAction.request(data)),
	subDistributorsDeleteByIdAction: (data: string) =>
		dispatch(subDistributorsDeleteByIdAction.request(data)),
	clientsReactivate: (data: string) =>
		dispatch(clientsReactivate.request(data)),
	distributorReactivatePostAction: (data: string) =>
		dispatch(subDistributorsReactivePostAction.request(data)),
});

const mapStateToProps = (state: RootStateType): StateProps => ({
	pending: state.SubDistributorsReducer.pending,
	deleting: state.SubDistributorsReducer.deleting,
	deletedId: state.SubDistributorsReducer.deletedId,
	newDistributor: state.SubDistributorsReducer.newSubDistributor,
	distributors: state.SubDistributorsReducer.subDistributors,
});

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(Component);
