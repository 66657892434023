import * as React from "react";
import DistributorsCreateClient from "containers/Portal/SubDistributor/Clients/Edit/Container";
import DistributorsPortal from "containers/Portal/SubDistributor/Clients/Container";
import UserEdit from "containers/Portal/SubDistributor/Users/Edit/Container";
import Users from "containers/Portal/SubDistributor/Users/Container";
import { ProtectedRoute } from "./ProtectedRoute";
import { Routes } from "../../constants/routes";
import { Switch } from "react-router-dom";

export const RouterListSubDistributor = () => {
  return (
    <Switch>
      <ProtectedRoute
        exact={true}
        path={Routes.SUB_DISTRIBUTORS_CLIENTS_CREATE}
        component={DistributorsCreateClient}
      />
      <ProtectedRoute
        path={Routes.SUB_DISTRIBUTOR_PORTAL_CLIENTS_EDIT_ID}
        component={DistributorsCreateClient}
      />
      <ProtectedRoute
        exact={true}
        path={Routes.SUB_DISTRIBUTOR_PORTAL_CLIENTS_EDIT}
        component={DistributorsCreateClient}
      />
      <ProtectedRoute
        exact={true}
        path={Routes.SUB_DISTRIBUTORS_USERS}
        component={Users}
      />
      <ProtectedRoute
        exact={true}
        path={Routes.SUB_DISTRIBUTORS_USERS_EDIT_ID}
        component={UserEdit}
      />
      <ProtectedRoute
        exact={true}
        path={Routes.SUB_DISTRIBUTORS_USERS_CREATE}
        component={UserEdit}
      />
      <ProtectedRoute
        exact={true}
        path={Routes.SUB_DISTRIBUTOR_PORTAL}
        component={DistributorsPortal}
      />
    </Switch>
  );
};
