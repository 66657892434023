import { ApiURL } from 'constants/api';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchSaga } from 'sagas/_sagas';
import { getType } from 'typesafe-actions';
import { lang, t } from 'translation/i18n';
import { Method } from 'constants/enum';
import { notificationAction } from 'components/Toast/_actions';
import { Status } from 'components/Toast/_types';
import { translationPath } from 'utils/getPath';
import {
	administratorGetByTokenAction,
	administratorPutAction,
	administratorResetPasswordAction,
} from "./_actions";

function* administratorGetActionSaga(
	action: ReturnType<typeof administratorGetByTokenAction.request>
): Generator {
	try {
		// @ts-ignore
		const { errorResponse, response, success, statusText } = yield call(
			fetchSaga,
			ApiURL.ADMINISTRATOR_USER,
			Method.GET
		);

		if (!success || errorResponse) {
			yield put(administratorGetByTokenAction.failure(errorResponse));
			yield put(
				notificationAction({
					code: Status.ERROR,
					message: t(translationPath(lang.common[statusText])),
				})
			);
			return;
		}

		if (success && response) {
			yield put(administratorGetByTokenAction.success(response));
		}
	} catch (err) {
		yield put(administratorGetByTokenAction.failure(err));
	}
}

function* administratorResetPasswordPostActionSaga(
	action: ReturnType<typeof administratorResetPasswordAction.request>
): Generator {
	try {
		// @ts-ignore
		const { errorResponse, response, success, statusText } = yield call(
			fetchSaga,
			ApiURL.ADMINISTRATOR_RESET_PASSWORD,
			Method.POST,
			{
				param: action.payload,
			}
		);

		if (!success || errorResponse) {
			yield put(administratorResetPasswordAction.failure(errorResponse));
			yield put(
				notificationAction({
					code: Status.ERROR,
					message: t(translationPath(lang.common[statusText])),
				})
			);
			return;
		}

		if (success && response) {
			yield put(
				notificationAction({
					code: Status.SUCCESS,
					message: t(translationPath(lang.common.successful)),
				})
			);
			yield put(administratorResetPasswordAction.success(response));
		}
	} catch (err) {
		yield put(administratorResetPasswordAction.failure(err));
	}
}

function* administratorPutActionSaga(
	action: ReturnType<typeof administratorPutAction.request>
): Generator {
	try {
		// @ts-ignore
		const { errorResponse, response, success, statusText } = yield call(
			fetchSaga,
			ApiURL.ADMINISTRATOR_USER,
			Method.PUT,
			{
				bodyJSON: action.payload,
			}
		);

		if (!success || errorResponse) {
			yield put(administratorPutAction.failure(errorResponse));
			yield put(
				notificationAction({
					code: Status.ERROR,
					message: t(translationPath(lang.common[statusText])),
				})
			);
			return;
		}

		if (success && response) {
			yield put(
				notificationAction({
					code: Status.SUCCESS,
					message: t(translationPath(lang.common.successful)),
				})
			);
			yield put(administratorPutAction.success(response));
		}
	} catch (err) {
		yield put(administratorPutAction.failure(err));
	}
}

export function* watchAdministratorResetPasswordPostAction() {
	yield takeLatest(
		getType(administratorResetPasswordAction.request),
		administratorResetPasswordPostActionSaga
	);
}

export function* watchAdministratorPutAction() {
	yield takeLatest(
		getType(administratorPutAction.request),
		administratorPutActionSaga
	);
}

export function* watchAdministratorGetByTokenAction() {
	yield takeLatest(
		getType(administratorGetByTokenAction.request),
		administratorGetActionSaga
	);
}
