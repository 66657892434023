import { Lang } from '../lang';

const dictionary: Lang = {
	distributors: {
		newDistributor: "Verteiler erstellen",
		editDistributor: "Verteiler bearbeiten",
		newClient: "Mandant erstellen",
		distributor: "verteiler",
		editClient: "Mandant bearbeiten",
	},
	administrator: {
		editAdministrator: "Administrator bearbeiten",
	},
	login: {
		subdistributor: "Subdistributor",
		administrator: "Verwalter",
		titleDistributor: "Systemanmeldung des Verteilers",
		titleSubDistributor: "Systemanmeldung des Unterverteilers",
		titleAdministrator: "Administrator-Systemanmeldung",
	},
	languages: {
		czech: "Tschechisch",
		english: "Englisch",
		german: "Deutsch",
	},
	header: {
		language: "Sprache",
		currency: "Währung",
	},
	table: {
		licenseRenewal: "Lizenzverlängerung (Jahre)",
		halfYear: "Ein halbes Jahr",
		year: "Jahr",
		twoYears: "Zwei Jahre",
		licenseRenewalHalfYear: "Lizenzverlängerung für ein halbes Jahr",
		licenseRenewalYear: "Lizenzverlängerung für ein Jahr",
		licenseRenewalTwoYears: "Lizenzverlängerung um zwei Jahre",
		capacityIncrease: "Kapazität erhöhen (GB)",
		capacityIncrease5GB: "Erhöhung der Kapazität um 5 GB",
		capacityIncrease10GB: "Erhöhung der Kapazität um 10 GB",
		capacityIncrease100GB: "Kapazität um 100 GB erhöhen",
		Capacity5GB: "5",
		Capacity10GB: "10",
		Capacity100GB: "100",
		open: "Anzeigen",
		licenseIssued: "Ausgestellt am",
		licenseIssuedBy: "Ausgestellt am",
		licenseValidityEnd: "Gültig bis",
		clientLicenceNumber: "Licence Nummer",
		maxStorage: "Kapazität (GB)",
		name: "Name",
		contact: "Kontakt",
		block: "Block",
		size: "Größe",
		extension: "Erweiterung",
		action: "Aktion",
		deleted: "Gelöscht",
		status: "Status",
		active: "Aktiv",
		inactive: "Inaktiv",
		info: "Info",
		allowed: "Erlaubt",
		default: "Standard",
	},
	input: {
		name: "Name",
		picture: "Bild",
		pictures: "Bilder",
		description: "Beschreibung",
		url: "URL",
		currency: "Wahrung",
		forename: "Name",
		surname: "Nachname",
		countryCode: "Vorwahl",
		clientLicenceNumber: "Licence Nummer",
		email: "E-Mail",
		phone: "Telefon",
		company: "Unternehmen",
		crn: "ID",
		address: "Adresse",
		note: "Anmerkung",
		username: "Anmeldename",
		password: "Kennwort",
		currentPassword: "Altes Passwort",
		newPassword: "Neues Kennwort",
		validationPassword: "Passwortprüfung",
		login: "Anmeldung",
		resetPass: "Passwort zurücksetzen",
		changePass: "Passwort ändern",
		public: "Öffentlich",
	},
	validation: {
		min: "Das Feld muss mindestens {{count}} Zeichen enthalten.",
		max: "Das Feld kann maximal {{Anzahl}} Zeichen enthalten.",
		required: "Erforderliches Feld.",
		email: "Geben Sie eine gültige E-Mail ein",
		phone: "Geben Sie eine gültige Telefonnummer ein",
		passwordMatch: "Die eingegebenen Passwörter stimmen nicht überein",
	},
	button: {
		more: "Mehr",
		load: "Mehr laden",
		link: "link",
		loadPrice: "Preis laden",
		activate: "Aktivieren",
		editItem: "Artikel bearbeiten",
		newItem: "Neuer Artikel",
		deleteItem: "Artikel löschen",
		duplicateItem: "Element duplizieren",
		saveItem: "Element speichern",
		save: "Speichern",
		resetPassword: "Passwort ändern",
	},
	toast: {
		createDistributorSuccess:
			"Der Verteiler wurde erfolgreich erstellt. Die Anmeldedaten wurden automatisch an seine E-Mail gesendet.",
		createSubDistributorSuccess:
			"Der Unterverteiler wurde erfolgreich erstellt. Die Anmeldedaten wurden automatisch an seine E-Mail-Adresse gesendet.",
		createClientSuccess:
			"Der Kunde wurde erfolgreich erstellt. Die Anmeldedaten wurden automatisch an seine E-Mail gesendet.",
	},
	status: {
		Unauthorized: "Falsches Passwort oder Benutzername",
	},
	users: {
		title: "Benutzer",
		newUser: "Neuer Benutzer",
		editUser: "Benutzer bearbeiten",
	},
	common: {
		settings: "Einstellungen",
		admin: "SuperAdmin-Umgebung",
		superAdmin: "Super-Admin-Umgebung",
		resetSuccessful: "Passwort wurde erfolgreich geändert.",
		users: "Benutzer",
		resetRequestPassword: "Passwort zurückgesetzt",
		resetRequestPasswordSubmit: "Zurücksetzen",
		resetPassword: "Neues Passwort",
		resetPasswordSubmit: "Zurücksetzen",
		distributor: "Verteiler",
		distributors: "Verteiler",
		clients: "Kunden",
		legalPerson: "Juristische Person",
		naturalPerson: "Natürliche Person",
		companyName: "Firmenname",
		vatRegNumber: "Umsatzsteuer-Identifikationsnummer",
		exceed: "Preisüberschreitung",
		attachedFiles: "Ziehen Sie die Dateien, die Sie speichern möchten, hierher",
		NameAlreadyExists: "Name existiert bereits",
		EntityNotFound:
			"Das Objekt befindet sich nicht in der Datenbank (es wurde wahrscheinlich gelöscht)",
		DuplicateEntity:
			"Kann nicht erstellt werden - das Objekt befindet sich bereits in der Datenbank",
		UsernameExists: "Benutzername vorhanden",
		InvalidOrMissingRole: "Fehlende Rolle.",
		CannotCreateUser: "Konnte den Benutzer nicht erstellen.",
		ConcurrencyFailure:
			"Das Objekt wird gerade von einem anderen Benutzer bearbeitet.",
		PasswordMismatch: "Passwörter stimmen nicht überein.",
		InvalidToken: "Ungültiges Token.",
		LoginAlreadyAssociated: "Der angegebene Benutzer ist bereits eingeloggt.",
		InvalidUserName: "Ihr Benutzername hat ein ungültiges Format.",
		InvalidEmail: "Ihre E-Mail hat ein ungültiges Format.",
		EmailExists: "Die angegebene E-Mail existiert bereits.",
		InvalidRoleName: "Ungültiger Rollenname.",
		RoleNameExists: "Diese Rolle existiert bereits.",
		PasswordAlreadySet: "Das Passwort wurde bereits eingegeben.",
		UserLockoutNotEnabled: "Der Benutzer kann nicht gesperrt werden.",
		UserAlreadyInRole: "Die Rolle ist dem Benutzer bereits zugewiesen.",
		UserNotInRole: "Der Benutzer hat keine zugewiesene Rolle",
		PasswordTooShort: "Das Passwort ist zu kurz.",
		PasswordRequiresNonAlphanumeric:
			"Das Passwort darf keine numerischen Zeichen enthalten",
		PasswordRequiresDigit:
			"Das Kennwort muss mindestens ein numerisches Zeichen enthalten.",
		PasswordRequiresLower:
			"Das Passwort muss mindestens einen Kleinbuchstaben enthalten.",
		PasswordRequiresUpper: "Kennwort erfordert mindestens einen Großbuchstaben",
		Unauthorized: "Sie haben falsche Anmeldedaten eingegeben.",
		order: "Bestellung",
		logout: "Abmelden",
		register: "Konto erstellen",
		reset: "Haben Sie Ihr Passwort vergessen?",
		successful: "Ihre Anfrage wurde erfolgreich bearbeitet",
		failed: "Ihre Anfrage konnte nicht bearbeitet werden",
		close: "Schließen",
		loading: "Laden",
		oauth: "Anmeldung am System",
	},
};

export default dictionary;
