import Component, { DispatchProps, StateProps } from './Component';
import { clearNotificationAction } from 'components/Toast/_actions';
import { clientsInit } from '../Admin/Clients/ClientsTable/_actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { distributorsInit } from '../Admin/Distributors/UsersTable/_actions';
import { oauthSubDistributorogoutAction } from 'containers/Authentication/SubDistributor/_actions';

export default connect(
	(state: any): StateProps => ({
		toast: state.ToastReducer.notification,
	}),
	(dispatch: Dispatch): DispatchProps => ({
		clearToast: () => dispatch(clearNotificationAction()),
		oauthSubDistributorogoutAction: () =>
			dispatch(oauthSubDistributorogoutAction()),
		distributorsInit: () => dispatch(distributorsInit()),
		clientsInit: () => dispatch(clientsInit()),
	})
)(Component);
