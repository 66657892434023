import { all, fork } from "redux-saga/effects";
import {
  watchAuthenticationAdmin,
  watchResetAdmin,
} from "containers/Authentication/Admin/_sagas";
import { watchAuthenticationDistributor } from "../containers/Authentication/Distributor/_sagas";
import { watchAuthenticationSuperAdmin } from "../containers/Authentication/SuperAdmin/_sagas";
import { watchRequestResetPassword } from "../containers/Authentication/ResetRequest/_sagas";
import { watchResetPassword } from "../containers/Authentication/Reset/_sagas";
import { watchResetPasswordPostAction } from "../containers/Portal/SuperAdmin/Settings/_sagas";
import {
  watchAdministratorAuthentication,
  watchDistributorAuthentication,
} from "containers/Authentication/SubDistributor/_sagas";
import {
  watchUserDeleteAction,
  watchUserGetByIdAction,
  watchUserPostAction,
  watchUserPutAction,
  watchUsersGetAction,
} from "containers/Portal/SuperAdmin/Users/UsersTable/_sagas";
import {
  watchDistributorClientRegisterPostAction,
  watchDistributorDeleteAction,
  watchDistributorGetAction,
  watchDistributorGetByIdAction,
  watchDistributorPostAction,
  watchDistributorPutAction,
  watchDistributoUsersGetAction,
  watchDistributorDeleteUserAction,
  watchDistributorUserPutActionSaga,
  watchDistributorUserGetByIdActionSaga,
  watchDistributorReactivatePostActionSaga,
} from "containers/Portal/Admin/Distributors/UsersTable/_sagas";
import {
  watchAdministratorGetByTokenAction,
  watchAdministratorPutAction,
  watchAdministratorResetPasswordPostAction,
} from "containers/Portal/Admin/Settings/_sagas";
import {
  watchClientGetByIdAction,
  watchClientsDeleteAction,
  watchClientsGetAction,
  watchClientsPostAction,
  watchClientsPutAction,
  watchClientsPostStorageAction,
  watchClientsPostValidityAction,
  watchClientsReactivationAction,
} from "../containers/Portal/Admin/Clients/ClientsTable/_sagas";
import {
  watchSubDistributorsDeleteAction,
  watchSubDistributorsGetByIdAction,
  watchSubDistributorsRegisterPostAction,
  watchSubDistributorsGetAction,
  watchSubDistributorsPutUserByIdAction,
  watchSubDistributorsGetUsersByIdAction,
  watchSubDistributorsDeleteUserByIdAction,
  watchSubDistributorsGetUsersAction,
  watchSubDistributorsRegisterUserPostAction,
  watchSubDistributorsPutAction,
  watchSubDistributorsReactivatePostAction,
} from "containers/Portal/SubDistributor/_sagas";

export const rootSaga = [
  watchDistributorAuthentication,
  watchAdministratorAuthentication,
  watchAuthenticationSuperAdmin,
  watchAuthenticationAdmin,
  watchResetAdmin,
  watchUsersGetAction,
  watchUserPostAction,
  watchUserPutAction,
  watchUserGetByIdAction,
  watchUserDeleteAction,
  watchResetPasswordPostAction,
  watchDistributorGetAction,
  watchDistributorPostAction,
  watchDistributorPutAction,
  watchDistributorClientRegisterPostAction,
  watchDistributorGetByIdAction,
  watchDistributorDeleteAction,
  watchAdministratorResetPasswordPostAction,
  watchAdministratorPutAction,
  watchAdministratorGetByTokenAction,
  watchClientsGetAction,
  watchClientsPostAction,
  watchClientsPutAction,
  watchClientsPostStorageAction,
  watchClientsPostValidityAction,
  watchClientGetByIdAction,
  watchClientsDeleteAction,
  watchAuthenticationDistributor,
  watchResetPassword,
  watchSubDistributorsGetAction,
  watchSubDistributorsRegisterPostAction,
  watchSubDistributorsGetByIdAction,
  watchSubDistributorsDeleteAction,
  watchDistributoUsersGetAction,
  watchDistributorDeleteUserAction,
  watchDistributorUserPutActionSaga,
  watchDistributorUserGetByIdActionSaga,
  watchSubDistributorsPutUserByIdAction,
  watchSubDistributorsGetUsersByIdAction,
  watchSubDistributorsDeleteUserByIdAction,
  watchSubDistributorsGetUsersAction,
  watchSubDistributorsPutAction,
  watchSubDistributorsRegisterUserPostAction,
  watchClientsReactivationAction,
  watchDistributorReactivatePostActionSaga,
  watchSubDistributorsReactivatePostAction,
  watchRequestResetPassword,
];

export type SagasType = typeof rootSaga;

export const combineSagas = (sagas: SagasType) =>
  function* appSaga() {
    yield all(sagas.map((saga) => fork(saga)));
  };
