import { ApiURL } from '../../../constants/api';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchSaga } from 'sagas/_sagas';
import { getType } from 'typesafe-actions';
import { lang, t } from 'translation/i18n';
import { Method } from 'constants/enum';
import { notificationAction } from 'components/Toast/_actions';
import { requestResetPasswordAction } from './_actions';
import { Status } from 'components/Toast/_types';
import { translationPath } from 'utils/getPath';

function* resetPasswordActionSaga(
	action: ReturnType<typeof requestResetPasswordAction.request>
): Generator {
	try {
		// @ts-ignore
		const { errorResponse, response, success, statusText } = yield call(
			fetchSaga,
			ApiURL.RESET_PASSWORD,
			Method.POST,
			{
				param: action.payload,
			}
		);

		if (!success || errorResponse) {
			yield put(requestResetPasswordAction.failure(errorResponse));
			yield put(
				notificationAction({
					code: Status.ERROR,
					message: t(translationPath(lang.common[statusText])),
				})
			);
			return;
		}

		if (success && response) {
			yield put(
				notificationAction({
					code: Status.SUCCESS,
					message: t(translationPath(lang.common.resetSuccessful)),
				})
			);
			yield put(requestResetPasswordAction.success());
		}
	} catch (err) {
		yield put(requestResetPasswordAction.failure(err));
	}
}

export function* watchRequestResetPassword() {
	yield takeLatest(
		getType(requestResetPasswordAction.request),
		resetPasswordActionSaga
	);
}
