import { connect } from "react-redux";
import { withRouter } from "react-router";
import { RootStateType } from "reducers";
import { compose, Dispatch } from "redux";
import Component, { DispatchProps, StateProps } from "./Component";
import { DistributorRequest } from "../UsersTable/_types";
import {
  distributorGetByIdAction,
  distributorPostAction,
  distributorPutAction,
} from "../UsersTable/_actions";

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  distributorPostAction: (data: DistributorRequest) =>
    dispatch(distributorPostAction.request(data)),
  distributorPutAction: (data: DistributorRequest) =>
    dispatch(distributorPutAction.request(data)),
  distributorGetByIdAction: (data: string) =>
    dispatch(distributorGetByIdAction.request(data)),
});

const mapStateToProps = (state: RootStateType): StateProps => ({
  pending: state.DistributorReducer.pending,
  distributor: state.DistributorReducer.distributor,
  routerState: state.router.location.state,
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
