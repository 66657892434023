import React from 'react';
import styled from 'styled-components';
import { device } from 'constants/theme';
import {
	ContentEndCenter,
	ContentSpaceBetweenCenter,
} from "constants/globalStyles";

interface Box {
	title: string | React.ReactNode;
	children: React.ReactNode;
	action?: React.ReactNode[];
	withoutMargin?: boolean;
	bottom?: boolean;
}

export const Box: React.FC<Box> = ({
	withoutMargin,
	action,
	title,
	children,
	bottom,
}) => {
	return (
		<Content>
			<ContentHeader>
				<ContentSpaceBetweenCenter>
					<Header>{title}</Header>
					{action && action.map((val) => val)}
				</ContentSpaceBetweenCenter>
			</ContentHeader>
			<ContentBody withoutMargin={withoutMargin}>{children}</ContentBody>
			<ContentHeader>
				<ContentEndCenter>
					{!bottom &&
						action &&
						action.map((val, key) => <div key={key}>{val}</div>)}
				</ContentEndCenter>
			</ContentHeader>
		</Content>
	);
};

export const Content = styled.div`
	width: 100%;
	background-color: #fff;
	-webkit-box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
	box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
	border-top: 1px solid #eaeded;
	border-radius: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	word-wrap: break-word;
	margin: 0.7em 0;

	@media ${device.extraLarge} {
		max-width: 1300px;
		overflow: scroll;
	}

	@media ${device.large} {
		max-width: 1000px;
		overflow: scroll;
	}

	@media ${device.small} {
		max-width: 700px;
		overflow: scroll;
	}
`;

export const ContentHeader = styled.div`
	background-color: #fafafa;
	border-bottom: 1px solid #eaeded;
	padding: 0.8rem 1.6rem;
`;

export const Header = styled.div`
	font-size: 1.4rem;
	line-height: 1.1rem;
	font-weight: 500;
`;

export const SubHeader = styled.div`
	font-size: 1.3rem;
	line-height: 1.1rem;
	font-weight: 500;
	border-bottom: 1px solid #cccccc96;
	margin: 10px 20px;
	padding-bottom: 7px;
`;

export const ContentBody = styled.div<{ withoutMargin: boolean }>`
	padding: ${(props) => (props.withoutMargin ? "0" : `0.5rem;`)};

	@media ${device.large} {
		padding: ${(props) => (props.withoutMargin ? "0" : `0.4rem;`)};
	}

	@media ${device.small} {
		padding: ${(props) => (props.withoutMargin ? "0" : `0.2rem`)};
	}
`;
