import { ClientsStateType } from "./_types";
import { getType } from "typesafe-actions";
import {
  ClientsActionType,
  clientsDeleteByIdAction,
  clientsGetAction,
  clientsGetByIdAction,
  clientsPostAction,
  clientsPutAction,
  clientsPostStorageAction,
  clientsPostValidityAction,
  clientsReactivate,
  clientsInit,
} from "./_actions";

const initialState: ClientsStateType = {
  pending: false,
  error: null,
  clients: null,
  client: null,
  deleting: false,
  deletedId: null,
  newClient: null,
  activating: false,
  activatedId: null,
};

export const ClientsReducer = (
  state: ClientsStateType = initialState,
  action: ClientsActionType
): ClientsStateType => {
  switch (action.type) {
    case getType(clientsInit):
      return {
        ...initialState,
      };
    case getType(clientsGetAction.request):
      return {
        ...initialState,
        pending: true,
      };
    case getType(clientsPostAction.request):
    case getType(clientsPutAction.request):
    case getType(clientsPostStorageAction.request):
    case getType(clientsPostValidityAction.request):
    case getType(clientsGetByIdAction.request):
      return {
        ...state,
        pending: true,
      };
    case getType(clientsDeleteByIdAction.request):
      return {
        ...state,
        deleting: true,
        deletedId: action.payload,
      };
    case getType(clientsReactivate.request):
      return {
        ...state,
        activating: true,
        activatedId: action.payload,
      };
    case getType(clientsGetByIdAction.success):
      return {
        ...state,
        client: action.payload,
        pending: false,
      };
    case getType(clientsGetAction.success):
      return {
        ...state,
        clients: action.payload,
        pending: false,
      };
    case getType(clientsReactivate.success):
      return {
        ...state,
        clients: action.payload,
        activating: false,
        activatedId: null,
      };

    case getType(clientsPostStorageAction.success):
    case getType(clientsPostValidityAction.success):
      return {
        ...state,
        pending: false,
      };
    case getType(clientsPutAction.success):
      return {
        ...state,
        pending: false,
      };
    case getType(clientsPostAction.success):
      return {
        ...state,
        newClient: true,
        pending: false,
      };
    case getType(clientsDeleteByIdAction.success):
      return {
        ...state,
        deleting: false,
      };
    case getType(clientsGetAction.failure):
    case getType(clientsReactivate.failure):
    case getType(clientsPostAction.failure):
    case getType(clientsPutAction.failure):
    case getType(clientsPostStorageAction.failure):
    case getType(clientsPostValidityAction.failure):
    case getType(clientsGetByIdAction.failure):
    case getType(clientsDeleteByIdAction.failure):
      return {
        ...state,
        error: action.payload.ErrorMessage,
        pending: false,
        deleting: false,
      };
    default:
      return state;
  }
};
