import {FetchStateType} from "../../../../_types";
import {createProxy} from "../../../../../utils/getPath";

export type User = {
    Id?: string;
    Email: string;
    Name: string;
    Surname: string;
    CountryCode: string;
    PhoneNumber: string;
    Password?: string;
    OrganizationId?: string;
}

export type UserStateType = FetchStateType & {
    users: User[];
    user: User;
    deleting: boolean;
    deletedId: string;
}

export const UserProxy = createProxy<User>();
