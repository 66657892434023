import * as React from 'react';
import styled from 'styled-components';
import { FC } from 'react';

export interface IsDeletedProps {
	isDeleted: boolean;
}

export const IsDeleted: FC<IsDeletedProps> = ({ isDeleted }) => {
	return <Box isDeleted={isDeleted}></Box>;
};

const Box = styled.div<{ isDeleted: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;

	width: 10px;
	height: 15px;

	margin: auto;

	background-color: ${(props) =>
		props.isDeleted ? "rgb(220, 0, 78)" : "#17785e"};
	color: #fff;
	border-radius: 80%;
	font-size: 0.9rem;
	font-weight: 400;
	padding: 3px 8px;
	white-space: nowrap;
	cursor: pointer;

	svg {
		margin-top: -1px;
		margin-right: 4px;
		margin-bottom: -1px;

		font-size: 14px;

		&:last-of-type {
			margin-right: 6px;
		}
	}
`;
