import {getType} from "typesafe-actions";

import {SettingsStateType} from "./_types";
import {resetPasswordAction, SuperAdminResetPasswordActionType,} from "./_actions";

const initialState: SettingsStateType = {
  pending: false,
  error: null,
  deleting: false,
  deletedId: null,
};

export const SettingsReducer = (
    state: SettingsStateType = initialState,
    action: SuperAdminResetPasswordActionType
): SettingsStateType => {
  switch (action.type) {
    case getType(resetPasswordAction.request):
      return {
        ...state,
        pending: true,
      };
    case getType(resetPasswordAction.success):
      return {
        ...state,
        pending: false,
      };

    case getType(resetPasswordAction.failure):
      return {
        ...state,
        error: action.payload.ErrorMessage,
        pending: false,
        deleting: false,
      };
    default:
      return state;
  }
};
