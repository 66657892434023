import Component, { DispatchProps, StateProps } from './Component';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootStateType } from 'reducers';
import { User } from '../UsersTable/_types';
import { withRouter } from 'react-router';
import {
	distributorUserRegisterPostAction,
	distributorUserPutAction,
	distributorUserGetByIdAction,
} from "containers/Portal/Admin/Distributors/UsersTable/_actions";

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	userPostAction: (data: User) =>
		dispatch(distributorUserRegisterPostAction.request(data)),
	userPutAction: (data: User) =>
		dispatch(distributorUserPutAction.request(data)),
	userGetByIdAction: (data: string) =>
		dispatch(distributorUserGetByIdAction.request(data)),
});

const mapStateToProps = (state: RootStateType): StateProps => ({
	pending: state.DistributorReducer.pending,
	user: state.DistributorReducer.user,
	routerState: state.router.location.state,
});

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(Component);
