import {ProtectedRoute} from "./ProtectedRoute";
import {Routes} from "../../constants/routes";
import {Switch} from "react-router-dom";
import * as React from "react";
import AdminPortalUsers from "containers/Portal/SuperAdmin/Users/Container";
import AdminPortalUserNew from "containers/Portal/SuperAdmin/Users/Edit/Container";
import Settings from "containers/Portal/SuperAdmin/Settings/Container";

export const RouterListAdmin = () => {
    return (
        <Switch>
            <ProtectedRoute exact={true} path={Routes.SUPER_ADMINISTRATOR_PORTAL} component={AdminPortalUsers}/>
            <ProtectedRoute exact={true} path={Routes.SUPER_ADMIN_USER_NEW} component={AdminPortalUserNew}/>
            <ProtectedRoute exact={true} path={Routes.SUPER_ADMIN_USER_EDIT_ID} component={AdminPortalUserNew}/>
            <ProtectedRoute exact={true} path={Routes.SUPER_ADMIN_SETTINGS} component={Settings}/>
        </Switch>
    );
};
