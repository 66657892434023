import Cookies from 'universal-cookie';
import { ApiURL } from '../../../constants/api';
import { authDistributorAction } from './_actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchSaga } from '../../../sagas/_sagas';
import { getType } from 'typesafe-actions';
import { lang, t } from 'translation/i18n';
import { notificationAction } from 'components/Toast/_actions';
import { push } from 'connected-react-router';
import { Routes } from 'constants/routes';
import { Status } from 'components/Toast/_types';
import { translationPath } from 'utils/getPath';

function* tokenSaga(
	action: ReturnType<typeof authDistributorAction.request>
): Generator {
	try {
		// @ts-ignore
		const { errorResponse, response, success, statusText } = yield call(
			fetchSaga,
			ApiURL.OAUTH_DISTRIBUTOR,
			"POST",
			{
				bodyJSON: action.payload,
			}
		);
		if (!!(!success || errorResponse)) {
			yield put(authDistributorAction.failure(errorResponse));
			yield put(
				notificationAction({
					code: Status.ERROR,
					message: t(translationPath(lang.common[statusText])),
				})
			);
			return;
		}

		if (success && response) {
			const cookies = new Cookies();
			cookies.set("oauth_token", response, { path: "/" });
			yield put(authDistributorAction.success(response as string));
			yield put(push(Routes.DISTRIBUTOR_PORTAL));
		}
	} catch (err) {
		yield put(authDistributorAction.failure(err));
	}
}

export function* watchAuthenticationDistributor() {
	yield takeLatest(getType(authDistributorAction.request), tokenSaga);
}
