import Component, { DispatchProps, StateProps } from "./Component";
import {
  clientsPutAction,
  clientsPostAction,
  clientsGetByIdAction,
} from "../../../Admin/Clients/ClientsTable/_actions";
import { compose, Dispatch } from "redux";
import { connect } from "react-redux";
import { CreateClient } from "containers/Portal/Admin/Clients/ClientsTable/_types";
import { RootStateType } from "reducers";
import { withRouter } from "react-router";

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  clientsPostAction: (data: CreateClient) =>
    dispatch(clientsPostAction.request(data)),
  clientsPutAction: (data: CreateClient) =>
    dispatch(clientsPutAction.request(data)),
  clientGetByIdAction: (data: string) =>
    dispatch(clientsGetByIdAction.request(data)),
});

const mapStateToProps = (state: RootStateType): StateProps => ({
  pending: state.ClientsReducer.pending,
  routerState: state.router.location.state,
  client: state.ClientsReducer.client,
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Component);
