import * as React from 'react';
import ErrorMessage from './ErrorMessage';
import FormikInput from './FormikInput';
import styled from 'styled-components';
import { GroupedOptionsType, OptionsType } from 'react-select';
import { Input } from 'constants/enum';
import { OptionType } from './Select';

interface FormRowProps {
	id?: string;
	title?: string;
	hideGuide?: boolean;
	childrenId?: any;
	name?: any;
	onTitleClick?: (_event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	titleWidth?: number;
	formik?: any;
	type: Input;
	options?: GroupedOptionsType<OptionType> | OptionsType<OptionType>;
	children?: React.ReactNode;
	format?: string;
	placeholder?: string;
	multiline?: boolean;
	disabled?: boolean;
	variableWidth?: boolean;
	titleUp?: boolean;
	countryCode?: string;
	phoneNumber?: string;
}

const FormikRow: React.FC<FormRowProps> = ({
	id,
	formik,
	format,
	children,
	name,
	options,
	type,
	title,
	placeholder,
	multiline,
	disabled,
	variableWidth,
	titleUp,
	countryCode,
	phoneNumber,
}) => {
	return (
		<Wrapper titleUp={titleUp}>
			{title && (
				<Title htmlFor={id} variableWidth={variableWidth} titleUp={titleUp}>
					{title}
				</Title>
			)}
			<InputWrapper>
				<FormikInput
					id={id}
					placeholder={placeholder}
					options={options}
					type={type}
					formik={formik}
					name={name}
					format={format}
					title={title}
					multiline={multiline}
					disabled={disabled}
					countryCode={countryCode}
					phoneNumber={phoneNumber}
				/>
				{children && children}
				<ErrorMessage name={name} formik={formik} />
			</InputWrapper>
		</Wrapper>
	);
};

export default FormikRow;

const Wrapper = styled.div<{ titleUp: boolean }>`
	display: flex;
	flex-direction: ${(props) => (props.titleUp ? "column" : "row")};
	align-items: flex-start;
	justify-content: flex-start;

	width: 100%;

	margin: 0.5rem 0;
`;

const Title = styled.label<{ variableWidth?: boolean; titleUp?: boolean }>`
	width: ${(props) => (props.variableWidth ? "auto" : "55%")};
	max-width: 150px;
	padding: ${(props) => (props.titleUp ? "0.6rem 0" : "1rem 2rem")};
	color: #a7a7a7;
`;

const InputWrapper = styled.div`
	width: 100%;
`;
