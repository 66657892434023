import {getType} from "typesafe-actions";

import {AdministratorSettingsStateType} from "./_types";
import {
    administratorGetByTokenAction,
    administratorPutAction,
    administratorResetPasswordAction,
    AdministratorSettingsActionType,
} from "./_actions";

const initialState: AdministratorSettingsStateType = {
    pending: false,
    error: null,
    deleting: false,
    deletedId: null,
    administrator: null
};

export const AdministratorSettingsReducer = (
    state: AdministratorSettingsStateType = initialState,
    action: AdministratorSettingsActionType
): AdministratorSettingsStateType => {
    switch (action.type) {
        case getType(administratorPutAction.request):
        case getType(administratorResetPasswordAction.request):
        case getType(administratorGetByTokenAction.request):
            return {
                ...state,
                pending: true,
            };
        case getType(administratorResetPasswordAction.success):
        case getType(administratorPutAction.success):
            return {
                ...state,
                pending: false,
            };
        case getType(administratorGetByTokenAction.success):
            return {
                ...state,
                pending: false,
                administrator: action.payload
            };
        case getType(administratorResetPasswordAction.failure):
        case getType(administratorPutAction.failure):
        case getType(administratorGetByTokenAction.failure):
            return {
                ...state,
                error: action.payload.ErrorMessage,
                pending: false,
                deleting: false,
            };
        default:
            return state;
    }
};
