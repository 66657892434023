import { ApiURL } from "constants/api";
import { call, put, takeLatest } from "redux-saga/effects";
import { fetchSaga } from "sagas/_sagas";
import { getType } from "typesafe-actions";
import { lang, t } from "translation/i18n";
import { Method } from "constants/enum";
import { notificationAction } from "components/Toast/_actions";
import { push } from "connected-react-router";
import { Routes } from "constants/routes";
import { Status } from "components/Toast/_types";
import { translationPath } from "utils/getPath";
import {
  userDeleteByIdAction,
  userGetByIdAction,
  userPostAction,
  userPutAction,
  usersGetAction,
} from "./_actions";

function* usersGetActionSaga(
  action: ReturnType<typeof usersGetAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.SUPER_ADMIN_USERS,
      Method.GET
    );

    if (!success || errorResponse) {
      yield put(usersGetAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(usersGetAction.success(response));
    }
  } catch (err) {
    console.log(err);
    yield put(usersGetAction.failure(err));
  }
}

function* userPutActionSaga(
  action: ReturnType<typeof userPutAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.SUPER_ADMIN_USER,
      Method.PUT,
      {
        bodyJSON: action.payload,
      }
    );

    if (!success || errorResponse) {
      yield put(userPutAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(usersGetAction.request());
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(userPutAction.success(response));
      yield put(push(Routes.SUPER_ADMINISTRATOR_PORTAL));
    }
  } catch (err) {
    yield put(userPutAction.failure(err));
  }
}

function* userGetByIdActionSaga(
  action: ReturnType<typeof userGetByIdAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.SUPER_ADMIN_USERS,
      Method.GET,
      {
        param: action.payload,
      }
    );

    if (!success || errorResponse) {
      yield put(userGetByIdAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(userGetByIdAction.success(response));
    }
  } catch (err) {
    yield put(userGetByIdAction.failure(err));
  }
}

function* userPostActionSaga(
  action: ReturnType<typeof userPostAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.SUPER_ADMIN_USER_REGISTER,
      Method.POST,
      {
        bodyJSON: action.payload,
      }
    );
    console.log(action.payload);
    if (!success || errorResponse) {
      yield put(userPostAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(userPostAction.success(response));
      yield put(push(Routes.SUPER_ADMINISTRATOR_PORTAL));
    }
  } catch (err) {
    yield put(userPostAction.failure(err));
  }
}

function* userDeleteActionSaga(
  action: ReturnType<typeof userDeleteByIdAction.request>
): Generator {
  try {
    // @ts-ignore
    const { errorResponse, response, success, statusText } = yield call(
      fetchSaga,
      ApiURL.SUPER_ADMIN_USER,
      Method.DELETE,
      {
        param: action.payload,
      }
    );

    if (!success || errorResponse) {
      yield put(userDeleteByIdAction.failure(errorResponse));
      yield put(
        notificationAction({
          code: Status.ERROR,
          message: t(translationPath(lang.common[statusText])),
        })
      );
      return;
    }

    if (success && response) {
      yield put(usersGetAction.request());
      yield put(
        notificationAction({
          code: Status.SUCCESS,
          message: t(translationPath(lang.common.successful)),
        })
      );
      yield put(userDeleteByIdAction.success(response));
    }
  } catch (err) {
    yield put(userDeleteByIdAction.failure(err));
  }
}

export function* watchUsersGetAction() {
  yield takeLatest(getType(usersGetAction.request), usersGetActionSaga);
}

export function* watchUserPostAction() {
  yield takeLatest(getType(userPostAction.request), userPostActionSaga);
}

export function* watchUserPutAction() {
  yield takeLatest(getType(userPutAction.request), userPutActionSaga);
}

export function* watchUserGetByIdAction() {
  yield takeLatest(getType(userGetByIdAction.request), userGetByIdActionSaga);
}

export function* watchUserDeleteAction() {
  yield takeLatest(getType(userDeleteByIdAction.request), userDeleteActionSaga);
}
