import { ActionType, createAsyncAction } from 'typesafe-actions';
import { Error } from '../../../sagas/_sagas';
import { ResetPassword } from './_types';

export const resetPasswordAction = createAsyncAction(
	"RESET_PASSWORD_REQUEST",
	"RESET_PASSWORD_SUCCESS",
	"RESET_PASSWORD_FAILURE"
)<ResetPassword, void, Error>();

export type ResetPasswordActionType = ActionType<typeof resetPasswordAction>;
