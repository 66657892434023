import {getType} from "typesafe-actions";
import {ResetPasswordType} from "./_types";
import {resetPasswordAction, ResetPasswordActionType,} from "./_actions";

const initialState: ResetPasswordType = {
  pending: false,
  error: null,
};

export const ResetPasswordReducer = (
    state: ResetPasswordType = initialState,
    action: ResetPasswordActionType
): ResetPasswordType => {
  switch (action.type) {
    case getType(resetPasswordAction.request):
      return {
        ...initialState,
        pending: true,
      };
    case getType(resetPasswordAction.success):
      return {
        ...initialState,
        pending: false,
      };
    case getType(resetPasswordAction.failure):
      return {
        ...state,
        pending: false,
      };
    default:
      return state;
  }
};
