import {connect} from "react-redux";
import {withRouter} from "react-router";
import {RootStateType} from "reducers";
import {compose, Dispatch} from "redux";
import Component, {DispatchProps, StateProps} from "./Component";
import {userGetByIdAction, userPostAction, userPutAction} from "../UsersTable/_actions";
import {User} from "../UsersTable/_types";

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  userPostAction: (data: User) =>
      dispatch(userPostAction.request(data)),
  userPutAction: (data: User) =>
      dispatch(userPutAction.request(data)),
  userGetByIdAction: (data: string) => dispatch(userGetByIdAction.request(data))
});

const mapStateToProps = (state: RootStateType): StateProps => ({
  pending: state.UserReducer.pending,
  user: state.UserReducer.user,
  routerState: state.router.location.state,
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Component);
