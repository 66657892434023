import Cookies from 'universal-cookie';
import { Authentication } from 'types/_types';
import { getType } from 'typesafe-actions';
import {
	AuthenticationSuperAdminActionType,
	oauthSuperAdminAction,
	oauthSuperAdminLogoutAction,
} from "./_actions";

const initialState: Authentication = {
	access_token: null,
	expires_in: null,
	token_type: null,
	pending: false,
	error: null,
};

export const AuthenticationSuperAdminReducer = (
	state: Authentication = initialState,
	action: AuthenticationSuperAdminActionType
): Authentication => {
	switch (action.type) {
		case getType(oauthSuperAdminAction.request):
			return {
				...initialState,
				pending: true,
			};
		case getType(oauthSuperAdminAction.success):
			return {
				...initialState,
				access_token: action.payload,
				pending: false,
			};
		case getType(oauthSuperAdminAction.failure):
			return {
				...state,
				pending: false,
			};
		case getType(oauthSuperAdminLogoutAction):
			const cookies = new Cookies();
			cookies.remove("oauth_token");
			return {
				...initialState,
			};
		default:
			return state;
	}
};
