import Component, { DispatchProps, StateProps } from './Component';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootStateType } from 'reducers';
import { Storage, Validity } from '../Clients/ClientsTable/_types';
import { subDistributorsDeleteByIdAction } from 'containers/Portal/SubDistributor/_actions';
import { withRouter } from 'react-router';
import {
	distributorDeleteByIdAction,
	distributorsGetAction,
	distributorReactivatePostAction,
} from "./UsersTable/_actions";
import {
	clientsDeleteByIdAction,
	clientsPostStorageAction,
	clientsPostValidityAction,
	clientsReactivate,
} from "../Clients/ClientsTable/_actions";

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	distributorsGetAction: (data: void) =>
		dispatch(distributorsGetAction.request(data)),
	distributorDeleteByIdAction: (data: string) =>
		dispatch(distributorDeleteByIdAction.request(data)),
	subDistributorsDeleteByIdAction: (data: string) =>
		dispatch(subDistributorsDeleteByIdAction.request(data)),
	clientsPostStorageAction: (data: Storage) =>
		dispatch(clientsPostStorageAction.request(data)),
	clientsPostValidityAction: (data: Validity) =>
		dispatch(clientsPostValidityAction.request(data)),
	clientsDeleteByIdAction: (data: string) =>
		dispatch(clientsDeleteByIdAction.request(data)),
	clientsReactivate: (data: string) =>
		dispatch(clientsReactivate.request(data)),
	distributorReactivatePostAction: (data: string) =>
		dispatch(distributorReactivatePostAction.request(data)),
});

const mapStateToProps = (state: RootStateType): StateProps => ({
	distributors: state.DistributorReducer.distributors,
	newDistributor: state.DistributorReducer.newDistributor,
	pending: state.DistributorReducer.pending,
	deleting: state.DistributorReducer.deleting,
	deletedId: state.DistributorReducer.deletedId,
});

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(Component);
