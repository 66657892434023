import createRootReducer from "../reducers";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import { applyMiddleware, compose, createStore } from "redux";
import { autoRehydrate } from "redux-persist";
import { combineSagas, rootSaga } from "../sagas";
import { createHashHistory } from "history";
import { createLogger } from "redux-logger";
import { routerActions, routerMiddleware } from "connected-react-router";
import { resetPasswordAction } from "../containers/Portal/SuperAdmin/Settings/_actions";
import {
  authAdministratorAction,
  authSubDistributorAction,
} from "../containers/Authentication/SubDistributor/_actions";
import {
  userDeleteByIdAction,
  userGetByIdAction,
  userPostAction,
  userPutAction,
  usersGetAction,
} from "../containers/Portal/SuperAdmin/Users/UsersTable/_actions";
import {
  distributorUserRegisterPostAction,
  distributorDeleteByIdAction,
  distributorGetByIdAction,
  distributorPostAction,
  distributorsGetAction,
} from "containers/Portal/Admin/Distributors/UsersTable/_actions";
import {
  clientsDeleteByIdAction,
  clientsGetAction,
  clientsGetByIdAction,
  clientsPostAction,
  clientsPostStorageAction,
  clientsPostValidityAction,
} from "../containers/Portal/Admin/Clients/ClientsTable/_actions";

const history = createHashHistory();
const path = (/#!(\/.*)$/.exec(history.location.hash) || [])[1];
if (path) {
  history.replace(path);
}

const rootReducer = createRootReducer(history);

const configureStore = (initialState?: any) => {
  // Redux Configuration
  const middleware = [];
  const enhancers = [];

  // Thunk Middleware
  middleware.push(thunk);

  //Sagas Middleware
  const sagaMiddleware = createSagaMiddleware();
  middleware.push(sagaMiddleware);

  // Logging Middleware
  const logger = createLogger({
    level: "info",
    collapsed: true,
  });

  // Skip redux logs in console during the tests
  if (process.env.NODE_ENV !== "test") {
    middleware.push(logger);
  }

  // Router Middleware
  const router = routerMiddleware(history);
  middleware.push(router);

  // Redux DevTools Configuration
  const actionCreators = {
    ...authAdministratorAction,
    ...authSubDistributorAction,
    ...routerActions,
    ...usersGetAction,
    ...userPostAction,
    ...userPutAction,
    ...userGetByIdAction,
    ...userDeleteByIdAction,
    ...resetPasswordAction,
    ...distributorsGetAction,
    ...distributorPostAction,
    ...distributorUserRegisterPostAction,
    ...distributorGetByIdAction,
    ...distributorDeleteByIdAction,
    ...clientsGetAction,
    ...clientsPostAction,
    ...clientsPostStorageAction,
    ...clientsPostValidityAction,
    ...clientsGetByIdAction,
    ...clientsDeleteByIdAction,
  };

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Options: http://extension.remotedev.io/docs/API/Arguments.html
        actionCreators,
      })
    : compose;
  /* eslint-enable no-underscore-dangle */

  // Apply Middleware & Compose Enhancers
  enhancers.push(applyMiddleware(...middleware));
  const enhancer = composeEnhancers(...enhancers, autoRehydrate());

  // Create Store
  const store = createStore(rootReducer, initialState, enhancer);

  //run Root Saga
  sagaMiddleware.run(combineSagas([...rootSaga]));

  if ((module as any).hot) {
    (module as any).hot.accept(
      "../reducers", // eslint-disable-next-line global-require
      () => store.replaceReducer(require("../reducers").default)
    );
  }

  return store;
};

export default { configureStore, history };
