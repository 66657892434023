import * as React from 'react';
import CheckCircle from '@material-ui/icons/CheckCircle';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import styled from 'styled-components';
import { FC } from 'react';
import { lang } from '../../translation/i18n';
import { translationPath } from '../../utils/getPath';
import { useTranslation } from 'react-i18next';

export interface StatusProps {
	status: boolean;
}

export const Status: FC<StatusProps> = ({ status }) => {
	const { t } = useTranslation();

	return (
		<Box status={status}>
			{status ? <CheckCircle /> : <RemoveCircle />}
			{status
				? t(translationPath(lang.table.active).path)
				: t(translationPath(lang.table.inactive).path)}
		</Box>
	);
};

const Box = styled.div<{ status: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;

	background-color: ${(props) =>
		props.status ? "#17785e" : "rgb(220, 0, 78)"};
	color: #fff;
	border-radius: 10px;
	font-size: 0.9rem;
	font-weight: 400;
	padding: 3px 8px;
	white-space: nowrap;
	cursor: pointer;

	svg {
		margin-top: -1px;
		margin-right: 4px;
		margin-bottom: -1px;

		font-size: 14px;

		&:last-of-type {
			margin-right: 6px;
		}
	}
`;
