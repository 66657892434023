import * as Yup from "yup";
import FormikRow from "components/Form/FormikRow";
import React, { useEffect } from "react";
import { Box } from "../../../../../components/Box";
import { ButtonType, MaterialButton } from "components/Button/MaterialButton";
import { Content } from "components/Content";
import { getCountryCode } from "utils/getCountryCodes";
import { GridItem, GridRow } from "constants/globalStyles";
import { Input } from "constants/enum";
import { lang } from "translation/i18n";
import { lastPathMember, translationPath } from "utils/getPath";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	Distributor,
	DistributorProxy,
} from "../../../Admin/Distributors/UsersTable/_types";

export interface StateProps {
	pending: boolean;
	routerState: any;
	distributor: Distributor;
}

export interface DispatchProps {
	subDistributorsRegisterPostAction: (data: Distributor) => void;
	subDistributorsPutAction: (data: Distributor) => void;
	subDistributorsGetByIdAction: (data: string) => void;
}

export interface Params {
	id: string;
}

const Component: React.FC<StateProps & DispatchProps> = ({
	subDistributorsRegisterPostAction,
	subDistributorsPutAction,
	subDistributorsGetByIdAction,
	pending,
	distributor,
}) => {
	const { t } = useTranslation();
	const { id } = useParams<Params>();

	const initialValues: Distributor = distributor
		? distributor
		: {
				Name: "",
				AdminEmail: "",
				AdminCountryCode: "",
				AdminPhoneNumber: "",
				AdminName: "",
				AdminSurname: "",
				LicenseValidityEnd: null,
				MaxStorage: 0,
				IsDeleted: false,
		  };

	useEffect(() => {
		if (!!id) {
			subDistributorsGetByIdAction(id);
		}
	}, [id && subDistributorsGetByIdAction]);

	const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: Yup.object({
			Name: Yup.string()
				.min(1, t(translationPath(lang.validation.min).path, { count: 1 }))
				.max(200, t(translationPath(lang.validation.max).path, { count: 200 }))
				.required(t(translationPath(lang.validation.required).path)),
			AdminEmail: Yup.string()
				.email(t(translationPath(lang.validation.email).path))
				.min(1, t(translationPath(lang.validation.min).path, { count: 1 }))
				.max(200, t(translationPath(lang.validation.max).path, { count: 200 }))
				.required(t(translationPath(lang.validation.required).path)),
			AdminName: Yup.string()
				.min(1, t(translationPath(lang.validation.min).path, { count: 1 }))
				.max(200, t(translationPath(lang.validation.max).path, { count: 200 }))
				.required(t(translationPath(lang.validation.required).path)),
			AdminSurname: Yup.string()
				.min(1, t(translationPath(lang.validation.min).path, { count: 1 }))
				.max(200, t(translationPath(lang.validation.max).path, { count: 200 }))
				.required(t(translationPath(lang.validation.required).path)),
			AdminPhoneNumber: Yup.string().matches(
				phoneRegExp,
				t(translationPath(lang.validation.phone).path)
			),
		}),

		onSubmit: (values: Distributor) => {
			id
				? subDistributorsPutAction(values)
				: subDistributorsRegisterPostAction(values);
		},
	});

	return (
		<Content
			title={t(
				translationPath(
					id
						? lang.distributors.editDistributor
						: lang.distributors.newDistributor
				).path
			)}
		>
			<form onSubmit={formik.handleSubmit}>
				<Box
					title={t(
						translationPath(
							id
								? lang.distributors.editDistributor
								: lang.distributors.newDistributor
						).path
					)}
					action={[
						<MaterialButton
							tooltip={t(translationPath(lang.button.saveItem).path)}
							type={ButtonType.SAVE}
							loading={pending}
						/>,
					]}
				>
					<GridRow>
						<GridItem>
							<FormikRow
								formik={formik}
								name={lastPathMember(DistributorProxy.Name).path}
								title={t(translationPath(lang.input.name).path)}
								titleWidth={30}
								type={Input.TEXT}
							/>
							<FormikRow
								formik={formik}
								name={lastPathMember(DistributorProxy.AdminName).path}
								title={t(translationPath(lang.input.forename).path)}
								titleWidth={30}
								disabled={!!id}
								type={Input.TEXT}
							/>
							<FormikRow
								formik={formik}
								name={lastPathMember(DistributorProxy.AdminSurname).path}
								title={t(translationPath(lang.input.surname).path)}
								titleWidth={30}
								disabled={!!id}
								type={Input.TEXT}
							/>
							<FormikRow
								formik={formik}
								title={t(translationPath(lang.input.phone).path)}
								titleWidth={30}
								countryCode={
									lastPathMember(DistributorProxy.AdminCountryCode).path
								}
								phoneNumber={
									lastPathMember(DistributorProxy.AdminPhoneNumber).path
								}
								type={Input.PHONE}
								disabled={!!id}
								options={getCountryCode().map((value: any) => {
									return { value: value, label: value };
								})}
							/>
							<FormikRow
								formik={formik}
								name={lastPathMember(DistributorProxy.AdminEmail).path}
								title={t(translationPath(lang.input.email).path)}
								titleWidth={30}
								type={Input.TEXT}
								disabled={!!id}
							/>
						</GridItem>
					</GridRow>
				</Box>
			</form>
		</Content>
	);
};

export default React.memo(Component);
