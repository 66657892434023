import React, { useEffect } from "react";
import { Alert } from "components/Alert";
import { Content } from "components/Content";
import { ContentColumn } from "constants/globalStyles";
import { Distributor } from "./UsersTable/_types";
import { DistributorsWithClients } from "../../../../components/DistributorsWithClients";
import { lang } from "translation/i18n";
import { Routes } from "../../../../constants/routes";
import { Storage, Validity } from "../Clients/ClientsTable/_types";
import { translationPath } from "utils/getPath";
import { useTranslation } from "react-i18next";

export interface StateProps {
  distributors: Distributor[];
  deletedId: string;
  deleting: boolean;
  pending: boolean;
  newDistributor: boolean;
}

export interface DispatchProps {
  distributorsGetAction: (data: void) => void;
  distributorDeleteByIdAction: (data: string) => void;
  clientsDeleteByIdAction: (data: string) => void;
  clientsPostStorageAction: (data: Storage) => void;
  clientsPostValidityAction: (data: Validity) => void;
  subDistributorsDeleteByIdAction: (data: string) => void;
  clientsReactivate: (data: string) => void;
  distributorReactivatePostAction: (data: string) => void;
}

const Component: React.FC<StateProps & DispatchProps> = ({
  distributors,
  distributorsGetAction,
  deletedId,
  deleting,
  pending,
  newDistributor,
  clientsDeleteByIdAction,
  clientsPostStorageAction,
  clientsPostValidityAction,
  distributorDeleteByIdAction,
  clientsReactivate,
  distributorReactivatePostAction,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    distributorsGetAction();
  }, [distributorsGetAction]);

  return (
    <Content title={t(translationPath(lang.common.distributors).path)}>
      <ContentColumn>
        {newDistributor && (
          <Alert
            children={
              <>
                {t(translationPath(lang.toast.createDistributorSuccess).path)}
              </>
            }
          />
        )}
        <DistributorsWithClients
          distributors={distributors}
          pending={pending}
          deletedId={deletedId}
          deleting={deleting}
          clientsDeleteByIdAction={clientsDeleteByIdAction}
          clientsPostStorageAction={clientsPostStorageAction}
          clientsPostValidityAction={clientsPostValidityAction}
          pathToCreateDistributor={Routes.ADMINISTRATOR_DISTRIBUTORS_NEW}
          pathToEditDistributors={Routes.ADMINISTRATOR_DISTRIBUTORS_EDIT}
          distributorDeleteByIdAction={distributorDeleteByIdAction}
          clientsReactivate={clientsReactivate}
          distributorReactivatePostAction={distributorReactivatePostAction}
        />
      </ContentColumn>
    </Content>
  );
};

export default React.memo(Component);
