import { ActionType, createAsyncAction } from 'typesafe-actions';
import { Error } from '../../../sagas/_sagas';

export const requestResetPasswordAction = createAsyncAction(
	"REQUEST_RESET_PASSWORD_REQUEST",
	"REQUEST_RESET_PASSWORD_SUCCESS",
	"REQUEST_RESET_PASSWORD_FAILURE"
)<string, void, Error>();

export type RequestResetPasswordActionType = ActionType<
	typeof requestResetPasswordAction
>;
