import { ActionType, createAsyncAction } from "typesafe-actions";
import { Error } from "sagas/_sagas";
import { User } from "../SuperAdmin/Users/UsersTable/_types";
import {
  Distributor,
  NewDistributor,
} from "../Admin/Distributors/UsersTable/_types";

export const subDistributorsPostUserAction = createAsyncAction(
  "SUB_DISTRIBUTOR_POST_USER_REQUEST",
  "SUB_DISTRIBUTOR_POST_USER_SUCCESS",
  "SUB_DISTRIBUTOR_POST_USER_FAILURE"
)<User, User[], Error>();

export const subDistributorsReactivePostAction = createAsyncAction(
  "SUB_DISTRIBUTOR_REACTIVATE_POST_REQUEST",
  "SUB_DISTRIBUTOR_REACTIVATE_POST_SUCCESS",
  "SUB_DISTRIBUTOR_REACTIVATE_POST_FAILURE"
)<string, Distributor[], Error>();

export const subDistributorsGetUsersAction = createAsyncAction(
  "SUB_DISTRIBUTOR_GET_USERS_REQUEST",
  "SUB_DISTRIBUTOR_GET_USERS_SUCCESS",
  "SUB_DISTRIBUTOR_GET_USERS_FAILURE"
)<void, User[], Error>();

export const subDistributorsGetUserByIdAction = createAsyncAction(
  "SUB_DISTRIBUTOR_GET_USER_BY_ID_REQUEST",
  "SUB_DISTRIBUTOR_GET_USER_BY_ID_SUCCESS",
  "SUB_DISTRIBUTOR_GET_USER_BY_ID_FAILURE"
)<string, User, Error>();

export const subDistributorsDeleteUserByIdAction = createAsyncAction(
  "SUB_DISTRIBUTOR_DELETE_USER_BY_ID_REQUEST",
  "SUB_DISTRIBUTOR_DELETE_USER_BY_ID_SUCCESS",
  "SUB_DISTRIBUTOR_DELETE_USER_BY_ID_FAILURE"
)<string, User[], Error>();

export const subDistributorsPutUserByIdAction = createAsyncAction(
  "SUB_DISTRIBUTOR_PUT_USER_BY_ID_REQUEST",
  "SUB_DISTRIBUTOR_PUT_USER_BY_ID_SUCCESS",
  "SUB_DISTRIBUTOR_PUT_USER_BY_ID_FAILURE"
)<User, User[], Error>();

export const subDistributorsGetAction = createAsyncAction(
  "SUB_DISTRIBUTOR_GET_REQUEST",
  "SUB_DISTRIBUTOR_GET_SUCCESS",
  "SUB_DISTRIBUTOR_GET_FAILURE"
)<void, Distributor[], Error>();

export const subDistributorsPutAction = createAsyncAction(
  "SUB_DISTRIBUTOR_PUT_REQUEST",
  "SUB_DISTRIBUTOR_PUT_SUCCESS",
  "SUB_DISTRIBUTOR_PUT_FAILURE"
)<Distributor, NewDistributor, Error>();

export const subDistributorsRegisterPostAction = createAsyncAction(
  "SUB_DISTRIBUTOR_POST_REQUEST",
  "SUB_DISTRIBUTOR_POST_SUCCESS",
  "SUB_DISTRIBUTOR_POST_FAILURE"
)<Distributor, NewDistributor, Error>();

export const subDistributorsGetByIdAction = createAsyncAction(
  "SUB_DISTRIBUTOR_GET_BY_ID_REQUEST",
  "SUB_DISTRIBUTOR_GET_BY_ID_SUCCESS",
  "SUB_DISTRIBUTOR_GET_BY_ID_FAILURE"
)<string, Distributor, Error>();

export const subDistributorsDeleteByIdAction = createAsyncAction(
  "SUB_DISTRIBUTOR_DELETE_REQUEST",
  "SUB_DISTRIBUTOR_DELETE_SUCCESS",
  "SUB_DISTRIBUTOR_DELETE_FAILURE"
)<string, Distributor[], Error>();

export type SubDistributorsActionType = ActionType<
  | typeof subDistributorsGetAction
  | typeof subDistributorsRegisterPostAction
  | typeof subDistributorsPutAction
  | typeof subDistributorsGetByIdAction
  | typeof subDistributorsDeleteByIdAction
  | typeof subDistributorsGetUsersAction
  | typeof subDistributorsGetUserByIdAction
  | typeof subDistributorsDeleteUserByIdAction
  | typeof subDistributorsPutUserByIdAction
  | typeof subDistributorsReactivePostAction
>;
