import React from 'react';
import { Content, FullHeight } from './_styles';
import { GlobalNotification } from '../../../components/Toast/_types';
import { Header } from './components/Header';
import { RouterListDistributor } from '../../../components/Routes/RouterListDistributor';
import { useToasts } from 'react-toast-notifications';

export interface StateProps {
	toast: GlobalNotification;
}

export interface DispatchProps {
	clearToast: () => void;
	authDistributorLogoutAction: () => void;
	distributorsInit: () => void;
	clientsInit: () => void;
}

const Component: React.FC<StateProps & DispatchProps> = ({
	toast,
	clearToast,
	authDistributorLogoutAction,
	distributorsInit,
	clientsInit,
}) => {
	const { addToast } = useToasts();

	React.useEffect(() => {
		if (toast) {
			addToast(toast.message, {
				appearance: toast.code,
				autoDismiss: true,
			});
			clearToast();
		}
	}, [toast, addToast, clearToast]);

	return (
		<Content>
			<Header
				authDistributorLogoutAction={authDistributorLogoutAction}
				distributorsInit={distributorsInit}
				clientsInit={clientsInit}
			/>
			<FullHeight>
				<RouterListDistributor />
			</FullHeight>
		</Content>
	);
};

export default Component;
